.form{
	display: flex;
	flex-wrap: wrap;
	padding: 90px;
	justify-content: space-between;

	input{
		color: #a8acb2;
		-webkit-appearance: none;
	}

	input, textarea, select{
		border: solid 1px #a8acb2;
		padding: 8px 20px;
		-webkit-appearance: none;
	}

	@supports  ( -webkit-appearance: checkbox) {
		input[type="radio"],
		input[type="checkbox"]
		{
			-webkit-appearance: checkbox;
			appearance: checkbox;
			padding: 0;
			border-radius: 0;
		}
	}


	> br{
		display: none;
	}



	> .input{
		color: #a8acb2;
	    margin-bottom: 25px;

    	p{
			padding-bottom: 13px;
			color: #17385e;
			font-family: $openSans-bold;
			font-size: 15px;

			&.invalid{
				color: #ff0000;
			}
		}

		&.one-col{

			&.half{
				width: 100%;

				input{
					width: calc(50% - 40px);
				}
			}
			&.full{
				width: 100%;
			}
		}

		&.two-col{
			width: calc(50% - 40px);
		}

		input, textarea, select{
			width: 100%;
			font-size: 15px;
		}

		&.radio{
			width: 100%;
			margin-left: 0;

			input{
				position: relative;
				width: auto;
				cursor: pointer;

				&:before{
			    	content: '';
				    background-color: #ffffff;
				    border: solid 1px #17385e; 
				    width: 100%;
				    height: 100%;
				    display: block;
				    position: absolute;
				    top: 0;
				    left: 0;
		    	}

		    	&:checked {
				  	&:before{
				  		background-color:#17385e; 

				  	}
				}
			}

			.wpcf7-radio{
				display: flex;
    			justify-content: space-between;

				.wpcf7-list-item{
					font-size: 16px;
				    width: calc(50% - 40px);
				    line-height: 1;
				    margin: 0;
					line-height: 1;

					.wpcf7-list-item-label{
					    color: #17385e;
					    cursor: pointer;
					    width: 100%;

					    &:hover{
					    	color:#41ccd1;
					    }
					}
				}
			}
		}
		&.select{
			color: #000000;
			margin-right: 0
		}
		
		&.textarea{
			color: #000000;
			margin-left: 0;
		}
	}
	.submit-container {
	    width: 100%;
	    display: flex;
	    justify-content: space-between;
	    margin-bottom: 25px;
	    position: relative;

	    .wpcf7-list-item-label{
	    	font-size: 16px;
		    cursor: pointer;

		    &:hover{
		    	color:#41ccd1;
		    }
	    }

	    .submit{
	    	width: 30%;

	    	input{
	    		cursor: pointer;
	    	    padding: 23px 65px;
			    background-color: #41ccd1;
			    color: #ffffff;
			    letter-spacing: 2px;
			    font-size: 14px;
			    text-transform: uppercase;
			    width: 100%;
			    border: none;

			    &:hover{
			    	background-color:#ffffff;
			    	color: #41ccd1;
			    	box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
			    }
	    	}
	    }

	    span{
	    	align-self: center;
	    }
	    .ajax-loader{
	    	display: none;
	    }

	    .wpcf7-checkbox{
	    	input{
				position: relative;
				width: auto;
				cursor: pointer;

				&:before{
			    	content: '';
				    background-color: #ffffff;
				    border: solid 1px #17385e; 
				    width: 100%;
				    height: 100%;
				    display: block;
				    position: absolute;
				    top: 0;
				    left: 0;
		    	}

		    	&:checked {
				  	&:before{
				  		background-color:#17385e; 

					}
				}
			}
		}
	}
	.wpcf7-not-valid-tip{
		font-size: 10px;
		color: #ff0d00;
	}
	&.style-corpo{
		> .input{
			&.radio{
				.wpcf7-radio{
					.wpcf7-list-item{
						.wpcf7-list-item-label{
							color: #17385e;
							&:hover{
								color:#6ab2e3;
							}
						}
					}
				}
			}
		}
		.submit-container{
			.wpcf7-list-item-label{
				&:hover{
					color:#6ab2e3;
				}
			}
			.submit{
				input{
					background-color: #6AB2E3;
					color: #ffffff;
					&:hover{
						background-color:#ffffff;
						color: #6AB2E3;
						box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
					}
				}
			}
		}
	}
	&.style-corpo.sent {
		.wpcf7-response-output {
			font-size: 16px;
			border: none;
			margin-left: 0;
			font-family: $openSans-regular;
			background-color: #6AB2E3;
			color: white;
			padding: 23px 65px;
		}
	}
	&.sent {
		.wpcf7-response-output {
			font-size: 16px;
			border: none;
			margin-left: 0;
			font-family: $openSans-regular;
			background-color: #41ccd1;
			color: white;
			padding: 23px 65px;
		}
	}
	&.invalid,
	&.failed {
		input[aria-invalid="true"],select[aria-invalid="true"]{
			border: solid 1px #ff0000;
		}
		.wpcf7-response-output{
			font-size: 16px;
			border: none;
			margin-left: 0;
			font-family: $openSans-regular;
			background-color: #E93131;
			color: white;
			padding: 23px 65px;
		}
	}
}