.page-post-title{
  font-family: $openSans-bold;
  font-size: 50px;
  line-height: 55px;
  color: #17385e;
  margin: 20px -6px;
}
.sticky-post{
  position: relative;
  .page-post-title{
    padding-top: 20px;
    margin: 0;
  }
  .cart{
    .one-half-part{
      flex: 1 0 48%;
      margin-right: 30px;
      figure{
        padding: 60px 0;
      }
    }
    .two-half-part{
      flex: 1 0 48%;
      .text-container{
        padding: 60px 0;
        font-size: 15px;
        .info-post{
          .time{
            width: fit-content;
            display: inline-block;
            font-family: $openSans-light;
            letter-spacing: 2px;
            color: #17385e;
            margin-right: 20px;
          }
          .category{
            width: fit-content;
            display: inline-block;
            font-family: $openSans-semi-bold;
            font-size: 13px;
            color: #ffffff;
            padding: 6px 12px;
            border-radius: 17.5px;
            margin-right: 5px;
          }
        }
        .title{
          font-family: $openSans-bold;
          font-size: 30px;
          line-height: 42px;
          letter-spacing: normal;
          color: #17385e;
          margin: 10px 0 20px 0;
        }
        .desc{
          margin: 20px 0 30px 0;
          font-family: $openSans-regular;
          font-size: 16px;
          line-height: 30px;
          letter-spacing: normal;
          color: #333333;
        }
        .more{
          font-family: $openSans-bold;
          font-size: 14px;
          letter-spacing: 2px;
          text-align: center;
          color: #17385e;
          border-bottom: solid 2px #6ab2e3;
          margin-right: 40px;
          padding: 0 12px 15px;
        }
        .download{
          position: relative;
          font-family: $openSans-bold;
          font-size: 18px;
          line-height: 24px;
          color: #17385e;
          margin-left:30px ;
          &::before{
            content: ' ';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -50px;
            background-image: url("../../../images/icon-download.png");
            background-size: cover;
            width: 35px;
            height: 29px;
          }
        }
      }
    }
  }
  &:before{
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 110%;
    z-index: -1;
    background-color: #eef3f6;
    transform: translate(-50%, 0);
  }
}
.listing-blog{
  .filter-posts{
    justify-content: center;
    background-color: #183455;
    padding: 30px 20px;
    margin-top: -5px;
    .searchandfilter{
      width: 100%;
      div{
        ul{
          li{
            h4{
              display: inline-block;
              padding-left: 40px;
              font-family: $openSans-regular;
              font-size: 16px;
              line-height: 30px;
              color: #ffffff;
            }
            #ofcategory{
              width: 184px;
              height: 49px;
              margin: 0 32px 0 51px;
              padding: 15px 20px;
              background-color: #17385e;
              color: #fff;
              border: solid 1px #ffffff;
            }
            input[name="ofsearch"]{
              width: 445px;
              height: 49px;
              margin: 0 24px 0 32px;
              padding: 15px 20px 15px 20px;
            }
            input[type="submit"]{
              width: 211px;
              height: 49px;
              margin: 0 0 0 24px;
              padding: 15px 50px;
              background-color: #6ab2e3;
              font-family: $openSans-regular;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  .cart-wrapper{
    margin: 50px 0;
    justify-content: center;
    .cart-container{
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
      text-align: center;
      font-size: 0;
      flex-wrap: wrap;
      .cart{
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding: 0;
        margin: 0 0 30px 0;
        text-align: left;
        font-size: 0;
        position: relative;
        .one-half-part{
          figure{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 30%;
            margin: 0;
            img{
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              font-family: "object-fit: cover;";
              padding: 30px 0;
            }
          }
        }
        .two-half-part{
          background-color: #fff;
          box-shadow: 0 32px 54px 0 rgba(0,0,0,.11);
          width: 90%;
          margin-left: auto;
          padding: 30px 50px 35px 300px;
          .text-container{
            padding: 0;
            font-size: 15px;
            .info-post{
              .time{
                width: fit-content;
                display: inline-block;
                font-family: $openSans-light;
                letter-spacing: 2px;
                color: #17385e;
                margin-right: 20px;
              }
              .category{
                width: fit-content;
                display: inline-block;
                font-family: $openSans-semi-bold;
                font-size: 13px;
                color: #ffffff;
                padding: 6px 12px;
                border-radius: 17.5px;
                margin-right: 5px;
              }
            }
            .title{
              font-family: $openSans-bold;
              font-size: 30px;
              line-height: 42px;
              letter-spacing: normal;
              color: #17385e;
              margin: 10px 0 20px 0;
            }
            .desc{
              margin: 20px 0 30px 0;
              font-family: $openSans-regular;
              font-size: 16px;
              line-height: 30px;
              letter-spacing: normal;
              color: #333333;
            }
            .more{
              font-family: $openSans-bold;
              font-size: 14px;
              letter-spacing: 2px;
              text-align: center;
              color: #17385e;
              border-bottom: solid 2px #6ab2e3;
              margin-right: 40px;
              padding: 0 12px 15px;
            }
            .download{
              position: relative;
              font-family: $openSans-bold;
              font-size: 18px;
              line-height: 24px;
              color: #17385e;
              margin-left:30px ;
              &::before{
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: -50px;
                background-image: url("../../../images/icon-download.png");
                background-size: cover;
                width: 35px;
                height: 29px;
              }
            }
          }
        }


      }
    }
    .pagination{
      .screen-reader-text{
        display: none;
      }
      .nav-links{
        font-size: 16px;
        .page-numbers{
          margin: 0 5px;
        }
      }
    }
  }
}
