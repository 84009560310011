.iso-9001{
  padding: 100px 0;
  position: relative;
  .advgb-columns {
    background-color: #ffffff;

    .wp-block-advgb-column {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .advgb-column-inner{
        h3,p{
          color: #17385e;
        }
        h3{
          margin-bottom: 13px;
        }
        p{
          padding-right: 95px;
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: var(--c-block-iso-background-color);
    box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
    transform: translate(-50%, 0);
  }
}
