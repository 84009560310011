@media screen and (min-width: 768px) {
  $container-width: 768px;

  .container{
    max-width: $container-width;
    padding: 0 40px;
  }

  //************Header**************************//
  header{
    .head{
      .title-logo-header{
        max-width: 13%;
      }
      .row-contact-header{
        .center-button-header{
          width: 45.33333%;
          min-height: 49px;
          padding: 0 25px;
          #inner-center-button-header{
            font-size: 14px;
          }
        }
        .question-button-header{
          width: 45.33333%;
          min-height: 49px;
          padding: 0 25px;
          #inner-question-button-header{
            font-size: 14px;
          }
        }
      }
    }
    .nav{
      .menu{
        max-width: $container-width;
        margin-top: 45px;
        padding: 0 45px;
      }
    }
  }

  //*************Banner*************************//
  .banner{
    .banner-container-outer{
      .banner-container{
        padding: 80px 50px 10px;
      }
    }
  }

  //***Center*template*//
  .container{
    .map-container{
      flex-wrap: nowrap;
      margin-bottom: 90px;

      .filter{
        display: block;
        &.mobile{
          display: none;
        }
      }
      .map-display{
        display: none;
      }
      .right-column{
        width: 37%;

        .items{
          .item{
            .mail{
              margin-bottom: 12px;
            }
          }
        }
      }
      .mapboxgl-map{
        width: 63%;
        display: block;
        margin-right: 25px;
        height: 850px;
      }
    }
  }

  //***Offer*Template****************//
  .container{
    .offer {
      &.single{
        .title{
          h1{
            line-height: 1.1;
          }
        }
        .description{
          p{
            line-height: 1.88;
          }
        }
        .button{
          width: 482px;
        }
      }
    }
  }

  //************Footer**************************//
  footer{
    .pre-footer{
      display: flex;
      .talk,.faq {
        padding: 20px 65px 20px 65px;
      }
      .faq{
        margin-bottom: 0;
      }
      .talk{
        margin-left: 20px;
        margin-bottom: 0;
      }
    }
    .menu_footer {

      .footer-flex{
        flex-wrap: nowrap;

      }
      .menu{
        width: 111%;
        margin-bottom: 0;

        .menu-item{
            flex: 1 1 50%;
            padding-bottom: 20px;
        }
      }

      .networks{
        width: calc(100% / 12 * 5);
        margin-bottom: 0;
        p{
          text-align: left;
        }

        .logos{
          flex-wrap: wrap;
          flex: 1;
          a{
            height: fit-content;
            &:nth-child(3n){
              padding-right: 0;
            }
          }

        }
      }

      .languages{
        margin-left: 0;
        width: 25%;
        margin-bottom: 0;
      }
      .iso{
        margin-left: calc(100% / 12 * 1);
        margin-bottom: 0;
        width: calc(100% / 12 * 1);

        figure{
          align-self: flex-start;
        }
      }
    }
  }

  //************Form**************************//
  .form{

    padding: 90px 45px 90px;

    > .input{

      &.one-col{
        &.half{
          input{
            width: calc(50% - 40px);
          }
        }
      }

      &.two-col{
        width: calc(50% - 40px);
      }

      &.radio{
        width: 100%;
        margin-left: 0;

        .wpcf7-radio{
          flex-wrap: nowrap;

          .wpcf7-list-item{
            width: calc(50% - 40px);
            margin-bottom: 0;
          }
        }
      }
    }
    .submit-container {
      flex-wrap: wrap;

      .wpcf7-list-item{
        margin-bottom: 0;
      }

      .submit{
        width: 50%;
      }
    }
  }

  //***********Block**Testimony****************//
  .home-block-testimony{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          padding: 40px 0;
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .testimony-block-image{
              height: 520px !important;
              background-size: 205%;
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-two-thirds-tablet{
            .advgb-column-inner{
              .testimony-block-image{
                height: 520px !important;
                background-size: 205%;
              }
            }
          }
        }
      }
    }
  }

  //**********Block*FAQ*********************//
  .home-block-faq-block{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: -60%;
                  .advgb-is-one-fifth-tablet{
                    max-width: 40%;
                    padding-top: 40%;
                    margin: 15px 20px !important;
                  }
                }
              }
            }
            .home-block-faq-block-row-button{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-is-half-tablet{
                    max-width: 40%;
                    margin: 20px 20px !important;
                  }
                }
              }
            }
            .home-block-faq-block-row-parcour{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-column{
                    .advgb-column-inner{
                      .home-block-faq-block-parcour-title{
                        margin-top: 30px;
                      }
                      .home-block-faq-block-parcour-content{
                        max-width: 100%;
                        margin-top: 28px;
                        margin-bottom: 310px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //**********Block*Frieze*****************//
  .home-block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        .home-block-frieze-columns{
          width: 100% !important;
        }
      }
    }
  }

  //************Block*iso-9001*************//
  .iso-9001{
    .advgb-columns{
      .advgb-column.advgb-is-one-fifth,
      .advgb-column.advgb-is-one-fifth-tablet {
        width: 20% !important;
      }

      .advgb-column.advgb-is-four-fifths,
      .advgb-column.advgb-is-four-fifths-tablet {
        width: 80% !important;
      }
    }
  }

  //**********Block*Video*Slider*************//
  .home-block-video-slider{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          .advgb-column-inner{
            .home-block-video-content{
              .advgb-video-wrapper{
                .advgb-video-poster{
                  img{
                    margin-top: -55px;
                  }
                }
              }
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .home-block-video-content-list{
              ul{
                li{
                  &.clicked{
                    &:nth-child(n)::before{
                      left: -3.555%;
                    }
                  }
                }
                &::before{
                  left: -3.555%;
                }
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Type*Pathology***********//
  .block-type-pathology{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          height: 50%;
          max-width: 520px;
        }
        .advgb-is-two-thirds-tablet{
          padding: 190px 0 50px;
          .advgb-column-inner {
            padding: 220px 15px 25px 15px;
          }
        }
      }
    }
  }

  //*********Block*Type*Tabs**************//
  .block-type-tabs{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          margin-bottom: 100px !important;
        }
      }
    }
  }

  //*********Block*Type*Quote************//
  .block-type-quote{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          // padding-top: 33.33%;
          .advgb-column-inner{
            padding: 85px 30px 40px;
            .block-type-quote-content{
              &::before{
                top: 15%;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Intro*Corpo**********//
  .intro-corpo-block{
    .advgb-columns-container{
      .advgb-columns-row{
        flex-direction: column-reverse;
        .advgb-column.advgb-is-one-third-tablet{
          padding: 40px;
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .intro-corpo-block-image{
              height: 520px !important;
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          flex-direction: column-reverse;
          .advgb-column.advgb-is-one-third-tablet{
            padding: 40px;
          }
          .advgb-column.advgb-is-two-thirds-tablet{
            .advgb-column-inner{
              .intro-corpo-block-image{
                height: 520px !important;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Three*Columns*******//
  .block-three-columns{
    padding: 100px 0;
    .advgb-columns-container{
      .advgb-columns-row{
        justify-content: center;
        flex-wrap: wrap;
        .advgb-is-one-third-tablet{
          margin-right: 30px !important;
          margin-bottom: 60px !important;
          max-height: 540px;
          width: 45% !important;
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-bottom: 0;
                  height: 100%;
                  .block-three-columns-img-column{
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //********Block*Numbers************//
  .block-numbers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          width: 45% !important;
          padding-top: 45%;
          margin-right: 30px !important;
          margin-bottom: 30px !important;
          .advgb-column-inner{
            padding: 0 20px;
          }
        }
      }
    }
  }

  //********Block*Map*Of*Centers*****//
  .block-map-of-centers{
    padding: 0;
    margin-top: 0;
  }

  //*******Block*Metiers*************//
  .block-metiers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-half-tablet{
          width: 45% !important;
          margin-right: 30px !important;
          padding-top: 55%;
        }
      }
    }
  }

  //******Block*Video*With*Title*Content**//
  .block-video-with-title-content{
    padding: 75px 0;
    .advgb-columns-container {
      .advgb-columns-row {
        flex-direction: column-reverse;
        .block-video-with-title-content-column-video{
          height: 385px;
          .advgb-column-inner{
            .home-block-video-content {
              .advgb-video-wrapper{
                .advgb-video-poster{
                  img{
                    margin-top: -65px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //*****Block*Type*Article*To*Other*Page**//
  .block-type-article-to-other-page{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          height: 50%;
          max-width: 520px;
        }
        .advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            padding: 350px 30px 20px;
          }
        }
      }
    }
  }

  //***********Tarteaucitron****************//
  #tarteaucitronRoot{
    #tarteaucitronAlertBig{
      #tarteaucitronDisclaimerAlert{
        max-width: $container-width;
      }
    }
  }

  //***********Blog*Page********************//
  .sticky-post{
    .cart{
      display: block;
      .one-half-part{
        flex: 1 0 100%;
        margin-right: 0;
        figure{
          padding: 20px 0;
        }
      }
      .two-half-part{
        flex: 1 0 48%;
        .text-container{
          padding: 10px 0;
          .download{
            margin-top: 0;
            display: inline;
          }
        }
      }
    }
    &:before{
      height: 115%;
    }
  }
  .listing-blog{
    .filter-posts{
      margin-top: 40px;
      width: 100%;
      .searchandfilter{
        div{
          ul{
            display: inline-block;
            li{
              display: inline-block;
              padding-right: 10px;
              h4{
                padding-left: 0;
              }
              #ofcategory{
                width: 184px;
                margin: 0 0 0 10px;
              }
              input[name="ofsearch"]{
                width: 200px;
                margin: 0;
              }
              input[type="submit"]{
                width: 100%;
                padding: 15px 20px;
              }
            }
          }
        }
      }
    }
    .cart-wrapper{
      margin: 50px 0;
      justify-content: center;
      .cart-container{
        width: 100%;
        margin: 0;
        .cart{
          .one-half-part{
            figure{
              height: 330px;
              width: 100%;
              img{
                padding: 0 20px;
              }
            }
          }
          .two-half-part{
            width: 100%;
            margin-top: 200px;
            padding: 165px 20px 40px;
            .text-container{
              .download{
                margin-left: 50px;
                display: block;
                margin-top: 50px;
              }
            }
          }


        }
      }
      .pagination{
        .nav-links{
          text-align: center;
          .page-numbers{
            margin: 0 5px;
          }
        }
      }
    }
  }

  //**********Single*Post*Page**************//
  .single-post{
    .site__navigation{
      .site__navigation__prev,
      .site__navigation__next{
        max-width: 47%;
        margin-right: 30px;
        margin-top: 0;
      }
    }
  }

  //**********Block*JobOffer*Carrousel******//
  .block-job-offers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            margin-bottom: 0;
            .block-job-offers-title{
              color: #fcfcfc;
            }
            .block-job-offers-carousel{
              .swiper-container{
                max-width: 315px;
                .swiper-wrapper{
                  min-height: 402px;
                  .block-job-offers-carousel-slide{
                    .slide-row{
                      .job-offer-embed{
                        .picto-job-offer{
                          position: relative;
                        }
                        .title{
                          min-height: 56px;
                          h1{
                            padding-left: 0;
                            padding-right: 0;
                            margin: 10px 0;
                          }
                        }
                        .center{
                          margin: 0 5px;
                        }
                      }
                      .formation-embed{
                        .title{
                          min-height: 56px;
                          h3{
                            padding-left: 0;
                            padding-right: 0;
                            margin: 10px 0;
                          }
                        }
                      }
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  color: #17385e;
                  top: 120%;
                }
                .swiper-button-next{
                  right: auto;
                  left: 90%;
                }
                .swiper-button-prev{
                  left: 0;
                }
                .blue-square{
                  top: -535px;
                  width: 260%;
                }
              }
            }
            .block-job-offers-button{
              margin-top: 120px;
              .wp-block-advgb-button_link{
                min-width: 315px;
              }
            }
          }
        }
      }
    }
    &::before{
      display: none;
    }
  }

  //**********Block*Three*Columns*Actu*******//
  .block-three-columns-actu{
    .actu-three-columns-block{
      .actu-three-columns-block-container{
        .actu-three-columns-block-row{
          justify-content: center;
          .actu-three-columns-block-is-one-third{
            width: 45%;
            margin-right: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .block-three-columns-button{
      a{
        width: 45%;
      }
    }
  }

  //*********Block*Slider*Home*Intro********//
  .slider-intro-block{
    .swiper-container-intro-block{
      .swiper-wrapper{
        .slider-intro-block-slide{
          .slide-row{
            .slider-intro-block-inner{
              .slider-intro-block-container{
                .slider-intro-block-row{
                  .slider-intro-block-column-one{
                    padding: 30px 0;
                  }
                  .slider-intro-block-column-two{
                    transform: translate(-40px, 0);
                  }
                }
              }
            }
          }
        }
      }
      .swiper-pagination{
        left: 200px;
        .swiper-pagination-bullet{
          margin: 0 10px;
        }
      }
      .swiper-button-next{
        left: 450px;
      }
      .swiper-button-prev{
        left: 400px;
      }
    }
  }

  //**********Block*Three*Columns*Former*******//
  .block-three-columns-former{
    .former-three-columns-block{
      .former-three-columns-block-container{
        .former-three-columns-block-row{
          justify-content: center;
          .former-three-columns-block-is-one-third{
            width: 45%;
            margin-right: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .block-three-columns-button{
      a{
        width: 45%;
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 991px){
  //**********Block*Frieze*****************//
  .home-block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        max-width: 317px;
        flex-wrap: wrap;
        .home-block-frieze-columns{
          max-height: 288px !important;
          width: 100%;
          .advgb-column-inner{
            padding: 55px 40px 55px 40px;
          }
          &::after{
            width: 100vw;
            height: 100%;
            left: -15%;
            top: -15%;
            background-image: url("../../images/fleche-frieze-mobile.png");
          }
          &:last-child::after{
            width: 100vw;
            height: 100%;
            left: -15%;
            top: -10%;
            background-image: url("../../images/fleche-frieze-mobile.png");
          }
        }
        &::before{
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns.mbl-layout-stacked>.advgb-column {
    width: 100% !important;
  }
}
