@media screen and (min-width: 992px) {
  $container-width: 992px;

  .container{
    max-width: $container-width;
    padding: 0 45px;
  }

  h1{
    font-size: 50px;
  }

  h2{
    font-size: 40px;
  }

  h3{
    font-size: 30px;
  }

  //************Header**************************//
  header{
    .flex-container.row {
      padding: 20px 0;
    }
    .head{
      .container{
        display: flex;
        padding: 0 45px;

      }
      #site-header {
        display: flex;
        float: none;
        padding: 0;
        margin-right: 0;
      }
      .title-logo-header{
        display: flex;
        max-width: none;
        float: none;
        &.title-logo-header-semantic-template{
          max-width: none;
        }
      }
      .menu-button-mobile{
        display: none;
      }
      .row-contact-header{
        background-color: transparent;
        display: inherit;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 0;
        min-width: unset;
        >div{
          margin-right: 30px;
        }
        .contact-header{
          display: inline-flex;
          align-items: flex-start;
          width: unset;
          text-align: unset;
          background-color: transparent;
          flex-flow: column;
          margin-bottom: 0;
          margin-right: 30px;
          #label-contact-header{
            font-size: 13px;
            color: #17385e;
          }
          #num-contact-header{
            font-size: 16px;
            color: #17385e;
          }
          .type-call-contact-header{
            font-size: 13px;
            color: #17385e;
          }
        }
        .center-button-header{
          min-height: 49px;
          padding: 0 25px;
          display: flex;
          width: unset;
          #inner-center-button-header{
            font-size: 14px;
          }
        }
        .question-button-header{
        display: flex;
        width: unset;
        min-height: 49px;
        padding: 0 25px;
          #inner-question-button-header{
            font-size: 14px;
          }
        }
      }

      /**Header*Corpo**/
      .container-header-corpo{
        display: flex;
        max-width: $container-width;
        padding: 0 45px;
        justify-content: space-between;
        .row-contact-header{
          background-color: transparent;
          display: inherit;
          flex-direction: row;
          padding: 0;
          min-width: unset;
          >div{
            margin-right: 30px;
          }
          .rdv-button-header{
            display: flex;
            min-width: unset;
            justify-content: normal;
            #inner-rdv-button-header{
              color: #363636;
            }
          }
          .meet-button-header{
            display: flex;
            min-width: unset;
            justify-content: normal;
            margin-bottom: 0;
          }
        }
      }
    }
    .nav{
      padding: 40px 0;
      margin-top: 0;
      .menu{
        max-width: $container-width;
        margin-top: 0;
        display: flex;
        justify-content: space-between;
      }
      ul {
        li {
          display: inline-block;
          a{
            font-size: 15px;
            padding: 4px 0;
            line-height: normal;
            font-family: $openSans-regular;
          }
          &:first-child{
            margin-left: 0 !important;
          }
          &:last-child{
            margin-right: 0 !important;
          }
        }
      }
      &.style-corpo{
        margin-top: 0;
        li.has-mega-menu{
          &:hover,
          &.clicked {
            .sub-menu{
              .mega-sub-column{
                &.menu-item-has-children:hover{
                  border-bottom: solid 2px #6ab2e3;
                  .sub-menu{
                    .mega-sub-sub-column{
                      &:hover{
                        border-bottom: solid 2px #6ab2e3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .has-mega-menu{
      position:static;
      &.menu-item-has-children:after{
        content: none;
        background-image: none;
      }
      &.more{
        >a{
          position: relative;
          &::after{
            content: " ";
            top: 10px;
            left: 105%;
            width: 7px;
            height: 13px;
            z-index: 999;
            position: absolute;
            background-image: url("../../images/fleche-droite-bleu.png");
            background-repeat: no-repeat;
            background-size: contain;
            transform: rotate(90deg);
            overflow: visible;
          }
        }
      }
    }
    li.has-mega-menu{
      .sub-menu {
        display: none;
      }
      &:hover,
      &.clicked {
        .sub-menu{
          display: inherit;
          position: absolute;
          overflow: visible;
          background-image: none;
          width: 100%;
          max-width: 972px;
          min-height: 75%;
          height: auto;
          padding-top: 100px;
          top: 0;
          left: 50%;
          text-align: left;
          transform: translateX(-50%);
          z-index: 999;
          .menu-item{
            margin: 0;
            a{
              max-width: fit-content;
              line-height: 35px;
              font-family: $openSans-light;
            }
            &:first-child{
              &:before{
                content: "";
                display: block;
                background-color: #a8acb2;
                width: 100%;
                height: 0.5px;
                position: absolute;
                top: 92px;
                left: 50%;
                right: 0;
                bottom: 0;
                transform: translateX(-50%);
                z-index: -1;
              }
            }
          }
          .mega-sub-column{
            width: 33.333333%;
            display: flex;
            margin: 0;
            flex-wrap: wrap;
            a{
              text-align: inherit;
              max-width: inherit;
              width: 285px;
            }
            .sub-menu {
              display: none;
            }
            &.menu-item-has-children:hover{
              border-bottom: solid 2px #D5AE60;
              .sub-menu{
                display: inline-flex;
                position: absolute;
                width: 100%;
                max-width: 972px;
                min-height: 75%;
                height: auto;
                background-image: none;
                top: 0;
                left: 77%;
                text-align: left;
                transform: translateX(-50%);
                padding-left: 55px;
                flex-direction: column;
                margin: 0;
                border: 0;
                z-index: 999;
                .menu-item{
                  &:first-child{
                    &:before{
                      display: none;
                    }
                  }
                }
                .mega-sub-sub-column{
                  width: 38.3333%;
                  height: 45px;
                  margin-bottom: 5px;
                  a{
                    width: 100%;
                  }
                  .sub-menu{
                    display: none;
                  }
                  &:hover{
                    border-bottom: solid 2px #D5AE60;
                    .sub-menu{
                      display: inline-flex;
                      position: absolute;
                      width: 100%;
                      max-width: 972px;
                      min-height: 75%;
                      height: auto;
                      background-image: none;
                      top: 0;
                      left: 90%;
                      text-align: left;
                      transform: translateX(-50%);
                      padding-left: 35px;
                    }
                  }
                  &:after{
                    content: " ";
                    display: block;
                    top: -27px;
                    left: 97%;
                    width: 10px;
                    height: 16px;
                    position: relative;
                    background-image: url("../../images/fleche-droite-bleu.png");
                    background-repeat: no-repeat;
                  }
                }
                &:before{
                  display: none;
                }
              }
            }
            &.menu-item-has-children:after{
              content: " ";
              display: block;
              top: 15px;
              left: 0;
              width: 10px;
              height: 16px;
              position: relative;
              z-index: 992;
              background-image: url("../../images/fleche-droite-bleu.png");
              background-repeat: no-repeat;
            }
          }
          &:before{
            content: "";
            display: block;
            background-image: linear-gradient(to bottom, #173150, #17385e);
            width: 100vw;
            height: 135%;
            position: absolute;
            top: 0;
            left: 50%;
            right: 0;
            bottom: 0;
            transform: translateX(-50%);
            z-index: -1;
          }
        }
        &:nth-child(2){
          margin-bottom: 0;
        }
      }

      &:hover{
        .sub-menu{
          z-index: 88;
        }
      }

      &.clicked{
        .sub-menu{
          z-index: 90;
        }
      }

      &.current-menu-ancestor{
        .sub-menu{
          .mega-sub-column{
            &.menu-item-has-children:hover{
              border-bottom: solid 0 #6ab2e3;
            }
          }
        }
      }
    }
    .only-mobile{
      display: none !important;
    }
  }

  //************Footer**************************//
  footer{
    .menu_footer {
      .footer-flex {
        &.footer-formation {
          .menu-footer-menu-container {
            width: 50%;
            margin-bottom: 0;
          }
        }
      }
      .opv-formation-address {
        width: 25%;
        margin-bottom: 0;
      }
      .menu-footer-menu-container{
        width: 33%;
      }
      .menu{
        width: 85%;
      }
      .networks{
        width: calc(100% / 12 * 3);
        margin-bottom: 0;
        p{
          text-align: left;
        }
        .logos{
          justify-content: left;
          flex-wrap: nowrap;
          flex: 0;
          a{
            &:nth-child(3n){
              padding-right: 30px;
            }
            &:last-child{
              padding-right: 0;
            }
          }
        }
      }
      .languages{
        margin-left: calc(100% / 12 * 1);
        width: calc(100% / 12 * 2);
      }
    }
  }

  //*************Banner*************************//
  .banner{
    .banner-container-outer{
      .banner-container{
        width: 740px;
        padding: 10px 60px;
        .banner-content{
          .banner-icon{
            left: 0;
            top: 50%;
            transform: translate(calc(-100% - 66px),-50%);
          }
        }
        .banner-text{
          p{
            margin-bottom: 0;
          }
        }
      }
      .banner-close{
        top: 0;
        right: -30px;
      }
    }
  }

  //***********Block**Testimony****************//
  .home-block-testimony{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          width: 50% !important;
          padding: 45px 0;
          .advgb-column-inner{
            .testimony-block-title{
              font-size: 50px;
            }
          }
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .testimony-block-image{
              background-position: 0 0 !important;
              height: 100% !important;
              min-height: 460px;
              background-size: cover;
              width: 66% !important;
              left: 0;
              transform: inherit;
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-one-third-tablet{
            position: relative;
            padding: 45px 0;
            left: -85px;
            .advgb-column-inner{
              .testimony-block-title{
                font-size: 50px;
              }
            }
          }
          .advgb-column.advgb-is-two-thirds-tablet{
            position: relative;
            left: -100px;
            .advgb-column-inner{
              .testimony-block-image{
                background-position: 0 0 !important;
                height: 100% !important;
                min-height: 460px;
                background-size: cover;
                width: 100% !important;
                left: 0;
                transform: inherit;
              }
            }
          }
        }
      }
    }
  }

  //***********Block*Intro*Surgery************//
  .home-block-intro-surgery{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-three-quarters-tablet{
          padding: 100px 0;
          .advgb-column-inner{
            .home-block-intro-surgery-title{
              font-size: 40px;
              line-height: 1.38;
            }
          }
        }
        .advgb-is-one-quarter-tablet{
          padding: 150px 0;
        }
      }
    }
  }

  //**********Block*Video*Slider*************//
  .home-block-video-slider{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          position: absolute;
          margin-bottom: unset !important;
          width: 520px !important;
          padding: 0;
          left: 0;
          .advgb-column-inner{
            .home-block-video-content{
              .advgb-video-wrapper{
                margin-top: 0;
                .advgb-video-poster{
                  img{
                    margin-top: -15px;
                  }
                }
              }
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          position: relative;
          padding: 72px 350px 72px 220px;
          min-height: 500px;
          left: 90%;
          max-height: 500px;
          overflow: auto;
          transform: translateX(-50%);
          .advgb-column-inner{
            .home-block-video-content-list{
              ul{
                li{
                  &.clicked{
                    &:nth-child(n)::before{
                      left: -35px;
                    }
                  }
                }
                &::before{
                  height: 100%;
                  top: 0;
                  left: -35px;
                }
              }
            }
          }
        }
      }
    }
  }

  //**********Block*Blue*Question*Frequency*//
  .home-block-blue-block-questions-frequency{
    margin-top: -15%;
    min-height: auto;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          padding: 380px 0 220px 0;
        }
      }
    }
  }

  //**********Block*FAQ*********************//
  .home-block-faq-block{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: -10%;
                  .advgb-is-one-fifth-tablet{
                    max-width: 30%;
                    padding-top: 30%;
                    margin: 15px 15px !important;
                    .advgb-column-inner{
                      padding: 40px 40px;
                    }
                  }
                }
              }
            }
            .home-block-faq-block-row-button{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-is-half-tablet{
                    max-width: 30%;
                    margin: 20px 15px !important;
                  }
                }
              }
            }
            .home-block-faq-block-row-parcour{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-column{
                    .advgb-column-inner{
                      .home-block-faq-block-parcour-title{
                          margin-top: 200px;
                      }
                      .home-block-faq-block-parcour-content{
                        max-width: 65%;
                        margin-top: 28px;
                        margin-bottom: 225px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //**********Block*Frieze*****************//
  .home-block-frieze{
    margin-top: -130px;
    .advgb-columns-container{
      .advgb-columns-row{
        max-width: $container-width;
        .home-block-frieze-columns{
          width: 23.3333% !important;
          .advgb-column-inner{
            padding: 40px 30px 40px 0;
          }
          &::after{
            width: 45%;
            height: 110%;
            left: 70%;
            top: -110%;
            background-image: url("../../images/fleche-frieze-desktop.png");
            background-size: 135%;
          }
          &:last-child::after{
            width: 100%;
            height: 105%;
            left: 88%;
            top: -110%;
            background-image: url("../../images/fleche-frieze-desktop.png");
            background-size: 60%;
          }
        }
        &::before{
          display: block;
        }
      }
    }
  }

  //************Block*iso-9001*************//
  .iso-9001{
    .advgb-columns-container{
      padding: 0;

      .wp-block-advgb-column{
        padding: 10px 0;
        p{
          padding: 0;
        }
      }
    }
    .advgb-columns{ 
      .wp-block-advgb-column{ 
        .advgb-column-inner{ 
          p{
            padding: 0;
          }
        }
      }
      .advgb-column.advgb-is-one-fifth,
      .advgb-column.advgb-is-one-fifth-tablet {
        width: 20% !important;
      }

      .advgb-column.advgb-is-four-fifths,
      .advgb-column.advgb-is-four-fifths-tablet {
        width: 80% !important;
      }
    }
  }

  //************Video*Carousel*************//
  .home-block-video-carousel{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .home-block-video-carousel-description{
              max-width: 45%;
            }
            .home-block-video-carousel{
              height: 450px;
              width: 100vw;
              padding-left: 40px;
              .swiper-container{
                max-width: 510px;
                .home-block-video-carousel-slide{
                  .slide-row{
                    max-height: 282px;
                    .home-block-video-carousel-slide-title{
                      max-width: 380px;
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  top: 73%;
                }
                .swiper-button-next{
                  left: 450px;
                }
                .swiper-button-prev{
                  left: 380px;
                }
                .white-square{
                  width: 120%;
                  height: 64%;
                  top: -150px;
                  left: -40px;
                }
              }
            }
          }
        }
      }
    }
  }

  //************Video*Carousel*Type*Corpo***//
  .home-block-video-carousel.type-corpo{
    &:before{
      background-image: url("../../images/motif-2.png");
    }
  }

  //**********Block*Type*Pathology*****//
  .block-type-pathology{
    .advgb-columns-container{
      .advgb-columns-row{
        justify-content: normal;
        .advgb-is-one-third-tablet{
          max-width: 375px;
          height: 100%;
          width: 100%;
          transform: translateY(-50%);
          top: 50%;
        }
        .advgb-is-two-thirds-tablet{
          padding: 0;
          left: 33.3333%;
          .advgb-column-inner{
            padding: 100px;
          }
        }
      }
    }
  }

  //*********Block*Type*Tabs**************//
  .block-type-tabs{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          margin-bottom: 100px !important;
        }
      }
    }
  }

  //*********Block*Type*Quote************//
  .block-type-quote{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          // padding-top: 45.58%;
          .advgb-column-inner{
            padding: 85px 40px 55px;
            .block-type-quote-content{
              &::before{
                top: 12%;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Intro*Corpo**********//
  .intro-corpo-block{
    .advgb-columns-container{
      .advgb-columns-row{
        flex-direction: row;
        .advgb-column.advgb-is-one-third-tablet{
          width: 50% !important;
          padding: 45px 45px 45px 0;
          .advgb-column-inner {
            .intro-corpo-block-title {
              font-size: 50px;
            }
          }
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .intro-corpo-block-image{
              height: 100% !important;
              min-height: 460px;
              width: 66% !important;
              left: 0;
              transform: inherit;
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          flex-direction: row;
          .advgb-column.advgb-is-one-third-tablet{
            position: relative;
            left: -85px;
            padding: 45px 45px 45px 0;
            .advgb-column-inner {
              .intro-corpo-block-title {
                font-size: 50px;
              }
            }
          }
          .advgb-column.advgb-is-two-thirds-tablet{
            position: relative;
            left: -100px;
            .advgb-column-inner{
              .intro-corpo-block-image{
                height: 100% !important;
                min-height: 460px;
                width: 100% !important;
                left: 0;
                transform: inherit;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Three*Columns*******//
  .block-three-columns{
    padding: 0;
    .advgb-columns-container{
      .advgb-columns-row{
        justify-content: unset;
        .advgb-is-one-third-tablet{
          margin-right: 30px !important;
          margin-bottom: 0 !important;
          max-height: 540px;
          width: 30% !important;
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-bottom: 80px;
                  .block-three-columns-img-column{
                    left: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &::before{
      background-image: url("../../images/motif.png");
      background-repeat: no-repeat;
      background-size: contain;
      left: 30%;
    }
  }

  //********Block*Numbers************//
  .block-numbers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          width: 22% !important;
          padding-top: 22%;
          margin-right: 30px !important;
          margin-bottom: 30px !important;
          .advgb-column-inner{
            padding: 0;
            .block-numbers-title {
              font-size: 30px;
            }
          }
        }
      }
    }
  }

  //********Block*Map*Of*Centers*****//
  .block-map-of-centers{
    padding: 45px 0 0 0;
    margin-top: 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .block-map-of-centers-column-map{
          margin-right: 90px !important;
          .advgb-column-inner{
            .block-map-of-centers-title-mobile{
              display: none;
            }
          }
        }
        .block-map-of-centers-column-content{
          .advgb-column-inner{
            .block-map-of-centers-title-desktop{
              display: block;
            }
            .block-map-of-centers-button-center{
              .wp-block-advgb-button_link{
                padding: 25px 50px;
              }
            }
            .block-map-of-centers-button-job-offer{
              .wp-block-advgb-button_link{
                padding: 25px 50px;
              }
            }
          }
        }
      }
    }
  }

  //********Block*Metiers***********//
  .block-metiers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-half-tablet{
          width: 42% !important;
          padding-top: 42%;
          .advgb-column-inner{
            .block-metiers-img{
              transform: scale(1);
            }
            .block-metiers-title{
              font-size: 16px;
              line-height: 1.25;
              margin-top: 30px;
            }
            .block-metiers-content{
              margin: 15px 0 55px 0;
              padding: 0 45px;
            }
            .block-metiers-tags{
              margin-right: 20px;
              &:nth-child(5){
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  //***Center*template*//
  .container{
    .map-container{
      flex-wrap: nowrap;
      margin-bottom: 90px;
      padding-top: 100px;
      &::before{
        content: "";
      }
      .filter{
        display: block;
        &.mobile{
          display: none;
        }
      }
      .map-display{
        display: none;
      }
      .right-column{
        width: 37%;
        -height: 850px;

        .items{
          -height: calc(78% - 25px);
          .item{

            .mail{
              margin-bottom: 12px;
            }
          }
        }
      }
      .mapboxgl-map{
        width: 63%;
        display: block;
        margin-right: 25px;
      }
    }
  }

  //*******Block*Video*With*Title*Content**//
  .block-video-with-title-content{
    padding: 0 0 100px 0;
    .advgb-columns-container {
      .advgb-columns-row {
        flex-direction: row;
        .block-video-with-title-content-column-video{
          width: 50% !important;
          margin-right: 100px !important;
          height: 295px;
          .advgb-column-inner{
            .home-block-video-content {
              .advgb-video-wrapper{
                .advgb-video-poster{
                  img{
                    margin-top: -22px;
                  }
                }
              }
            }
          }
        }
        .block-video-with-title-content-column-content{
          width: 35.555% !important;
          .advgb-column-inner{
            margin-bottom: 75px;
            .block-video-with-title-content-title{
              font-size: 40px;
              line-height: 1.38;
            }
          }
        }
      }
    }
  }

  //*****Block*Actus*FB******************//
  .block-actus-FB{
    padding: 200px 0 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            height: 100%;
            margin-bottom: 475px;
            .block-actus-FB-title{
              top: -100px;
              left: 380px;
              max-width: 540px;
            }
            .block-actus-FB-description{
              display: block;
              top: -65px;
              left: 380px;
              max-width: 540px;
            }
            .block-actu-fb-carousel{
              padding-left: 95px;
              .swiper-container{
                max-width: 450px;
                .swiper-wrapper{
                  min-height: 810px;
                  .block-actu-fb-carousel-slide{
                    .slide-row{
                      .actu-embed{
                        .fb_iframe_widget{
                          span{
                            width: 445px !important;
                            iframe{
                              width: 445px !important;
                            }
                          }
                        }
                        iframe{
                          width: 445px !important;
                        }
                      }
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  top: -10%;
                }
                .swiper-button-next{
                  left: 250px;
                }
                .swiper-button-prev{
                  left: 200px;
                }
                .blue-square{
                  width: 100vw;
                  height: 125%;
                  top: -1135px;
                  left: 195px;
                }
              }
            }
          }
        }
      }
    }
    &::before{
      background-image: url("../../images/motif-3.png");
    }
  }

  //*****Block*Type*Article*To*Other*Page**//
  .block-type-article-to-other-page{
    .advgb-columns-container{
      .advgb-columns-row{
        justify-content: normal;
        .advgb-is-one-third-tablet{
          width: 100%;
          max-width: 245px;
          height: 80%;
          transform: translateY(-50%);
          top: 50%;
        }
        .advgb-is-two-thirds-tablet{
          padding: 100px 45px;
          left: 15.3333%;
          width: 90% !important;
          .advgb-column-inner{
            padding: 55px 95px 55px 100px;
          }
        }
      }
    }
  }

  //*****Block*Type*Offers*************//
  .block-type-offers{
    padding: 100px 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25% !important;
        }
        .block-type-offers-column-picto{
          width: 15% !important;
        }
        .block-type-offers-column-content{
          width: 30% !important;
        }
        .block-type-offers-column-button{
          width: 30% !important;
          .advgb-column-inner{
            .block-type-offers-button{
              .wp-block-advgb-button_link{
                padding: 15px 55px;
              }
            }
          }
        }
      }
    }
  }

  //***********Tarteaucitron****************//
  #tarteaucitronRoot{
    #tarteaucitronAlertBig{
      #tarteaucitronDisclaimerAlert{
        max-width: $container-width;
      }
    }
  }

  //***********Blog*Page********************//
  .sticky-post{
    position: relative;
    .cart{
      display: flex;
      .one-half-part{
        flex: 1 0 48%;
        margin-right: 30px;
        figure{
          padding: 60px 0;
        }
      }
      .two-half-part{
        flex: 1 0 48%;
        .text-container{
          padding: 60px 0;
          font-size: 15px;
          .info-post{
            .time{
              width: fit-content;
              display: inline-block;
              font-family: $openSans-light;
              letter-spacing: 2px;
              color: #17385e;
              margin-right: 20px;
            }
            .category{
              width: fit-content;
              display: inline-block;
              font-family: $openSans-semi-bold;
              font-size: 13px;
              color: #ffffff;
              padding: 6px 12px;
              border-radius: 17.5px;
            }
          }
          .title{
            font-family: $openSans-bold;
            font-size: 30px;
            line-height: 42px;
            letter-spacing: normal;
            color: #17385e;
            margin: 10px 0 20px 0;
          }
          .desc{
            margin: 20px 0 30px 0;
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: normal;
            color: #333333;
          }
          .more{
            font-family: $openSans-bold;
            font-size: 14px;
            letter-spacing: 2px;
            text-align: center;
            color: #17385e;
            border-bottom: solid 2px #6ab2e3;
            margin-right: 40px;
            padding: 0 12px 15px;
          }
          .download{
            position: relative;
            font-family: $openSans-bold;
            font-size: 18px;
            line-height: 24px;
            color: #17385e;
            margin-left:30px;
            display: inline;
            margin-top: 0;
            &::before{
              content: ' ';
              display: block;
              top: 0;
              bottom: 0;
              left: -50px;
            }
          }
        }
      }
    }
    &:before{
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      right: 0;
      bottom: 0;
      width: 100vw;
      height: 110%;
      z-index: -1;
      background-color: #eef3f6;
      transform: translate(-50%, 0);
    }
  }
  .listing-blog{
    .filter-posts{
      justify-content: center;
      background-color: #183455;
      padding: 30px 20px;
      margin-top: -5px;
      flex-basis: 100%;
      width: unset;
      flex-grow: 1;
      .searchandfilter{
        width: 100%;
        div{
          ul{
            li{
              h4{
                padding-left: 10px;
              }
              #ofcategory{
                width: 184px;
                height: 55px;
                margin: 0 15px;
                padding: 15px 20px;
              }
              input[name="ofsearch"]{
                width: 315px;
                height: 50px;
                margin: 0 10px 0 0;
                padding: 15px 20px;
              }
              input[type="submit"]{
                width: 210px;
                height: 50px;
                margin: 0;
                padding: 15px 50px;
              }
            }
          }
        }
      }
    }
    .cart-wrapper{
      margin: 50px 0;
      justify-content: center;
      .cart-container{
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
        flex-wrap: wrap;
        .cart{
          display: inline-block;
          vertical-align: top;
          width: 100%;
          padding: 0;
          margin: 0 0 30px 0;
          text-align: left;
          font-size: 0;
          position: relative;
          .one-half-part{
            figure{
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 30%;
              margin: 0;
              img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                font-family: "object-fit: cover;";
                padding: 30px 0;
              }
            }
          }
          .two-half-part{
            width: 90%;
            margin-left: auto;
            margin-top: 0;
            padding: 30px 50px 35px 215px;
            .text-container{
              padding: 0;
              font-size: 15px;
              .info-post{
                .time{
                  width: fit-content;
                  display: inline-block;
                  font-family: $openSans-light;
                  letter-spacing: 2px;
                  color: #17385e;
                  margin-right: 20px;
                }
                .category{
                  width: fit-content;
                  display: inline-block;
                  font-family: $openSans-semi-bold;
                  font-size: 13px;
                  color: #ffffff;
                  padding: 6px 12px;
                  border-radius: 17.5px;
                }
              }
              .title{
                font-family: $openSans-bold;
                font-size: 30px;
                line-height: 42px;
                letter-spacing: normal;
                color: #17385e;
                margin: 10px 0 20px 0;
              }
              .desc{
                margin: 20px 0 30px 0;
                font-family: $openSans-regular;
                font-size: 16px;
                line-height: 30px;
                letter-spacing: normal;
                color: #333333;
              }
              .more{
                letter-spacing: 2px;
                text-align: center;
                color: #17385e;
                border-bottom: solid 2px #6ab2e3;
                margin-right: 40px;
                padding: 0 12px 15px;
              }
              .download{
                margin-left:30px;
                display: inline;
                margin-top: 0;
                &::before{
                  content: ' ';
                  display: block;
                  top: 0;
                  bottom: 0;
                  left: -50px;

                }
              }
            }
          }


        }
      }
      .pagination{
        .nav-links{
          .page-numbers{
            margin: 0 5px;
          }
        }
      }
    }
  }

  //**********Single*Post*Page**************//
  .single-post{
    .thumbnail{
      top: 0;
      left: 50%;
      width: 100vw;
      transform: translate(-50%, 0) scale(1);
      min-height: 180px;
      max-height: 450px;
      img{
        width: 100vw;
        max-height: 450px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .post-container{
      padding: 80px 95px 60px 95px;
      margin-top: -195px;
      .title{
        font-size: 40px;
        line-height: 55px;
      }
    }
    .site__navigation{
      margin: 150px 0;
      .site__navigation__prev,
      .site__navigation__next{
        display: flex;
        flex: auto;
        margin-top: 0;
        max-width: 48%;
        margin-right: 35px;
        .thumbnail{
          display: block;
          margin-right: 30px;
        }
        .text-container{
          max-width: 45%;
          flex-basis: 50%;
        }
      }
    }
  }

  //**********Block*JobOffer*Carrousel******//
  .block-job-offers{
    padding: 200px 0 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .block-job-offers-title{
              color: #17385e;
            }
            .block-job-offers-carousel{
              .swiper-container{
                max-width: 450px;
                .swiper-wrapper{
                  .block-job-offers-carousel-slide{
                    .slide-row{
                      width: 100%;
                      .job-offer-embed{
                        .picto-job-offer{
                          position: absolute;
                        }
                        .title{
                          h1{
                            padding-left: 85px;
                            padding-right: 15px;
                            margin: 0 0 17px 0;
                          }
                        }
                        .center{
                          margin: 15px 0 5px;
                        }
                      }
                      .formation-embed{
                        .title{
                          h3{
                            margin: 0 0 17px 0;
                          }
                        }
                      }
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  color: #ffffff;
                  top: -14%;
                }
                .swiper-button-next{
                  right: auto;
                  left: 750px;
                }
                .swiper-button-prev{
                  left: 680px;
                }
                .blue-square{
                  width: 230%;
                  height: 100%;
                  top: -535px;
                  left: 575px;
                }
              }
            }
            .block-job-offers-button{
              margin-top: 0;
            }
          }
        }
      }
    }
    &::before{
      display: block;
      left: 33%;
    }
  }

  //**********Block*Three*Columns*Actu*******//
  .block-three-columns-actu{
    .actu-three-columns-block{
      .actu-three-columns-block-container{
        .actu-three-columns-block-row{
          .actu-three-columns-block-is-one-third{
            width: 30.72%;
            margin-right: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .block-three-columns-button{
      a{
        width: 330px;
      }
    }
  }

  //*********Block*Slider*Home*Intro********//
  .slider-intro-block{
    .swiper-container-intro-block{
      .swiper-wrapper{
        min-height: unset;
        .slider-intro-block-slide{
          .slide-row{
            .slider-intro-block-inner{
              .slider-intro-block-container{
                .slider-intro-block-row{
                  flex-direction: row;
                  flex-wrap: unset;
                  .slider-intro-block-column-one{
                    width: 40%;
                    padding: 45px 90px 45px 0;
                    .slider-intro-block-column-one-inner{
                      .slider-intro-block-title{
                        font-size: 50px;
                        line-height: 55px;
                        margin-bottom: 15px;
                      }
                      .slider-intro-block-desc{
                        font-size: 18px;
                      }
                    }
                  }
                  .slider-intro-block-column-two{
                    width: 105%;
                    transform: translate(0, 0);
                    .slider-intro-block-column-two-inner{
                      .slider-intro-block-column-two-image{
                        overflow: hidden;
                        .slider-intro-block-img{
                          transform: translate(-130px, 0);
                          max-height: 540px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .swiper-pagination{
        left: 0;
        .swiper-pagination-bullet{
          margin: 0 25px;
        }
      }
      .swiper-button-next{
        left: 365px;
      }
      .swiper-button-prev{
        left: 300px;
      }
    }
  }

  //*************Block*Koban*Form************************//
  .kbnzone{
    .row{
      #ncform{
        flex-direction: row;
        .ncFieldct{
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          align-items: flex-start;
          width: 50%;
          label{
            color: #17385e;
            font-family: $openSans-bold;
            font-size: 15px;
            display: block;
            width: auto;
            text-align: left;
            padding: 0;
            margin: 0 0 15px;
          }
          .ncField{
            width: 100%;
            input[type="text"],
            input[type="email"]{
              width: 65%;
              max-width: 391px;
            }
            .multirequired{
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: center;
              justify-content: space-between;
              align-items: center;
            }
          }
          &.koba-form-33{
            width: 33.33%;
          }
          &.tpboolean{
            .ncField{
              width: fit-content;
            }
          }
        }
        .ncSendZone{
          width: 50%;
        }
      }
    }
  }

  //************Block*tab*semantic*link******************//
  .tab_semantic_link_block{
    padding-top: 100px;
    .tab_semantic_link_block-container{
      .tab_semantic_link_block-columns-row{
        .tab_semantic_link_block-column{
          &::before{
            top: 50%;
            height: 50%;
          }
          .tab_semantic_link_block-column-inner{
            flex-direction: unset;
            .tab_semantic_link_block-column-content{
              margin-bottom: 100px;
              margin-right: 30px;
              flex: 0 1 33.33%;
              width: unset;
              padding: 40px 30px;
            }
          }
        }
      }
    }
  }

  //************Block*summary*semantic*link******************//
  .summary_semantic_link_block{
    .summary_semantic_link_block-container{
      .summary_semantic_link_block-columns-row{
        padding: 35px;
      }
    }
  }

  //***********Template*trombonoscope*surgeon*************//
  .trombinoscope-surgeon{
    .listing-surgeon{
      .filter-posts{
        .searchandfilter{
          div{
            ul{
              display: inline-block;
              li{
                display: inline-block;
                padding-right: 10px;
                .ofcategory{
                  width: 230px;
                  margin: 0 30px 0 0;
                  padding: 15px 20px;
                  &:not(:last-child){
                    margin: 0 30px 0 50px;
                  }
                }
                input[type="submit"]{
                  width: 211px;
                }
              }
            }
          }
        }
      }
      .cart-wrapper{
        margin: 50px 0;
        .cart-container{
          .cart-surgeon{
            flex-direction: row;
            width: 100%;
            padding: 30px;
            text-align: left;
            .column-one {
              width: 15%;
              .thumbnail-surgeon{
                margin: unset;
              }
            }
            .column-two {
              width: 50%;
              border-right: 1px solid #6ab2e3;
              border-bottom: 0 solid #6ab2e3;
              padding-right: 50px;
              margin-bottom: 0;
            }
            .column-three {
              width: 35%;
              padding-left: 60px;
            }
          }
        }
        .pagination{
          .page-numbers {
            margin: 0 40px;
          }
        }
      }
    }
  }

  //***********Single*Training******************//
  .single-training {
    .row-header{
      padding-bottom: 360px;
      .row-flex {
        flex-direction: row;
        .row-content {
          flex: 0 1 60%;
          max-width: 60%;
        }
        .row-cta {
          flex: 0 1 30%;
          max-width: 30%;
          .cta-blue,
          .cta-border-blue {
            font-size: 14px;

          }
        }
      }
    }
    .row-info {
      margin-top: -200px;
      .column-img {
        width: 100%;
        top: calc(50% - 35px);
        left: unset;
        transform: translateY(-50%);
        .img-info {
          margin: 0;
        }
      }
      .column-content {
        left: 33.3333%;
        .inner-column-content {
          padding: 150px 175px 150px 350px;
        }
      }
    }
    .row-former {
      padding: 150px 0;
      .card-container{
        .card-former {
          flex-direction: row;
          padding: 30px;
          text-align: left;
          .column-one {
            width: 15%;
            .thumbnail-former {
              margin: 0;
            }
          }
          .column-two {
            width: 50%;
            border-right: 1px solid #6ab2e3;
            border-bottom: 0 solid #6ab2e3;
            padding-right: 50px;
            margin-bottom: 0;
            .text-container{
              .centers {
                .center-surgeon {
                  margin-bottom: 35px;
                }
              }
            }
          }
          .column-three {
            text-align: left;
            width: 35%;
            padding-left: 60px;
          }
        }
      }
      &::before{
        display: block;
      }
    }
    .row-content {
      .inner-content {
        p {
          flex-direction: row;
        }
      }
    }
  }

  //***********Listing*Former*************//
  .listing-former{
    .inner-listing-former{
      .filter-posts{
        .searchandfilter{
          div{
            ul{
              display: inline-block;
              li{
                display: inline-block;
                padding-right: 10px;
                .ofcategory{
                  width: 230px;
                  margin: 0 30px 0 0;
                  padding: 15px 20px;
                  &:not(:last-child){
                    margin: 0 30px 0 50px;
                  }
                }
                input[type="submit"]{
                  width: 211px;
                }
              }
            }
          }
        }
      }
      .cart-wrapper{
        margin: 50px 0;
        .cart-container{
          .cart-former{
            flex-direction: row;
            width: 100%;
            padding: 30px;
            text-align: left;
            .column-one {
              width: 15%;
              .thumbnail-surgeon{
                margin: unset;
              }
            }
            .column-two {
              width: 50%;
              border-right: 1px solid #6ab2e3;
              border-bottom: 0 solid #6ab2e3;
              padding-right: 50px;
              margin-bottom: 0;
            }
            .column-three {
              width: 35%;
              padding-left: 60px;
            }
          }
        }
        .pagination{
          .page-numbers {
            margin: 0 40px;
          }
        }
      }
    }
  }

  //***********Single*Fomer************//
  .inner-single-former {
    padding: 80px 95px 60px 95px;
    .row-header{
      padding: 50px 0;
      flex-direction: row;
      align-items: unset;
      .column-content {
        margin-left: 30px;
        .link-all-training {
          padding: 25px;
          font-size: 14px;
        }
      }
    }
    .row-info {
      padding: 50px 0;
      .former-diplome {
        ul{
          li{
            flex: 0 1 50%;
            max-width: 50%;
          }
        }
      }
    }
  }

  //********* Catalog*Training********//
  .catalog-training{
    .catalog-container{
      padding: 80px 95px 60px 95px;
      margin-top: -195px;
      .listing-training{
        .filter-posts{
          .searchandfilter{
            div{
              ul{
                display: inline-block;
                li{
                  display: inline-block;
                  padding-right: 10px;
                  .ofcategory{
                    width: 200px;
                    margin: 0;
                    padding: 15px 20px;
                    &:not(:last-child){
                      margin: 0 30px;
                    }
                  }
                  input[type="submit"]{
                    width: 211px;
                  }
                }
              }
            }
          }
        }
        .cart-wrapper{
          .cart-container{
            flex-direction: row;
            justify-content: space-between;
            .card-training {
              flex: 0 1 48%;
              max-width: 48%;
              .formation-embed {
                padding: 35px 25px;
              }
            }
          }
          .pagination{
            .page-numbers {
              margin: 0 40px;
            }
          }
        }
      }
    }
  }

  //**********Block*Three*Columns*Former*******//
  .block-three-columns-former{
    .former-three-columns-block{
      .former-three-columns-block-container{
        .former-three-columns-block-row{
          .former-three-columns-block-is-one-third{
            width: 30.72%;
            margin-right: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
    .block-three-columns-button{
      a{
        width: 330px;
      }
    }
  }

  //***********Block*Certifications*Logo*block**********//
  .certifications-logo-block{
    .certifications-logo-row{
      flex-direction: row;
      align-items: stretch;
      .logo-item {
        &:not(:last-child) {
          margin-right: 30px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (min-height: 300px){
  //**********Block*Type*Pathology*****//
  .block-type-pathology{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 295px !important;
            }
          }
        }
      }
    }
  }

  //*****Block*Type*Article*To*Other*Page**//
  .block-type-article-to-other-page{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 170px !important;
            }
          }
        }
      }
    }
  }
}