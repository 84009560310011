#tarteaucitronRoot{
  #tarteaucitronAlertBig{
    background: #fff !important;
    color: #173354;
    #tarteaucitronDisclaimerAlert{
      display: flex;
      text-align: center;
      font: 15px verdana;
      color: #17365a;
      justify-content: center;
      align-items: center;
      align-content: center;
      max-width: 1440px;
      margin: 0 auto 10px;
      strong{
        color: #17365a !important;
      }
    }
    #tarteaucitronPersonalize{

    }
    .tarteaucitronDeny{
      display: none !important;
    }
    #tarteaucitronCloseAlert{
      background: #17365a;
      color: #fff;
      display: inline-block;
      font-size: 16px!important;
      line-height: 1.2;
      padding: 5px 10px;
      text-decoration: none;
      margin-left: 7px;
    }
    #tarteaucitronPrivacyUrl{
      color: #17365a !important;
    }
    >br{
      display: none;
    }
  }
}