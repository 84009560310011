.block-type-offers{
  padding: 100px 0;
  .advgb-columns-container{
    .advgb-columns-row{
      background-color: #ffffff;
      box-shadow: 0 2px 50px 0 rgba(7, 26, 30, 0.12);
      .advgb-is-one-quarter-tablet{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25% !important;
      }
      .block-type-offers-column-picto{
        width: 15% !important;
        .advgb-column-inner{
          .block-type-offers-picto{
            padding: 28px 15px;
            background-color: transparent;
            background-size: auto;
            height: 60px !important;
            width: 60px !important;
            .advgb-image-overlay{
              display: none;
            }
          }
        }
      }
      .block-type-offers-column-title{
        .advgb-column-inner{
          .block-type-offers-title{
            font-family: $openSans-bold;
            font-size: 20px;
            line-height: 1.4;
            color: #17385e;
            padding: 28px 15px;
          }
        }
      }
      .block-type-offers-column-content{
        width: 30%;
        .advgb-column-inner{
          .block-type-offers-content{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 1.88;
            color: #333333;
            padding: 28px 15px;
          }
        }
      }
      .block-type-offers-column-button{
        width: 30%;
        .advgb-column-inner{
          .block-type-offers-button{
            padding: 28px 15px;
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              padding: 15px 55px;
              &:hover{
                box-shadow: none;
                background-color: #ffffff !important;
                color: #2196f3 !important;
              }
            }
          }
        }
      }
    }
  }
}