.inner-single-former {
  padding: 80px 95px 60px 95px;
  box-shadow: 0 20px 40px 0 rgb(7 26 30 / 12%);
  background-color: #ffffff;
  margin-bottom: 100px;
  .row-header{
    position: relative;
    padding: 50px 0;
    border-bottom: 1px solid #6AB2E3;
    display: flex;
    .column-img {
      border-radius: 100%;
      overflow: hidden;
      width: 205px;
      height: 205px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .column-content {
      margin-left: 30px;
      h1 {
        color: #17385E;
      }
      .former-speciality {
        font-family: $openSans-bold;
        font-size: 30px;
        color: #17385E;
        a{
          font-family: $openSans-bold;
          font-size: 30px;
        }
      }
      .link-all-training {
        display: block;
        width: fit-content;
        background-color: #6AB2E3;
        border: solid 2px #6ab2e3;
        box-shadow: 40px 10px 80px 0 rgba(11,30,53,0.09);
        padding: 25px;
        text-align: center;
        color: #FFFFFF;
        text-transform: uppercase;
        font-family: $openSans-bold;
        font-size: 14px;
        letter-spacing: 2px;
        margin-top: 20px;
        margin-bottom: 20px;
        &:hover{
          background-color: #ffffff;
          border: solid 2px #6ab2e3;
          color: #6ab2e3;
        }
      }
    }
  }
  .row-info {
    padding: 50px 0;
    .former-diplome {
      p {
        font-family: $openSans-regular;
        font-size: 16px;
        line-height: 26px;
        color: #17385E;
        margin-bottom: 30px;
      }
      ul,li{
        list-style-type: disc;
        list-style-position: inside;
      }
      ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        li{
          flex: 0 1 50%;
          max-width: 50%;
          font-family: $openSans-regular;
          font-size: 16px;
          line-height: 26px;
          color: #17385E;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}