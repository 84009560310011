// Swiper
@import '~swiper/swiper-bundle.min.css';

@import 'initialize/variables';
@import 'initialize/functions';
@import 'initialize/reset';
@import 'initialize/grid';


//Blocs
@import 'generic/header';
@import "generic/footer";

//Blocs page
@import "generic/block/testimony-block";
@import "generic/block/intro-surgery-block";
@import "generic/block/video-slider";
@import "generic/block/blue-block-questions-frequency";
@import "generic/block/home-faq-block";
@import "generic/block/frieze-block";
@import "generic/block/video-carousel";
@import "generic/block/two-columns";
@import "generic/block/title-text";
@import "generic/block/one-cta";
@import "generic/block/double-cta";
@import "generic/block/iso-9001-block";
@import "generic/block/block-type-pathology";
@import "generic/block/block-type-tabs";
@import "generic/block/block-type-quote";
@import "generic/block/intro-corpo-block";
@import "generic/block/block-three-columns";
@import "generic/block/block-numbers";
@import "generic/block/block-map-of-centers";
@import "generic/block/block-metiers";
@import "generic/block/block-video-with-title-content";
@import "generic/block/block-actus-FB";
@import "generic/block/block-type-article-to-other-page";
@import "generic/block/block-type-offers";
@import "generic/block/block-banner";
@import "generic/block/faq-block";
@import "generic/block/block-list";
@import "generic/block/block-job-offers";
@import "generic/block/block-actu-three-columns";
@import "generic/block/block-slider-intro";
@import "generic/block/block-koban-form";
@import "generic/block/block-tab-semantic-link-block";
@import "generic/block/block-summary-sementic-link";
@import "generic/block/block-former-three-columns";
@import "generic/block/block-certifications-logo";

//Template
@import "generic/templates/form";
@import "generic/templates/archive-map";
@import "generic/templates/tarteaucitron";
@import "generic/templates/wizville";
@import "generic/templates/404";
@import "generic/templates/posts-teaser";
@import "generic/templates/single-post";
@import "generic/templates/single-training";
@import "generic/templates/single-former";
@import "generic/templates/trombinoscope-surgeon";
@import "generic/templates/catalog-training";
@import "generic/templates/listing-former";

//Style commun
@import "generic/common";

//Responsives
@import "responsive/breakpoint-320px";
@import "responsive/breakpoint-576px";
@import "responsive/breakpoint-768px";
@import "responsive/breakpoint-992px";
@import "responsive/breakpoint-1200px";
@import "responsive/breakpoint-1440px";
