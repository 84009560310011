.block-job-offers{
  padding: 200px 0 0 0;
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          height: 100%;
          margin-bottom: 200px;
          .block-job-offers-title{
            margin: 0 0 50px;
            font-family: $openSans-bold !important;
            font-size: 40px;
            line-height: 1.38;
            color: #17385e;
            position: relative;
            top: 0;
            left: 0;
            z-index: 2 !important;
            width: fit-content;
          }
          .block-job-offers-carousel{
            height: 375px;
            width: 100vw;
            .swiper-container{
              max-width: 450px;
              margin: 0;
              overflow: visible;
              .swiper-wrapper{
                min-height: 292px;
                .block-job-offers-carousel-slide{
                  .slide-row{
                    position: relative;
                    width: 100%;
                    box-shadow: 0 20px 40px 0 rgba(45, 53, 62, 0.22);
                    min-height: 310px;
                    .job-offer-embed{
                      padding: 35px 25px;
                      position: relative;
                      .picto-job-offer{
                        position: absolute;
                        width:  65px;
                        height: 52px;
                      }
                      .title{
                        min-height: 56px;
                        h1{
                          padding-left: 85px;
                          padding-right: 15px;
                          margin: 0 0 17px 0;
                          font-family: $openSans-bold;
                          font-size: 20px;
                          line-height: 1.4;
                          color: #17385e;
                          text-align: left;
                        }
                      }
                      .center{
                        margin: 15px 0 5px;
                        text-align: left;
                        p{
                          display: inline;
                          font-family: $openSans-bold;
                          font-size: 16px;
                          line-height: 1.88;
                          color: #333333;
                        }
                      }
                      .exercpt{
                        margin: 5px 0 20px;
                        font-family: $openSans-regular;
                        font-size: 16px;
                        line-height: 1.88;
                        color: #333333;
                        text-align: left;
                      }
                      .link{
                        text-align: left;
                        a{
                          font-family: $openSans-bold;
                          font-size: 18px;
                          line-height: 1.33;
                          color: #17385e;
                        }
                      }
                    }
                    .formation-embed {
                      padding: 35px 25px;
                      position: relative;
                      .title{
                        min-height: 56px;
                        h3{
                          padding-right: 15px;
                          margin: 0 0 12px 0;
                          font-family: $openSans-bold;
                          font-size: 20px;
                          line-height: 1.4;
                          color: #17385e;
                          text-align: left;
                        }
                      }
                      .categories-row {
                        text-align: left;
                        margin-bottom: 15px;
                        .category {
                          width: fit-content;
                          display: inline-block;
                          font-family: $openSans-semi-bold;
                          font-size: 13px;
                          color: #ffffff;
                          background-color: #0a4b78;
                          padding: 6px 12px;
                          border-radius: 17.5px;
                          line-height: normal;
                          &:not(:last-child) {
                            margin-right: 5px;
                          }
                        }
                      }
                      .duration-price-row {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        margin-top: 15px;
                        margin-bottom: 8px;
                        .duration {
                          position: relative;
                          font-family: $openSans-bold;
                          font-size: 16px;
                          line-height: 1.4;
                          color: #333333;
                          padding-left: 35px;
                          &::before {
                            content: "";
                            position: absolute;
                            display: block;
                            top: 0;
                            left: 0;
                            width: 22px;
                            height: 22px;
                            background-image: url("../../../images/clock.png");
                            background-size: contain;
                            background-repeat: no-repeat;

                          }
                        }
                        .price {
                          font-family: $openSans-bold;
                          font-size: 16px;
                          line-height: 1.4;
                          color: #333333;
                        }
                      }
                      .exercpt{
                        margin: 5px 0 20px;
                        font-family: $openSans-regular;
                        font-size: 16px;
                        line-height: 1.88;
                        color: #333333;
                        text-align: left;
                      }
                      .link{
                        text-align: left;
                        a{
                          font-family: $openSans-bold;
                          font-size: 18px;
                          line-height: 1.33;
                          color: #17385e;
                        }
                      }
                    }
                  }
                  &.swiper-slide{
                    opacity: 0;
                  }
                  &.swiper-slide-active{
                    opacity: 1;
                  }
                  &.swiper-slide-active ~ .swiper-slide{
                    opacity: 1;
                  }
                }
              }
              .swiper-button-prev,
              .swiper-button-next{
                color: #ffffff;
                top: -14%;
              }
              .swiper-button-next:after,
              .swiper-button-prev:after{
                font-size: 16px;
              }
              .swiper-button-next{
                right: auto;
                left: 1120px;
              }
              .swiper-button-prev{
                left: 1060px;
              }
              .blue-square{
                position: relative;
                width: 230%;
                height: 100%;
                top: -450px;
                left: 780px;
                overflow: visible;
                box-shadow: 10px 10px 80px 0 rgba(139, 139, 139, 0.17);
                background-color: #17385e;
              }
            }
          }
          .block-job-offers-button{
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              letter-spacing: 2px;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-image: url("../../../images/motif-4.png");
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-50%, 0);
  }
}