.certifications-logo-block{
  padding: 100px 0;
  position: relative;
  z-index: 51 !important;
  .certifications-logo-row{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    .logo-item {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: var(--c-block-certifs-background-color);
    box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
    transform: translate(-50%, 0);
  }
}
