.faq-block {
  .advgb-accordion-header,
  .advgb-accordion-header.ui-accordion-header.ui-state-default {
    border: 0;
    background: #17385e !important;
    margin: 0;
    padding: 0;
    position: relative;
    outline: none;

    h4 {
      font-size: 15px;
      color: #ffffff !important;
      padding: 13px 20px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 25px;
      right: 20px;
      height: 14px;
      width: 14px;
      transform: translate(0, -50%) rotate(135deg);
      border-right: 2px solid #ffffff;
      border-top: 2px solid #ffffff;
      pointer-events: none;
    }
  }

  .advgb-accordion-header-icon {
    display: none;
  }

  .advgb-accordion-body,
  .advgb-accordion-wrapper .advgb-accordion-body.ui-widget-content {
    border-color: #17385e;
    margin: 0;

    ul,li{
      list-style-type: disc;
    }
    ul{
      font-size: 16px;
      list-style-position: inside;
    }
  }
}