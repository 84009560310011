footer{
	background-color: #ffffff;

	.pre-footer{
		display: flex;
		justify-content: center;
		margin-bottom: 50px;

		a{
			font-family: $openSans-bold;
			color: #ffffff;
			letter-spacing: 2px;
			text-transform: uppercase;
			width: 100%;
			text-align: center;
			font-size: 14px;
		}

		.talk,.faq{
			font-size: 14px;
			padding: 20px 65px 20px 65px;
			border-radius: 0;
			text-align: center;
		}
		.faq{
			background-color: #5CA2D5;
			margin-left: 20px;
			cursor: pointer;
			border: 2px solid #5CA2D5;

			&:hover{
				background-color: #ffffff;
				box-shadow: 1px 1px 12px 0 #ccc;
				opacity: 1;
				transition: all 0.2s ease;
				border: 2px solid #ffffff;

				a{
					color: #5CA2D5 ;
				}
			}
		}
		.talk{
			background-color: #a8acb2;
			border: 2px solid #a8acb2;
			margin: 0 0 0 20px;
			cursor: pointer;

			&:hover{
				border: solid 2px #a8acb2;
				background-color: #ffffff;

				a{
					color: #a8acb2;
				}
			}
		}
	}

	.menu_footer{
		background-image: linear-gradient(to bottom, #173150, #17385e);
		padding: 60px 0 27px;

		a,p{
			color: #ffffff;
			font-size: 16px;
			font-family: $openSans-bold;
			line-height: normal;
			font-weight: normal;
		}

		.footer-flex{
			display: flex;
			position: relative;
			max-width: 1440px;
			margin-bottom: 68px;
			&.semantic-cocon-footer{
				justify-content: center;
				align-content: center;
				align-items: center;
				.networks{
					p{
						text-align: center;
					}
					.logos {
						justify-content: center;
					}
				}
			}
			&.footer-formation {
				.menu-footer-menu-container {
					width: 50%;
					.menu {
						width: 95%;
						justify-content: space-between;
						.menu-item {
							flex: 0 1 48%;
						}
					}
				}
			}
		}

		.opv-formation-address {
			width: 25%;
			p {
				font-family: $openSans-regular;
			}
		}

		.menu{
			display: flex;
			flex-wrap: wrap;
			width: calc(100% / 12 * 5);
			height: fit-content;

			.menu-item{
				flex: 1 1 50%;
				padding-bottom: 20px;
			}
		}

		.networks{
			display: flex;
			flex-direction: column;

			p{
				font-family: $openSans-regular;
				font-size: 15px;
				flex: 0 0 40px;
			}
			.logos{
				display: flex;

				a{
					padding-right: 30px;
				}

				img{
					min-width: 24px;
					min-height: 24px;
				}
			}
		}

		.languages{
			margin-left: calc(100% / 12 * 1);
			width: calc(100% / 12 * 1);

			.lang-item{
				a{
					display: flex;
					position: relative;
					align-items: center;
				}

				img{
					width: 66px;
					min-height: 37px;
				}

				p{
					margin-left: 20px;
					font-size: 15px;
					font-family: $openSans-regular;
				}
			}
		}
		.iso{
			margin-left: calc(100% / 12 * 1);

			figure{
				img{
					min-width: 90px;
				}
			}
		}
		.grp{

			a,p{
				font-family: $openSans-regular;
				font-size: 15px;
				text-align: center;
				line-height: normal;
				font-weight: normal;
			}

			a{
				text-decoration: underline;
			}
		}
		.scrollUp{
			background-color: #ffffff;
			border-radius: 100%;
			box-shadow: 0 0 9px 0 rgba(23, 56, 94, 0.15);
			position: absolute;
			top: -60px;
			right: 90px;
			width: 54px;
			height: 54px;
			transform: translateY(-50%);
			cursor: pointer;
			z-index: 1000;

			&:hover{
				background-color: #5CA2D5;

				svg{
					.arrow{
						fill: #ffffff;
					}
				}
			}
			&.style-corpo:hover{
				background-color: #6ab2e3;
			}
			svg{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}
