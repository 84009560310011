.intro-corpo-block{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column.advgb-is-one-third-tablet{
        width: 50% !important;
        padding: 100px 145px 100px 0;
        .advgb-column-inner{
          .intro-corpo-block-title{
            font-family: $openSans-bold;
            font-size: 50px;
            line-height: 1.1;
            color: #17385e;
          }
          .intro-corpo-block-content{
            font-family: $openSans-regular;
            text-align: justify;
            font-size: 18px;
            line-height: 1.67;
            color: #333333;
            margin-top: 30px;
            ul, ol {
              font-family: $openSans-regular;
              li {
                font-size: 16px;
                line-height: 24px;
                position: relative;
                padding: 5px 5px 5px 30px;

                &::before {
                  content: '';
                  height: 6px;
                  width: 6px;
                  background: #000000;
                  border-radius: 50%;
                  position: absolute;
                  left: 0;
                  top: 15px;
                }
              }
            }

            ol {

              li {
                padding: 5px 5px 5px 0;
                list-style-type: decimal;
                list-style-position: inside;

                &::before {
                  content: none;
                }

                ol {

                  li {
                    margin: 5px 0 0 40px;
                  }
                }
              }
            }
          }
        }
      }
      .advgb-column.advgb-is-two-thirds-tablet{
        width: 100% !important;
        .advgb-column-inner{
          .intro-corpo-block-image{
            margin: 0;
            background-color: #eef3f6;
            height: 100% !important;
            width: 66% !important;
            background-size: cover;
            .advgb-image-overlay{
              display: none;
            }
          }
        }
      }
    }
  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    transform: translateX(-50%);
    background-color: #eef3f6;
  }
  &.img-left{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          width: 50% !important;
          padding: 100px 145px 100px 0;
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          width: 66% !important;
          .advgb-column-inner{
            .intro-corpo-block-image{
              margin: 0;
              background-color: #eef3f6;
              height: 100% !important;
              width: 100% !important;
              background-size: cover;
              .advgb-image-overlay{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}