.block-three-columns{
  position: relative;
  padding: 100px 0 230px 0;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-is-one-third-tablet{
        width: 27.7888%;
        margin-right: 11.5%;
        .advgb-column-inner{
          .advgb-columns-wrapper{
            .advgb-columns-container{
              .advgb-columns-row{
                max-width: 100%;
                margin: 0;
                padding: 0;
                margin-bottom: 80px;
                .block-three-columns-img-column{
                  position: relative;
                  z-index: 5 !important;
                  left: 25px;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  width: 85.777%;
                  height: fit-content;
                  .advgb-column-inner{
                    box-shadow: 0 20px 40px 0 rgba(7, 26, 30, 0.33);
                    .block-three-columns-img{
                      .advgb-image-overlay{
                        display: none;
                      }
                    }
                  }
                }
                .block-three-columns-content-column{
                  width: 100% !important;
                  position: relative;
                  top: 80px;
                  left: -85.777%;
                  right: 0;
                  bottom: 0;
                  background-color: #ffffff;
                  box-shadow: 0 20px 40px 0 rgb(7 26 30 / 33%);
                  .advgb-column-inner{
                    padding: 150px 25px 55px 25px;
                    .block-three-columns-title{
                      font-family: $openSans-bold;
                      font-size: 25px;
                      line-height: 1.4;
                      color: #17385e;
                    }
                    .block-three-columns-content{
                      font-family: $openSans-regular;
                      font-size: 16px;
                      line-height: 1.88;
                      color: #333333;
                    }
                  }
                }
              }
            }
          }
        }
        &:last-child{
          margin-right: 0 !important;
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-image: url("../../../images/motif.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 14.5%;
    background-color: #ffffff;
    transform: translate(-50%, 0);
  }
}