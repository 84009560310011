.slider-intro-block{
  position: relative;
  .swiper-container-intro-block{
    width: 100%;
    height: -moz-fit-content;
    .swiper-wrapper{
      width: 100%;
      .slider-intro-block-slide{
        .slide-row{
          display: flex;
          .slider-intro-block-inner{
            .slider-intro-block-container{
              .slider-intro-block-row{
                display: flex;
                flex-direction: row;
                position: relative;
                .slider-intro-block-column-one{
                  width: 40%;
                  padding: 65px 125px 65px 0;
                  z-index: 2 !important;
                  flex: none;
                  display: block;
                  .slider-intro-block-column-one-inner{
                    text-align: left;
                    .slider-intro-block-title{
                      font-family: $openSans-bold;
                      font-size: 50px;
                      line-height: 55px;
                      color: #17385e;
                      margin-bottom: 15px;
                      text-align: left;
                    }
                    .slider-intro-block-desc{
                      font-family: $openSans-regular;
                      font-size: 18px;
                      line-height: 30px;
                      color: #333333;
                      margin-bottom: 30px;
                      text-align: justify;
                    }
                    .slider-intro-block-link{
                      padding: 15px 5px;
                      font-family: $openSans-bold;
                      font-size: 14px;
                      letter-spacing: 2px;
                      text-align: center;
                      color: #17385e;
                      border-bottom: solid 2px #6ab2e3;
                    }
                  }
                  &::before{
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100vw;
                    z-index: -1 !important;
                    transform: translateX(-50%);
                    background-color: #eef3f6;
                  }
                }
                .slider-intro-block-column-two{
                  width: 67%;
                  z-index: 2 !important;
                  flex: none;
                  display: block;
                  .slider-intro-block-column-two-inner{
                    .slider-intro-block-column-two-image{
                      .slider-intro-block-img{

                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.swiper-slide{
          opacity: 0;
        }
        &.swiper-slide-active{
          opacity: 1;
        }
      }
    }
    .swiper-pagination{
      bottom: 25px;
      left: 0;
      width: fit-content;
      text-align: left;
      .swiper-pagination-bullet{
        width: 45px;
        height: 3px;
        opacity: 1;
        background: #97b1b9;
        margin: 0 25px;
        border-radius: 15%;
        &:first-child{
          margin-left: 0 !important;
        }
        &:last-child{
          margin-right: 0 !important;
        }
      }
      .swiper-pagination-bullet-active{
        background: #17385e;
      }
    }
    .swiper-button-next,
    .swiper-button-prev{
      color: #17385e;
      bottom: 10px;
      top: unset;
      right: auto;
      &::after{
        font-size: 16px;
      }
    }
    .swiper-button-next{
      left: 425px;
    }
    .swiper-button-prev{
      left: 365px;
    }
  }
}

.chirurgie-home-slider{
  .advgb-columns-container{
    .advgb-columns{
      .advgb-column{
        .advgb-column-inner{
          .slider-intro-block{
            .swiper-container-intro-block{
              .swiper-wrapper{
                .slider-intro-block-slide{
                  .slide-row{
                    .slider-intro-block-inner{
                      .slider-intro-block-container{
                        .slider-intro-block-row{
                          .slider-intro-block-column-one{
                            .slider-intro-block-column-one-inner{
                              .slider-intro-block-link{
                                color: #17385e;
                                border-bottom: solid 2px #D5AE60;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .swiper-pagination{
                .swiper-pagination-bullet-active{
                  background: #D5AE60;
                }
              }
              .swiper-button-next,
              .swiper-button-prev{
                color: #17385e;
              }
            }
          }
        }
      }
    }
  }
}