.banner{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  top: 0;
  width: 100%;
  min-height: 180px;
  //background-color: rgba(233, 49, 49, 0.12);
  box-shadow: rgba(0, 0, 0, 0.05) 0 3px 4px;
  z-index: 51 !important;
  box-sizing: border-box;
  transition: -webkit-transform ease 0.3s;
  transition: transform ease 0.3s;
  -webkit-transform: translateY(0%) translate3d(0,0,0);
  transform: translateY(0%) translate3d(0,0,0);
  .banner-container-outer{
    position: relative;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    margin: 0;
    padding: 0;

    .banner-container{
      width: 970px;
      position: relative;
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
      padding: 10px 60px;
      line-height: 1.4;
      .banner-content{
        position: relative;
        .banner-icon{
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(calc(-100% - 66px),-50%);
        }
        .banner-text{
          p{
            font-family: $openSans-regular;
            font-size: 14px;
            line-height: 1.77;
            text-align: center;
            color: #333333;
          }
        }
        .banner-link{
          font-family: $openSans-bold;
          font-size: 16px;
          line-height: 1.88;
          text-decoration: none;
          color: #e93131;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          border-bottom: solid 2px #e93131;
          width: fit-content;
        }
      }

    }
    .banner-close{
      background-color: transparent;
      color: #e93131;
      position: absolute;
      text-decoration: none;
      top: 0;
      right: -30px;
      font-size: 45px;
      text-align: center;
      box-sizing: initial;
      line-height: 1;
      span{
        display: inline-block;
        -webkit-transform: rotate(45deg) translate3d(0,0,0);
        transform: rotate(45deg) translate3d(0,0,0);
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
        margin: 0;
        padding: 0;
      }
    }
  }
}