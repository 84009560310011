.block-video-with-title-content{
  padding: 0 0 100px 0;
  position: relative;
  z-index: 51 !important;
  .advgb-columns-container {
    .advgb-columns-row {
      .block-video-with-title-content-column-video{
        width: 45%;
        margin-right: 126px;
        height: 303px;
        .advgb-column-inner{
          .home-block-video-content {
            .advgb-video-wrapper{
              background-color: transparent;
              #player{
                height: 100% !important;
              }
              .advgb-video-poster{
                opacity: 1;
                overflow: hidden;
                img{
                  width: 100%;
                  margin-top: -57px;
                }
              }
              .advgb-button-wrapper{
                height: 100%;
                width: 100%;
                z-index: 3;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                cursor: pointer;
                .advgb-play-button{
                  color: #c8c8c8 !important;
                }
              }
            }
          }
        }
      }
      .block-video-with-title-content-column-content{
        width: 35.555%;
        .advgb-column-inner{
          .block-video-with-title-content-title{
            font-family: $openSans-bold;
            font-size: 40px;
            line-height: 1.38;
            color: #17385e;
          }
          .block-video-with-title-content-content{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 1.88;
            color: #333333;
          }
        }
      }
    }
  }
}