.single-post{
  .thumbnail{
    position: relative;
    top: 0;
    left: 50%;
    z-index: -1 !important;
    width: 100vw;
    transform: translate(-50%, 0) scale(1);
    max-height: 450px;
    img{
      width: 100vw;
      max-height: 450px;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }
  .post-container{
    padding: 80px 95px 60px 95px;
    box-shadow: 0 20px 40px 0 rgb(7 26 30 / 12%);
    background-color: #ffffff;
    margin-top: -195px;
    .info-post{
      .time{
        width: fit-content;
        display: inline-block;
        font-family: $openSans-light;
        letter-spacing: 2px;
        color: #17385e;
        margin-right: 20px;
      }
      .category{
        width: fit-content;
        display: inline-block;
        font-family: $openSans-semi-bold;
        font-size: 13px;
        color: #ffffff;
        padding: 6px 12px;
        border-radius: 17.5px;
        line-height: normal;
        margin-right: 5px;
      }
    }
    .title{
      font-family: $openSans-bold;
      font-size: 40px;
      line-height: 55px;
      color: #17385e;
      margin: 10px 0;
    }
    .desc{
      font-family: $openSans-regular;
      font-size: 20px;
      line-height: 32px;
      color: #333333;
    }
    .share{
      margin: 15px 0 50px;
      .facebook{
        display: inline-block;
        cursor: pointer;
      }
      .twitter{
        display: inline-block;
        cursor: pointer;
        margin: 0 30px;
      }
      .linkedin{
        display: inline-block;
        cursor: pointer;
      }
    }
    .download{
      position: relative;
      a{
        font-family: $openSans-bold !important;
        font-size: 18px;
        line-height: 24px;
        padding-left: 50px;
        span{
          font-family: $openSans-bold !important;
        }
      }

      &::before{
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-image: url("../../../images/icon-download.png");
        background-size: cover;
        width: 35px;
        height: 29px;
      }
    }
    ul, ol {
      font-family: $openSans-regular;
      li {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        padding: 5px 5px 5px 30px;

        &::before {
          content: '';
          height: 6px;
          width: 6px;
          background: #000000;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 15px;
        }
      }
    }

    ol {

      li {
        padding: 5px 5px 5px 0;
        list-style-type: decimal;
        list-style-position: inside;

        &::before {
          content: none;
        }

        ol {

          li {
            margin: 5px 0 0 40px;
          }
        }
      }
    }
    h1,h2,h3,h4,h5,h6{
      span{
        font-family: $openSans-bold;
      }
    }
    p{
      font-family: $openSans-regular;
      font-size: 16px;
      line-height: 30px;
      color: #333333;
    }
  }
  .site__navigation{
    margin: 150px 0;
    font-size: 16px;
    .site__navigation__prev,
    .site__navigation__next{
      max-width: 48%;
      margin-right: 50px;
      padding: 20px 20px 30px;
      box-shadow: 0 20px 40px 0 rgba(7, 26, 30, 0.12);
      background-color: #ffffff;
      .thumbnail{
        left: 0;
        transform: none;
        z-index: auto;
        width: auto;
        max-width: 47%;
        margin-right: 30px;
        min-height: unset;
      }
      .text-container{
        max-width: 47%;
        .title{
          font-family: $openSans-bold;
          font-size: 20px;
          line-height: 28px;
          color: #17385e;
          margin-bottom: 20px;
        }
        .link{
          font-family: $openSans-bold;
          font-size: 14px;
          letter-spacing: 2px;
          text-align: center;
          color: #17385e;
          border-bottom: solid 2px #6ab2e3;
          margin-right: 40px;
          padding: 0 12px 15px;
        }
      }
    }
    .site__navigation__prev{
      .previous-post{
        margin: 15px 0 35px 20px;
        padding-left: 50px;
        font-family: $openSans-bold;
        font-size: 18px;
        line-height: 24px;
        color: #17385e;
        position: relative;
        &::before{
          content: ' ';
          display: block;
          position: absolute;
          top: -15px;
          left: -20px;
          width: 54px;
          height: 54px;
          background-color: #ffffff;
          box-shadow: 0 0 9px 0 rgba(23, 56, 94, 0.15);
          background-image: url("../../../images/icon-fleche-droite-bleu.png");
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          object-fit: contain;
        }
      }
    }
    .site__navigation__next{
      margin-right: 0 !important;
      .next-post{
        margin: 15px 0 35px 20px;
        padding-right: 75px;
        font-family: $openSans-bold;
        font-size: 18px;
        line-height: 24px;
        color: #17385e;
        text-align: right;
        position: relative;
        &::before{
          content: ' ';
          display: block;
          position: absolute;
          top: -15px;
          right: 0;
          width: 54px;
          height: 54px;
          background-color: #ffffff;
          box-shadow: 0 0 9px 0 rgba(23, 56, 94, 0.15);
          background-image: url("../../../images/icon-fleche-droite-bleu.png");
          transform: rotate(180deg);
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          object-fit: contain;
        }
      }
    }
  }
}