.block-type-pathology{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      position: relative;
      justify-content: center;
      .advgb-is-one-third-tablet{
        width: 100% !important;
        max-width: 748px;
        position: absolute;
        z-index: 15 !important;
        padding: 0;
        top: 50%;
        bottom: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        .advgb-column-inner{
          width: 100%;
          .wp-block-advgb-image{
            background-size: contain;
            .advgb-image-overlay{
              display: none;
            }
          }
        }
      }
      .advgb-is-two-thirds-tablet{
        padding: 150px 0;
        position: relative;
        left: 33.3333%;
        z-index: 10 !important;
        .advgb-column-inner{
          padding: 100px 90px 100px 300px;
          box-shadow: 0 20px 40px 0 rgba(11, 30, 53, 0.09);
          background-color: #ffffff;
          .block-type-pathology-title{
            font-family: $openSans-bold;
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #17385e;
          }
          .block-type-pathology-content{
            font-family: $openSans-regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #2d2d2d;
            margin-top: 30px;
            margin-bottom: 40px;
            ul, ol {
              font-family: $openSans-regular;
              li {
                font-size: 16px;
                line-height: 24px;
                position: relative;
                padding: 5px 5px 5px 30px;

                &::before {
                  content: '';
                  height: 6px;
                  width: 6px;
                  background: #000000;
                  border-radius: 50%;
                  position: absolute;
                  left: 0;
                  top: 15px;
                }
              }
            }

            ol {

              li {
                padding: 5px 5px 5px 0;
                list-style-type: decimal;
                list-style-position: inside;

                &::before {
                  content: none;
                }

                ol {

                  li {
                    margin: 5px 0 0 40px;
                  }
                }
              }
            }
          }
          .block-type-pathology-button{
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2px;
              text-align: center;
              color: #17385e !important;
              border: none;
              padding: 10px 0;
              width: fit-content;
              border-bottom: 2px solid #17385e;
              &:hover{
                opacity: 1;
                box-shadow: none;
                border-bottom: 2px solid #ffffff;
                color: #17385e !important;
              }
            }
          }
        }
      }
    }
  }
}