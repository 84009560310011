@media screen and (min-width: 1440px) {
  $container-width: 1440px;

  .container{
    max-width: $container-width;
  }

  //************Header**************************//
  header{
    .head{
      .title-logo-header{
        .header-title{
          font-size: 18px;
        }
      }
      .container{
        padding: 0 90px;
      }
      .row-contact-header{
        .contact-header{
          #label-contact-header{
            font-size: 13px;
            letter-spacing: -0.2px;
          }
          #num-contact-header{
            font-size: 16px;
          }
        }
        .center-button-header{
          min-height: 49px;
          padding: 0 25px;
          #inner-center-button-header{
            font-size: 14px;
          }
        }
        .question-button-header{
          min-height: 49px;
          padding: 0 25px;
          #inner-question-button-header{
            font-size: 14px;
          }
        }
      }

      /**Header*Corpo**/
      .container-header-corpo{
        max-width: $container-width;
        padding: 0 90px;
      }
    }
    .nav{
      .menu{
        max-width: $container-width;
        padding: 0 90px;
      }
      ul {
        li {
          a{
            font-size: 15px;
            padding: 4px 0;
          }
        }
      }
    }

    li.has-mega-menu{
      &:hover,
      &.clicked {
        .sub-menu{
          max-width: 1260px;
          .mega-sub-column{
            width: 24.333333%;
            &.menu-item-has-children:hover{
              .sub-menu{
                left: 55%;
              }
            }
          }
        }
      }
    }
  }

  //***********Block**Testimony****************//
  .home-block-testimony{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          padding: 100px 120px 100px 0;
          .advgb-column-inner{
            .testimony-block-title{
              font-size: 50px;
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-one-third-tablet{
            padding: 100px 120px 100px 0;
            left: -35px;
            .advgb-column-inner{
              .testimony-block-title{
                font-size: 50px;
              }
            }
          }
        }
      }
    }
  }

  //**********Block*Video*Slider*************//
  .home-block-video-slider{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          left: 0;
          width: 632px !important;
          .advgb-column-inner{
            .home-block-video-content{
              .advgb-video-wrapper{
                .advgb-video-poster{
                  img{
                    margin-top: -57px;
                  }
                }
              }
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          position: relative;
          padding: 72px 350px 72px 360px;
          max-height: 500px;
          left: 80%;
          overflow: auto;
        }
      }
    }
  }

  //**********Block*Blue*Question*Frequency*//
  .home-block-blue-block-questions-frequency{
    margin-top: -15%;
    min-height: auto;
  }

  //**********Block*FAQ*********************//
  .home-block-faq-block{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: -10%;
                  .advgb-is-one-fifth-tablet{
                    max-width: 365px;
                    padding-top: 365px;
                    margin: 25px !important;
                    .advgb-column-inner{
                      padding: 55px 85px;
                    }
                  }
                }
              }
            }
            .home-block-faq-block-row-button{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-is-half-tablet{
                    max-width: 365px;
                    margin: 25px !important;
                  }
                }
              }
            }
            .home-block-faq-block-row-parcour{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-column{
                    .advgb-column-inner{
                      .home-block-faq-block-parcour-title{
                        margin-top: 100px;
                      }
                      .home-block-faq-block-parcour-content{
                        max-width: 52%;
                        margin-top: 28px;
                        margin-bottom: 200px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //************Form**************************//
  .form{
    padding: 90px;
  }

  //**********Block*Frieze*****************//
  .home-block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        max-width: $container-width;
        .home-block-frieze-columns{
          width: 25% !important;
          .advgb-column-inner{
            padding: 40px 100px 40px 0;
          }
          &::after{
            width: 40%;
            height: 100%;
            left: 75%;
            top: -100%;
            background-size: unset;
          }
          &:last-child::after{
            width: 40%;
            height: 100%;
            left: 93%;
            top: -104%;
            background-size: unset;
          }
        }
      }
    }
  }

  //************Video*Carousel*************//
  .home-block-video-carousel{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  //**********Block*Type*Pathology*****//
  .block-type-pathology{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          max-width: 625px;
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 400px !important;
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          left: 33.3333%;
          .advgb-column-inner{
            padding: 100px 90px 100px 300px;
          }
        }
      }
    }
  }

  //*********Block*Type*Tabs**************//
  .block-type-tabs{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          margin-bottom: 100px !important;
        }
      }
    }
  }

  //*********Block*Type*Quote************//
  .block-type-quote{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          // padding-top: 28.58%;
          .advgb-column-inner{
            padding: 100px 55px 55px;
            .block-type-quote-content{
              &::before{
                top: 15%;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Intro*Corpo**********//
  .intro-corpo-block{
    .advgb-columns-container{
      .advgb-columns-row{
        flex-direction: row;
        .advgb-column.advgb-is-one-third-tablet{
          padding: 100px 145px 100px 0;
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-one-third-tablet{
            left: -35px;
            padding: 100px 145px 100px 0;
          }
        }
      }
    }
  }

  //*********Block*Three*Columns********//
  .block-three-columns{
    //padding: 100px 0 230px 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          width: 27.7888% !important;
          margin-right: 8% !important;
          max-height: 470px;
        }
      }
    }
    &::before{
      left: 48%;
    }
  }

  //********Block*Numbers************//
  .block-numbers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          width: 22% !important;
          padding-top: 22%;
          margin-right: 50px !important;
          .advgb-column-inner{
            .block-numbers-title {
              font-size: 45px;
            }
          }
        }
      }
    }
  }

  //********Block*Map*Of*Centers*****//
  .block-map-of-centers{
    padding: 0;
  }

  //********Block*Metiers***********//
  .block-metiers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-half-tablet{
          width: 33.28% !important;
          padding-top: 33.28%;
        }
      }
    }
  }

  //*******Block*Video*With*Title*Content**//
  .block-video-with-title-content{
    .advgb-columns-container {
      .advgb-columns-row {
        .block-video-with-title-content-column-video{
          .advgb-column-inner{
            .home-block-video-content {
              .advgb-video-wrapper{
                .advgb-video-poster{
                  img{
                    margin-top: -57px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //*****Block*Actus*FB******************//
  .block-actus-FB{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .block-actus-FB-title{
              left: 635px;
            }
            .block-actus-FB-description{
              left: 635px;
            }
            .block-actu-fb-carousel{
              .swiper-container{
                .swiper-button-next{
                  left: 460px;
                }
                .swiper-button-prev{
                  left: 410px;
                }
                .blue-square{
                  left: 360px;
                }
              }
            }
          }
        }
      }
    }
  }

  //*****Block*Type*Article*To*Other*Page**//
  .block-type-article-to-other-page{
    padding: 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 170px !important;
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          padding: 150px 0;
          left: 10%;
          .advgb-column-inner{
            padding: 55px 95px 55px 190px;
          }
        }
      }
    }
  }

  //***********Tarteaucitron****************//
  #tarteaucitronRoot{
    #tarteaucitronAlertBig{
      #tarteaucitronDisclaimerAlert{
        max-width: $container-width;
      }
    }
  }

  //***********Blog*Page*******************//
  .listing-blog{
    .filter-posts{
      justify-content: center;
      background-color: #183455;
      padding: 30px 20px;
      margin-top: -5px;
      .searchandfilter{
        width: 100%;
        div{
          ul{
            li{
              h4{
                display: inline-block;
                padding-left: 40px;
                font-family: $openSans-regular;
                font-size: 16px;
                line-height: 30px;
                color: #ffffff;
              }
              #ofcategory{
                width: 184px;
                height: 49px;
                margin: 0 32px 0 51px;
                padding: 15px 20px;
                background-color: #17385e;
                color: #fff;
                border: solid 1px #ffffff;
              }
              input[name="ofsearch"]{
                width: 445px;
                height: 49px;
                margin: 0 24px 0 32px;
              }
              input[type="submit"]{
                width: 211px;
                height: 49px;
                margin: 0 0 0 24px;
                padding: 15px 50px;
                background-color: #6ab2e3;
                font-family: $openSans-regular;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 2px;
                text-align: center;
                color: #ffffff;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
    .cart-wrapper{
      margin: 50px 0;
      justify-content: center;
      .cart-container{
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 0;
        flex-wrap: wrap;
        .cart{
          display: inline-block;
          vertical-align: top;
          width: 100%;
          padding: 0;
          margin: 0 0 30px 0;
          text-align: left;
          font-size: 0;
          position: relative;
          .one-half-part{
            figure{
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 30%;
              margin: 0;
              img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                font-family: "object-fit: cover;";
                padding: 30px 0;
              }
            }
          }
          .two-half-part{
            background-color: #fff;
            box-shadow: 0 32px 54px 0 rgba(0,0,0,.11);
            width: 90%;
            margin-left: auto;
            padding: 30px 50px 35px 300px;
            .text-container{
              padding: 0;
              font-size: 15px;
              .info-post{
                .time{
                  width: fit-content;
                  display: inline-block;
                  font-family: $openSans-light;
                  letter-spacing: 2px;
                  color: #17385e;
                  margin-right: 20px;
                }
                .category{
                  width: fit-content;
                  display: inline-block;
                  font-family: $openSans-semi-bold;
                  font-size: 13px;
                  color: #ffffff;
                  padding: 6px 12px;
                  border-radius: 17.5px;
                }
              }
              .title{
                font-family: $openSans-bold;
                font-size: 30px;
                line-height: 42px;
                letter-spacing: normal;
                color: #17385e;
                margin: 10px 0 20px 0;
              }
              .desc{
                margin: 20px 0 30px 0;
                font-family: $openSans-regular;
                font-size: 16px;
                line-height: 30px;
                letter-spacing: normal;
                color: #333333;
              }
              .more{
                font-family: $openSans-bold;
                font-size: 14px;
                letter-spacing: 2px;
                text-align: center;
                color: #17385e;
                border-bottom: solid 2px #6ab2e3;
                margin-right: 40px;
                padding: 0 12px 15px;
              }
              .download{
                position: relative;
                font-family: $openSans-bold;
                font-size: 18px;
                line-height: 24px;
                color: #17385e;
                margin-left:30px ;
                &::before{
                  content: ' ';
                  display: block;
                  top: 0;
                  bottom: 0;
                  left: -50px;
                }
              }
            }
          }


        }
      }
    }
  }

  //**********Single*Post*Page**************//
  .single-post{
    .thumbnail{
      top: 0;
      left: 50%;
      width: 100vw;
      transform: translate(-50%, 0) scale(1);
      max-height: 450px;
      img{
        width: 100vw;
        max-height: 450px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .post-container{
      padding: 80px 95px 60px 95px;
      margin-top: -195px;
      .title{
        font-size: 40px;
        line-height: 55px;
      }
    }
    .site__navigation{
      margin: 150px 0;
      .site__navigation__prev,
      .site__navigation__next{
        display: flex;
        margin-top: 0;
        max-width: 48%;
        margin-right: 50px;
        .thumbnail{
          display: block;
          margin-right: 30px;
        }
        .text-container{
          max-width: 47%;
          flex-basis: 50%;
        }
      }
    }
  }

  //**********Block*JobOffer*Carrousel******//
  .block-job-offers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .block-job-offers-carousel{
              .swiper-container{
                .swiper-button-next{
                  right: auto;
                  left: 1120px;
                }
                .swiper-button-prev{
                  left: 1060px;
                }
                .blue-square{
                  top: -555px;
                  left: 780px;
                }
              }
            }
          }
        }
      }
    }
    &::before{
      left: 42%;
    }
  }

  //**********Block*Three*Columns*Actu*******//
  .block-three-columns-actu{
    .actu-three-columns-block{
      .actu-three-columns-block-container{
        .actu-three-columns-block-row{
          .actu-three-columns-block-is-one-third{
            width: 31.72%;
          }
        }
      }
    }
  }

  //*********Block*Slider*Home*Intro********//
  .slider-intro-block{
    .swiper-container-intro-block{
      .swiper-wrapper{
        .slider-intro-block-slide{
          .slide-row{
            .slider-intro-block-inner{
              .slider-intro-block-container{
                .slider-intro-block-row{
                  .slider-intro-block-column-one{
                    width: 40%;
                    padding: 65px 125px 65px 0;
                  }
                  .slider-intro-block-column-two{
                    width: 67%;
                    transform: translate(0, 0);
                    .slider-intro-block-column-two-inner{
                      .slider-intro-block-column-two-image{
                        overflow: hidden;
                        .slider-intro-block-img{
                          transform: translate(0, 0);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .swiper-button-next{
        left: 425px;
      }
      .swiper-button-prev{
        left: 365px;
      }
    }
  }

  //************Block*summary*semantic*link******************//
  .summary_semantic_link_block{
    .summary_semantic_link_block-container{
      .summary_semantic_link_block-columns-row{
        padding: 50px 65px 49.5px 50px;
      }
    }
  }

  //**********Block*Three*Columns*Former*******//
  .block-three-columns-former{
    .former-three-columns-block{
      .former-three-columns-block-container{
        .former-three-columns-block-row{
          .former-three-columns-block-is-one-third{
            width: 31.72%;
          }
        }
      }
    }
  }

  //***********Single*Training******************//
  .single-training {
    .row-info {
      .column-content {
        .inner-column-content {
          padding: 150px 500px 150px 350px;
        }
      }
    }
  }

}


@media screen  and (min-width: 1650px) {

  //*********Block*Three*Columns********//
  .block-three-columns{
    &::before{
      left: 67%;
    }
  }
  //**********Block*JobOffer*Carrousel******//
  .block-job-offers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .block-job-offers-carousel{
              .swiper-container{
                .swiper-button-next{
                  right: auto;
                  left: 1120px;
                }
                .swiper-button-prev{
                  left: 1060px;
                }
                .blue-square{
                  top: -555px;
                  left: 780px;
                }
              }
            }
          }
        }
      }
    }
    &::before{
      left: 42%;
    }
  }
  //**********Block*JobOffer*Carrousel******//
  .block-job-offers{
    &::before{
      left: 55%;
    }
  }
}

