.block-actus-FB{
  padding: 200px 0 0 0;
  position: relative;
  z-index: 50 !important;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          height: 100%;
          margin-bottom: 200px;
          .block-actus-FB-title{
            font-family: $openSans-bold;
            font-size: 40px;
            line-height: 1.38;
            color: #ffffff;
            position: relative;
            top: -100px;
            left: 635px;
            max-width: 540px;
            z-index: 88 !important;
          }
          .block-actus-FB-description{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 1.88;
            color: #ffffff;
            position: relative;
            top: -65px;
            left: 635px;
            max-width: 540px;
            z-index: 88 !important;
          }
          .block-actu-fb-carousel{
            height: 375px;
            width: 100vw;
            padding-left: 95px;
            .swiper-container{
              max-width: 450px;
              margin: 0;
              overflow: visible;
              .swiper-wrapper{
                min-height: 292px;
                .block-actu-fb-carousel-slide{
                  .slide-row{
                    position: relative;
                    width: 100%;
                    box-shadow: 0 20px 40px 0 rgba(45, 53, 62, 0.22);
                    .actu-embed{

                    }
                  }
                  &.swiper-slide{
                    opacity: 0;
                  }
                  &.swiper-slide-active{
                    opacity: 1;
                  }
                  &.swiper-slide-active ~ .swiper-slide{
                    opacity: 1;
                  }
                }
              }
              .swiper-button-prev,
              .swiper-button-next{
                color: #ffffff;
                top: -14%;
              }
              .swiper-button-next:after,
              .swiper-button-prev:after{
                font-size: 16px;
              }
              .swiper-button-next{
                right: auto;
                left: 460px;
              }
              .swiper-button-prev{
                left: 410px;
              }
              .blue-square{
                position: relative;
                width: 230%;
                height: 125%;
                top: -665px;
                left: 360px;
                overflow: visible;
                box-shadow: 10px 10px 80px 0 rgba(139, 139, 139, 0.17);
                background-color: #17385e;
              }
            }
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: -150px;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: white;
    background-image: url("../../../images/motif-3.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: 75%;
    transform: translate(-50%, 0);
  }
}