.block-three-columns-actu{
  .block-three-columns-actu-title{
    margin-bottom: 50px;
    font-family: $openSans-bold;
    font-size: 40px;
    line-height: 55px;
    color: #17385e;
  }
  .actu-three-columns-block{
    position: relative;
    width: 100%;
    .actu-three-columns-block-container{
      .actu-three-columns-block-row{
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        .actu-three-columns-block-is-one-third{
          width: 31.72%;
          margin-right: 30px;
          max-height: 610px;
          flex: none;
          display: block;
          .actu-three-columns-block-inner{
            .actu-three-columns-block-inner-row{
              width: 100%;
              height: 100%;
              .actu-three-columns-block-img-column{
                position: relative;
                z-index: 5 !important;
                left: 25px;
                top: 0;
                right: 0;
                bottom: 0;
                width: 85.777%;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
                flex: none;
                display: block;
                .actu-three-columns-block-image-block{
                  img{
                    min-height: 182px;
                  }
                }
              }
              .actu-three-columns-block-content-column{
                width: 100%;
                position: relative;
                top: -100px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #fff;
                box-shadow: 0 20px 40px 0 rgb(7 26 30 / 33%);
                .actu-three-columns-block-content-column-inner{
                  padding: 130px 25px 35px;
                  .info-post{
                    .time{
                      width: fit-content;
                      display: inline-block;
                      font-family: $openSans-light;
                      letter-spacing: 2px;
                      color: #17385e;
                      margin-right: 20px;
                    }
                    .category{
                      width: fit-content;
                      display: inline-block;
                      font-family: $openSans-semi-bold;
                      font-size: 13px;
                      color: #ffffff;
                      padding: 6px 12px;
                      border-radius: 17.5px;
                      margin-right: 5px;
                    }
                  }
                  .title{
                    font-family: $openSans-bold;
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: normal;
                    color: #17385e;
                    margin: 15px 0 10px;
                  }
                  .desc{
                    margin: 0 0 20px;
                    font-family: $openSans-regular;
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: normal;
                    color: #333333;
                  }
                  .more{
                    font-family: $openSans-bold;
                    font-size: 14px;
                    letter-spacing: 2px;
                    text-align: center;
                    color: #17385e;
                    border-bottom: solid 2px #6ab2e3;
                    margin-right: 40px;
                    padding: 0 12px 15px;
                  }
                  .download{
                    position: relative;
                    display: block;
                    font-family: $openSans-bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #17385e;
                    margin-top:45px;
                    padding-left: 50px;
                    &::before{
                      content: ' ';
                      display: block;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      background-image: url("../../../images/icon-download.png");
                      background-size: cover;
                      width: 35px;
                      height: 29px;
                    }
                  }
                }
              }
            }
          }
          &:last-child{
            margin-right: 0 !important;
          }
        }
      }
    }
  }
  .block-three-columns-button{
    text-align: center;
    a{
      font-family: $openSans-bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      width: 330px;
    }
  }
}