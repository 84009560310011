.two-columns{

	margin-bottom: 80px;

	h2{
		margin-bottom: 15px;
	}

	p{
		line-height: 30px;
		text-align: justify;
	}

	.advgb-columns-container{
		.advgb-columns{
			.advgb-column.advgb-is-one-quarter-tablet{
				width: 22% !important;
			}
			.advgb-column.advgb-is-three-quarters-tablet{
				width: calc(80% - 15px) !important;
				margin-left: 15px;
				ul, ol {
					font-family: $openSans-regular;
					li {
						font-size: 16px;
						line-height: 24px;
						position: relative;
						padding: 5px 5px 5px 30px;

						&::before {
							content: '';
							height: 6px;
							width: 6px;
							background: #000000;
							border-radius: 50%;
							position: absolute;
							left: 0;
							top: 15px;
						}
					}
				}

				ol {

					li {
						padding: 5px 5px 5px 0;
						list-style-type: decimal;
						list-style-position: inside;

						&::before {
							content: none;
						}

						ol {

							li {
								margin: 5px 0 0 40px;
							}
						}
					}
				}
			}
			a{
				text-decoration: underline;
			}
		}
	}
}