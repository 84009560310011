.one-cta{
  margin: 45px 0;
  .advgb-columns{
    justify-content: center;
    .advgb-column{
      width: auto !important;
      .one-cta-button{
        a{
          font-family: $openSans-bold;
          letter-spacing: 2px;
          text-transform: uppercase;
          width: 100%;
          text-align: center;
        }
      }

    }
  }
}