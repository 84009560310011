.summary_semantic_link_block{
  .summary_semantic_link_block-container{
    .summary_semantic_link_block-columns-row{
      padding: 50px 65px 49.5px 50px;
      box-shadow: -10px 10px 80px 0 rgb(11 30 53 / 15%);
      background-color: #ffffff;
      .summary_semantic_link_block-title{
        .summary_semantic_link_block-title-inner{
          .title-tab{
            font-family: $openSans-bold;
            font-size: 30px;
            line-height: 1.4;
            color: #17385e;
            margin-bottom: 20px;
          }
        }
      }
      .summary_semantic_link_block-column{
        .summary_semantic_link_block-column-inner{
          .summary_semantic_link_block-column-content{
            .summary{
              .summary-title{
                a{
                  display: inline;
                  font-family: $openSans-semi-bold;
                  font-size: 18px;
                  line-height: 1.67;
                  color: #17385e;
                  border-bottom: solid 2px #d5ad61;
                }
                margin-bottom: 25px;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}