.double-cta{
	margin: 45px 0;
	.advgb-columns{
		justify-content: center;
		.advgb-column{
			.talk{
				a{
					border: solid 2px #a8acb2;
				}
			}
			&.advgb-is-half-tablet{
				width: auto !important;
				min-width: 364px;
			}
			a{
				font-family: $openSans-bold;
				letter-spacing: 2px;
				text-transform: uppercase;
				width: 100%;
				text-align: center;
			}
		}
	}
}