@media screen and (min-width: 576px) {
  $container-width: 576px;
  //************Header**************************//
  header{
    .head{
      .title-logo-header{
        max-width: 16%;
      }
    }
    .nav{
      .menu{
        max-width: $container-width;
        margin-top: 45px;
      }
    }
  }

  //**********Block*Video*Slider*************//
  .home-block-video-slider{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          .advgb-column-inner{
            .home-block-video-content{
              .advgb-video-wrapper{
                margin-top: -125px;
                #player{
                  max-height: unset;
                }
                .advgb-video-poster{
                  img{
                    margin-top: -10px;
                  }
                }
              }
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .home-block-video-content-list{
              ul{
                li{
                  &.clicked{
                    &:nth-child(n)::before{
                      left: -5.555%;
                    }
                  }
                }
                &::before{
                  left: -5.555%;
                }
              }
            }
          }
        }
      }
    }
  }

  //***********Single*Training******************//
  .single-training {
    .row-info {
      .column-img {
        width: calc( 100% - 45px);
        left: 23px;
      }
      .column-content {
        .inner-column-content {
          padding: 265px 30px 30px;
        }
      }
    }
  }
}