.block-type-tabs{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        margin-bottom: 100px !important;
        .advgb-column-inner{
          .block-type-tabs-inner{
            margin: 0 auto;
            width: auto;
            thead{
              tr{
                th{
                  padding: 15px 20px;
                  background-color: #173252;
                  font-family: $openSans-semi-bold;
                  font-size: 15px;
                  line-height: 1.33;
                  color: white;
                  text-align: initial;
                  max-width: 180px;
                }
              }
            }
            tbody{
              tr{
                td{
                  padding: 15px 20px;
                  font-family: $openSans-light;
                  font-size: 15px;
                  line-height: 1.33;
                  color: #000000;
                  text-align: initial;
                  border-bottom: 1px solid #a8acb2!important;
                  &:first-child{
                    border-left: 1px solid #a8acb2!important;
                  }
                  &:last-child{
                    border-right: 1px solid #a8acb2!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}