.home-block-faq-block{
  position: relative;
  z-index: 60 !important;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          .advgb-columns-wrapper{
            .advgb-columns-container{
              .advgb-columns-row{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                position: relative;
                z-index: 999 !important;
                margin-top: -10%;
                .advgb-is-one-fifth-tablet{
                  width: unset;
                  max-width: 22%;
                  flex: 0 1 100% !important;
                  padding-top: 22%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  align-content: center;
                  margin: 30px 45px !important;
                  position: relative;
                  overflow: hidden;
                  box-shadow: 40px 10px 80px 0 rgba(45, 53, 62, 0.18);
                  .advgb-column-inner{
                    display: flex;
                    background-color: #ffffff;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    padding: 55px 85px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    .advgb-image-block{
                      background-size: contain;
                      background-position: center !important;
                      height: 5% !important;
                      width: 5% !important;
                      margin: 16px;
                      padding: 26px;
                      background-color: transparent;
                    }
                    .home-block-faq-block-title{
                      font-family: $openSans-bold;
                      font-size: 16px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.25;
                      letter-spacing: normal;
                      text-align: center;
                      color: #17385e;
                      .size-50{
                        font-size: 50px;
                        line-height: 1;
                      }
                      .size-35{
                        font-size: 35px;
                        line-height: 0.97;
                      }
                    }
                    .home-block-faq-block-content{
                      font-family: $openSans-regular;
                      font-size: 15px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.47;
                      letter-spacing: normal;
                      text-align: center;
                      color: #17385e;
                      margin-top: 10px;
                    }
                  }
                }
              }
            }
          }
          .home-block-faq-block-row-button{
            .advgb-columns-container{
              .advgb-columns-row{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                position: relative;
                margin-top: 0%;
                .advgb-is-half-tablet{
                  width: unset;
                  max-width: 22%;
                  flex: 0 1 100% !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  align-content: center;
                  margin: 20px 45px !important;
                  position: relative;
                  overflow: hidden;
                  .advgb-column-inner{
                    width: 100%;
                    .home-block-faq-block-button-faq{
                      box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
                      background-color: #5CA2D5 ;
                      text-align: center;
                      .wp-block-advgb-button_link{
                        font-family: $openSans-regular;
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 2px;
                        text-align: center;
                        color: #ffffff;
                        border: none;
                        padding: 20px 0;
                        &:hover{
                          box-shadow: none;
                          transition: none;
                        }
                      }
                      &:hover{
                        background-color: #ffffff;
                        .wp-block-advgb-button_link{
                          color: #5CA2D5  !important;
                        }
                      }
                    }
                    .home-block-faq-block-button-discuss{
                      box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
                      background-color: #a8acb2;
                      border: 2px #a8acb2 solid;
                      text-align: center;
                      .wp-block-advgb-button_link{
                        font-family: $openSans-regular;
                        font-size: 14px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 2px;
                        text-align: center;
                        color: #ffffff;
                        border: none;
                        padding: 20px 0;
                        &:hover{
                          box-shadow: none;
                          transition: none;
                        }
                      }
                      &:hover{
                        background-color: #ffffff;
                        .wp-block-advgb-button_link{
                          color: #a8acb2 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .home-block-faq-block-row-parcour{
            .advgb-columns-container{
              .advgb-columns-row{
                margin-top: 0;
                .advgb-column{
                  .advgb-column-inner{
                    .home-block-faq-block-parcour-title{
                      font-family: $openSans-bold;
                      font-size: 40px;
                      font-weight: bold;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.38;
                      letter-spacing: normal;
                      color: #17385e;
                      margin-top: 100px;
                    }
                    .home-block-faq-block-parcour-content{
                      max-width: 45%;
                      font-family: $openSans-regular;
                      font-size: 16px;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: 1.88;
                      letter-spacing: normal;
                      color: #17385e;
                      margin-top: 28px;
                      margin-bottom: 200px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: #f1f1f1;
    transform: translate(-50%, 0);
  }
}