.home-block-blue-block-questions-frequency{
  position: relative;
  margin-top: -15%;
  z-index: 50 !important;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        padding: 380px 0 220px 0;
        .advgb-column-inner{
          .home-block-blue-block-questions-frequency-title{
            font-family: $openSans-bold;
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #ffffff;
          }
          .home-block-blue-block-questions-frequency-content{
            font-family: $openSans-regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #ffffff;
            margin-top: 30px;
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: #17385e;
    box-shadow: 0 32px 64px 0 rgba(192, 192, 192, 0.25);
    transform: translate(-50%, 0);
  }
}