.block-type-article-to-other-page{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      position: relative;
      justify-content: center;
      .advgb-is-one-third-tablet{
        position: absolute;
        z-index: 15 !important;
        width: 100% !important;
        max-width: 400px;
        padding: 0;
        top: 50%;
        bottom: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        .advgb-column-inner{
          width: 100%;
          .wp-block-advgb-image{
            background-size: contain;
            .advgb-image-overlay{
              display: none;
            }
          }
        }
      }
      .advgb-is-two-thirds-tablet{
        padding: 150px 0;
        position: relative;
        left: 10%;
        z-index: 10 !important;
        .advgb-column-inner{
          padding: 55px 95px 55px 190px;
          box-shadow: 0 20px 40px 0 rgba(11, 30, 53, 0.09);
          background-color: #ffffff;
          .block-type-article-to-other-page-title{
            font-family: $openSans-bold;
            font-size: 20px;
            line-height: 1.4;
            color: #17385e;
          }
          .block-type-article-to-other-page-content{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 1.88;
            color: #2d2d2d;
            margin: 20px 0;
          }
          .block-type-article-to-other-page-button{
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 18px;
              line-height: 1.33;
              text-align: center;
              color: #17385e;
              border: none;
              padding: 10px 0;
              width: fit-content;
              &:hover{
                opacity: 1;
                box-shadow: none;
                border-bottom: none;
                color: #17385e !important;
              }
            }
          }
        }
      }
    }
  }
}