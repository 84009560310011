.tab_semantic_link_block{
  padding-top: 100px;
  .tab_semantic_link_block-container{
    .tab_semantic_link_block-columns-row{
      .tab_semantic_link_block-title{
        .tab_semantic_link_block-title-inner{
          .title-tab{
            font-family: $openSans-bold;
            font-size: 30px;
            line-height: 1.4;
            color: #17385e;
            text-align: center;
          }
        }
      }
      .tab_semantic_link_block-column{
        position: relative;
        &::before{
          content: ' ';
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          bottom: 0;
          right: 0;
          height: 50%;
          width: 100vw;
          z-index: -1 !important;
          background-color: #f1f1f1;
          transform: translate(-50%, 0);
        }
        .tab_semantic_link_block-column-inner{
          display: flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 50px;
          .tab_semantic_link_block-column-content{
            display: flex;
            flex: 0 1 33.33%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding: 40px 30px;
            box-shadow: 40px 10px 80px 0 rgba(45, 53, 62, 0.18);
            background-color: #ffffff;
            margin-bottom: 100px;
            min-height: 320px;
            margin-right: 30px;
            &:last-child{
              margin-right: 0;
            }
            .header-tab{
              font-family: $openSans-bold;
              font-size: 16px;
              line-height: 1.5;
              text-align: center;
              color: #17385e;
              padding: 0 65px 40px;
              position: relative;
              &:after{
                content: " ";
                position: absolute;
                height: 1px;
                width: 100%;
                background: #6ab2e3;
                bottom: 20px;
                left: 0;
              }
            }
            .content-tab{
              padding: 0 65px 20px;
              .lien-obfusque{
                font-family: $openSans-bold;
                font-size: 16px;
                line-height: 1.38;
                text-align: center;
                color: #17385e;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}