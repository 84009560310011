.trombinoscope-surgeon{
  .listing-surgeon{
    .filter-posts{
      width: fit-content;
      justify-content: center;
      background-color: #183455;
      padding: 30px 20px;
      margin: 35px auto 0;

      .searchandfilter{
        width: 100%;
        div{
          ul{
            li{
              h4{
                display: inline-block;
                padding-left: 0;
                font-family: $openSans-regular;
                font-size: 16px;
                line-height: 30px;
                color: #ffffff;
              }
              .ofcategory{
                width: 230px;
                height: 49px;
                margin: 0 30px 0 0;
                padding: 15px 20px;
                background-color: #17385e;
                color: #fff;
                border: solid 1px #ffffff;
                &:not(:last-child){
                  margin: 0 30px 0 50px;
                }
              }
              input[type="submit"]{
                width: 211px;
                height: 49px;
                margin: 0 0 0 24px;
                padding: 15px 50px;
                background-color: #6ab2e3;
                font-family: $openSans-regular;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 2px;
                text-align: center;
                color: #ffffff;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
    .cart-wrapper{
      margin: 50px 0;
      justify-content: center;
      .cart-container{
        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: 0;
        flex-wrap: wrap;
        .cart-surgeon{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: center;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 30px;
          margin: 0 0 30px 0;
          text-align: left;
          background-color: #fff;
          box-shadow: 0 32px 54px 0 rgba(0,0,0,.11);
          .column-one {
            width: 15%;
            .thumbnail-surgeon{
              border-radius: 100%;
              overflow: hidden;
              width: fit-content;
              img{
                width: 100%;
                max-width: 140px;
              }
            }
          }
          .column-two {
            width: 50%;
            border-right: 1px solid #6ab2e3;
            padding-right: 50px;
            .text-container{
              padding: 0;
              font-size: 15px;
              .surgeon-name{
                color: #17385E;
                font-size: 30px;
                line-height: 42px;
              }
              .surgeon-rpps {
                margin-bottom: 15px;
                font-size: 15px;
                line-height: 32px;
                color: #707070;
              }
              .centers {
                margin-bottom: 35px;
                .center-surgeon {
                  display: inline-block;
                  position: relative;
                  background-color: #6AB2E3;
                  width: fit-content;
                  border-radius: 50px;
                  padding: 6px 0 6px 12px;
                  overflow-wrap: normal;
                  word-break: keep-all;
                  word-wrap: normal;
                  a{
                    font-size: 13px;
                    color: white;
                    line-height: 18px;
                    padding-right: 35px;
                  }
                  &:not(:last-child) {
                    margin-right: 10px;
                  }
                  &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 11px;
                    right: 11px;
                    background-image: url("../../../images/external-link.png");
                    background-size: cover;
                    width: 12px;
                    height: 12px;
                  }
                }
              }
            }
          }
          .column-three {
            width: 35%;
            padding-left: 60px;
            .text-container {
              padding: 0;
              font-size: 16px;
              .diplomes-surgeon {
                ul,li{
                  list-style-type: disc;
                }
                ul{
                  li{
                    font-family: $openSans-regular;
                    font-size: 16px;
                    line-height: 26px;
                    color: #17385E;
                    &:not(:last-child) {
                      margin-bottom: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .pagination{
        text-align: center;
        .page-numbers {
          font-size: 18px;
          line-height: 35px;
          color: #A8ACB2;
          margin: 0 40px;
          &.current,
          &.prev,
          &.next {
            color: #17385E;
            font-weight: bold;
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}