.title-text{
	margin-bottom: 80px;
	.advgb-columns-container{
		max-width: 1440px;
		margin: 0 auto;
		// padding: 0 90px;
		h1{
			padding: 15px 0;
		}
		p{
			line-height: 30px;
			text-align: justify;
		}
		ul, ol {
			font-family: $openSans-regular;
			li {
				font-size: 16px;
				line-height: 24px;
				position: relative;
				padding: 5px 5px 5px 30px;

				&::before {
					content: '';
					height: 6px;
					width: 6px;
					background: #000000;
					border-radius: 50%;
					position: absolute;
					left: 0;
					top: 15px;
				}
			}
		}

		ol {

			li {
				padding: 5px 5px 5px 0;
				list-style-type: decimal;
				list-style-position: inside;

				&::before {
					content: none;
				}

				ol {

					li {
						margin: 5px 0 0 40px;
					}
				}
			}
		}
	}
}