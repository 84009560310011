.block-list {
  margin: 0 0 100px 0;
  font-family: $openSans-regular;

  ul, ol {

    li {
      font-size: 16px;
      line-height: 24px;
      position: relative;
      padding: 5px 5px 5px 30px;

      &::before {
        content: '';
        height: 6px;
        width: 6px;
        background: #000000;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 15px;
      }
    }
  }

  ol {

    li {
      padding: 5px 5px 5px 0;
      list-style-type: decimal;
      list-style-position: inside;

      &::before {
        content: none;
      }

      ol {

        li {
          margin: 5px 0 0 40px;
        }
      }
    }
  }
}