h1,h2,p,a{
	color: #0f0f11;
}

h1{
	font-family: $openSans-bold;
	font-size: 50px;
}

h2{
	font-family: $openSans-bold;
	font-size: 40px;
}

h3{
	font-family: $openSans-bold;
	font-size: 30px;
}

h4{
	font-family: $openSans-bold;
	font-size: 20px;
}

p{
	font-family: $openSans-regular;
	font-size: 16px;
}

a{
	font-family: $openSans-regular;
	font-size: 16px;
	line-height: 2;
	letter-spacing: normal;
	font-weight : Bold;
	color : #17385E;
	text-decoration: none;
}

input, select, textarea, span{
	font-family: $openSans-regular;
}

.container{
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 90px;
	/** for compatibility with safari **/
	height: max-content;
	height: -webkit-max-content;
}

@media screen and (max-width: 720px) {
	.container{
		padding: 30px;
	}
}

.swiper-container,
.swiper-container-intro-block{
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
#breadcrumbs{
	max-width: 1440px;
	margin: 16px auto;
	font-family: $openSans-regular;
	color: #17385e;
	.breadcrumb_last{
		font-family: $openSans-bold
	}
}
#primary{
	position: relative;
	z-index: 50;
}

.wp-block-advgb-columns.advgb-columns-wrapper .advgb-columns-container .advgb-columns .advgb-column {
	flex: none !important;
}

.advgb-columns-1>.advgb-column {
	width: 100% !important;
}

.advgb-column.advgb-is-half,
.advgb-column.advgb-is-half-tablet {
	width: 50% !important;
}

.advgb-column.advgb-is-two-thirds,
.advgb-column.advgb-is-two-thirds-tablet {
	width: 66.6666% !important;
}

.advgb-column.advgb-is-one-third,
.advgb-column.advgb-is-one-third-tablet {
	width: 33.3333% !important;
}