.block-map-of-centers{
  padding: 0;
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      .block-map-of-centers-column-map{
        width: 40% !important;
        margin-right: 125px;
        .advgb-column-inner{
          .block-map-of-centers-title-mobile{
            font-family: $openSans-bold;
            font-size: 40px;
            line-height: 1.38;
            color: #ffffff;
            display: none;
          }
          .block-map-of-centers-map{

          }
        }
      }
      .block-map-of-centers-column-content{
        width: 60% !important;
        .advgb-column-inner{
          .block-map-of-centers-title-desktop{
            font-family: $openSans-bold;
            font-size: 40px;
            line-height: 1.38;
            color: #ffffff;
          }
          .block-map-of-centers-content{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 1.88;
            color: #ffffff;
            margin: 30px 0;
          }
          .block-map-of-centers-button-center{
            margin-bottom: 20px;
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              padding: 25px 50px;
              min-width: 316px;
              &:hover{
                box-shadow: none;
                color: #6ab2e3 !important;
                background-color: #ffffff  !important;
              }
            }
          }
          .block-map-of-centers-button-job-offer{
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              background-color: transparent !important;
              padding: 25px 50px;
              min-width: 316px;
              border: solid 2px #6ab2e3;
              &:hover{
                box-shadow: none;
                color: #17385e !important;
                background-color: #ffffff !important;
                border: solid 2px #17385e;
              }
            }
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: #17385e;
    transform: translate(-50%, 0);
  }
}