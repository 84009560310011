@media screen and (min-width: 320px) {
  $container-width: 320px;
  .d-none{
    display: none;
  }
  .container{
    max-width: 100%;
    padding: 0 30px;
  }

  a,p,h1,h2,h4,h5,h6{
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  h1{
    font-size: 40px;
  }

  h2{
    font-size: 35px;
  }

  h3{
    font-size: 25px;
  }

  //************Header**************************//
  header{
    .flex-container.row {
      padding: 20px 0 0;
    }
    .head{
      .container{
        display: block;
        padding: 0;
        >div{
          margin-right: 0;
        }
      }
      #site-header {
        display: block;
        float: left;
        padding: 0 0 10px 10px;
        margin-right: 15px;
        max-width: 95px;
      }
      .title-logo-header{
        margin-right: 0 !important;
        display: block;
        max-width: 30%;
        float: left;
        .header-title{
          font-size: 13px;
        }
        &.title-logo-header-semantic-template{
          max-width: 60%;
        }
      }
      .menu-button-mobile{
        display: block;
        position: absolute;
        top: 30px;
        right: 15px;
        font-family: $openSans-bold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2px;
        text-align: center;
        color: #17385e;
        border-bottom: 3px #41ccd1 solid;
      }
      .row-contact-header{
        background-color: #093253;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px 30px;
        min-width: 100%;
        >div{
          margin-right: 0;
        }
        .contact-header{
          display: inline-flex;
          width: 100%;
          background-color: #093253;
          flex-flow: wrap;
          justify-content: center;
          margin-bottom: 15px;
          margin-right: 0;
          #label-contact-header{
            font-size: 15px;
            letter-spacing: -0.23px;
            color: #ffffff;
          }
          #num-contact-header{
            font-size: 15px;
            color: #ffffff;
          }
          .type-call-contact-header{
            font-size: 15px;
            letter-spacing: -0.23px;
            color: #ffffff;
          }
        }
        .center-button-header{
          display: inline-flex;
          width: 40.33333%;
          min-height: 49px;
          padding: 0 25px;
          margin-right: 30px;
          justify-content: center;
          #inner-center-button-header{
            font-size: 14px;
          }
        }
        .question-button-header{
          display: inline-flex;
          width: 40.33333%;
          justify-content: center;
          min-height: 49px;
          padding: 0 25px;
          #inner-question-button-header{
            font-size: 14px;
          }
        }
      }

      /**Header*Corpo**/
      .container-header-corpo{
         display: block;
         justify-content: normal;
         >div{
           margin-right: 0;
         }
        .menu-button-mobile{
          border-bottom: 3px #6ab2e3 solid;
        }
         .row-contact-header{
           background-color: #093253;
           display: inline-flex;
           flex-direction: column-reverse;
           flex-wrap: wrap;
           justify-content: center;
           padding: 20px 30px;
           min-width: 100%;
           >div{
             margin-right: 0;
           }
           .rdv-button-header{
             display: inline-flex;
             min-width: 100%;
             justify-content: center;
             #inner-rdv-button-header{
               color: #ffffff;
             }
           }
           .meet-button-header{
             display: inline-flex;
             min-width: 100%;
             justify-content: center;
             margin-bottom: 15px;
           }
         }
       }
    }
    .back-megamenu-mobile,
    .link-megamenu-mobile{
      display: none;
    }
    .show{
      display: block;
      font-family: $openSans-regular;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: 96px;
      left: 50px;
      padding-left: 10px;
      color: #ffffff;
      z-index: 888;
      &:before{
        content: " ";
        top: 25%;
        left: -20px;
        width: 10px;
        height: 16px;
        position: absolute;
        background-image: url("../initialize/img/fleche-gauche-bleu.png");
        background-repeat: no-repeat;
      }
    }
    .show-link{
      display: block;
      font-family: $openSans-regular;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: 85px;
      left: 80px;
      padding-left: 10px;
      color: #ffffff;
      z-index: 888;
    }
    .nav{
      padding: 10px 0 40px;
      margin-top: -150px;
      z-index: 777;
      position: relative;
      .menu{
        max-width: $container-width;
        display: block;
        margin-top: 45px;
      }
      ul {
        li {
          display: block;
          margin: 0;
          a{
            font-size: 18px;
            padding: 4px 0;
            line-height: 55px;
            font-family: $openSans-light;
          }
        }
      }
      &.style-corpo{
        margin-top: -150px;
      }
    }
    .has-mega-menu{
      position:static;
      &.menu-item-has-children:after{
        content: "";
        display: block;
        top: -35px;
        left: 95%;
        width: 10px;
        height: 16px;
        position: relative;
        background-image: url("../../images/fleche-droite-bleu.png");
        background-repeat: no-repeat;
      }
    }
    li.has-mega-menu{
      .sub-menu {
        display: none;
      }
      &.clicked{
        .sub-menu{
          display: block;
          position: absolute;
          z-index: 999;
          border-top: solid 0.5px #a8acb2;
          background-image: linear-gradient(to bottom, #173150, #17385e);
          width: 100%;
          max-width: none;
          height: 85%;
          top: 7%;
          left: 50%;
          text-align: left;
          transform: translateX(-50%);
          .menu-item{
            margin: 0;
            a{
              max-width: fit-content;
              line-height: 35px;
              font-family: $openSans-light;
            }
          }
          .mega-sub-column{
            width: 100%;
            display: block;
            margin: 0 30px;
            height: fit-content;
            a{
              text-align: inherit;
              max-width: inherit;
            }
            .sub-menu {
              display: none;
            }
            &.clicked{
              border-bottom: solid 2px #6ab2e3;
              .sub-menu{
                display: block;
                position: absolute;
                z-index: 999;
                border-top: 0;
                background-image: linear-gradient(to bottom, #173150, #17385e);
                width: 100%;
                max-width: none;
                height: 80%;
                top: 0;
                left: 50%;
                text-align: left;
                padding: 0 30px;
                transform: translateX(-50%);
                .mega-sub-sub-column{
                  .sub-menu{
                    display: none;
                  }
                  &.clicked{
                    border-bottom: solid 2px #6ab2e3;
                    .sub-menu{
                      display: block;
                      position: absolute;
                      z-index: 999;
                      background-image: linear-gradient(to bottom, #173150, #17385e);
                      width: 100%;
                      max-width: none;
                      height: 80%;
                      top: 0;
                      left: 50%;
                      text-align: left;
                      transform: translateX(-50%);
                    }
                  }
                  &.menu-item-has-children:after{
                    content: " ";
                    display: block;
                    top: -25px;
                    left: 97%;
                    width: 10px;
                    height: 16px;
                    position: relative;
                    background-image: url("../../images/fleche-droite-bleu.png");
                    background-repeat: no-repeat;
                  }
                }
              }
            }
            &.menu-item-has-children:after{
              content: " ";
              display: block;
              top: -25px;
              left: 82%;
              width: 10px;
              height: 16px;
              position: relative;
              background-image: url("../../images/fleche-droite-bleu.png");
              background-repeat: no-repeat;
            }
          }
        }
        &:nth-child(2){
              margin-bottom: 35px;
        }
      }
    }
    .only-mobile{
      display: flex !important;
    }
  }

  //*************Banner*************************//
  .banner{
    .banner-container-outer{
      .banner-container{
        width: 100%;
        padding: 80px 30px 10px;
        .banner-content{
          .banner-icon{
            left: 50%;
            top: -65px;
            transform: translate(calc(-50%), 0px);
          }
          .banner-text{
            p{
              margin-bottom: 20px;
            }
          }
        }
        .banner-text{
          p{
            margin-bottom: 20px;
          }
        }
      }
      .banner-close{
        top: 15px;
        right: 30px;
      }
    }
  }

  //************Footer**************************//
  footer{
    .pre-footer{
      display: block;
      .faq,.talk{
        padding-left: 0;
        padding-right: 0;
      }
      .faq{

        margin: 25px;
      }
      .talk{
        margin: 25px;
      }
    }
    .menu_footer {
      .footer-flex {
        flex-wrap: wrap;
        margin: 0 0 30px 0;
        &.footer-formation {
          .menu-footer-menu-container {
            width: 100%;
            margin-bottom: 16px;
          }
        }
      }
      .opv-formation-address {
        width: 100%;
        margin-bottom: 16px;
      }
      .menu{
        width: 100%;
        margin-bottom: 16px;
        .menu-item{
          flex: 1 1 50%;
        }
      }
      .languages, .iso, .menu, .networks{
        margin: 0;
      }

      .languages, .iso{
        width: 50%;
      }

      .languages{
        ul{
          li{
            margin-bottom: 10px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      .networks{
        width: 100%;
        margin-bottom: 56px;

        p{
          text-align: center;
        }

        .logos{
          justify-content: center;
          a{
            &:last-child{
              padding-right: 0;
            }
          }
        }
      }
      .iso{
        width: 50%;
        display: flex;
        justify-content: flex-end;

        figure{
          align-self: center;
          img{
            max-width: 70px;
            max-height: 70px;
            min-width: 0;
          }
        }
      }
    }
  }

  //***********Block**Testimony****************//
  .home-block-testimony{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          width: 100% !important;
          padding: 30px 0;
          .advgb-column-inner{
            .testimony-block-title{
              font-size: 40px;
            }
          }
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .testimony-block-image{
              background-position: 15% 0 !important;
              height: 340px !important;
              background-size: 205%;
              width: 100vw !important;
              position: relative;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-two-thirds-tablet{
            .advgb-column-inner{
              .testimony-block-image{
                background-position: 15% 0 !important;
                height: 340px !important;
                background-size: 205%;
                width: 100vw !important;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
              }
            }
          }
        }
      }
    }
  }

  //***********Block*Intro*Surgery************//
  .home-block-intro-surgery{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-three-quarters-tablet{
          padding: 30px 0 20px;
          .advgb-column-inner{
            .home-block-intro-surgery-title{
              font-size: 40px;
              line-height: 1.38;
            }
          }
        }
        .advgb-is-one-quarter-tablet{
          padding: 20px 0 150px;
        }
      }
    }
  }

  //**********Block*Video*Slider*************//
  .home-block-video-slider{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          position: inherit;
          margin-bottom: 0 !important;
          padding: 30px;
          .advgb-column-inner{
            .home-block-video-content{
              .advgb-video-wrapper{
                margin-top: -125px;
                #player{
                  max-height: 140px;
                }
                .advgb-video-poster{
                  img{
                    margin-top: -25px;
                  }
                }
                .advgb-button-wrapper{
                  height: 100%;
                  width: 100%;
                  z-index: 3;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                }
              }
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          position: inherit;
          padding: 30px 31px 30px 55px;
          min-height: 350px;
          .advgb-column-inner{
            .home-block-video-content-list{
              ul{
                position: relative;
                li{
                  &.clicked{
                    &:nth-child(n)::before{
                      left: -8.555%;
                    }
                  }
                }
                &::before{
                  height: 100%;
                  top: 0;
                  left: -8.555%;
                }
              }
            }
          }
        }
      }
    }
  }

  //**********Block*Blue*Question*Frequency*//
  .home-block-blue-block-questions-frequency{
    margin-top: 0;
    min-height: 720px;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          padding: 40px 0;
        }
      }
    }
  }

  //**********Block*FAQ*********************//
  .home-block-faq-block{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: -60%;
                  .advgb-is-one-fifth-tablet{
                    max-width: 100%;
                    padding-top: 100%;
                    margin: 15px 25px !important;
                    .advgb-column-inner{
                      padding: 30px 40px;
                    }
                  }
                }
              }
            }
            .home-block-faq-block-row-button{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-is-half-tablet{
                    max-width: 100%;
                    margin: 20px 25px !important;
                  }
                }
              }
            }
            .home-block-faq-block-row-parcour{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-column{
                    .advgb-column-inner{
                      .home-block-faq-block-parcour-title{
                        margin-top: 30px;
                      }
                      .home-block-faq-block-parcour-content{
                        max-width: 100%;
                        margin-top: 28px;
                        margin-bottom: 310px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //**********Block*Frieze*****************//
  .home-block-frieze{
    margin-top: -260px;
    .advgb-columns-container{
      .advgb-columns-row{
        max-width: 317px;
        .home-block-frieze-columns{
          max-height: 288px !important;
          .advgb-column-inner{
            padding: 55px 40px 55px 40px;
          }
          &::after{
            width: 100vw;
            height: 100%;
            left: -15%;
            top: -15%;
            background-image: url("../../images/fleche-frieze-mobile.png");
          }
          &:last-child::after{
            width: 100vw;
            height: 100%;
            left: -15%;
            top: -10%;
            background-image: url("../../images/fleche-frieze-mobile.png");
          }
        }
        &::before{
          display: none;
        }
      }
    }
  }

  //************Form***********************//
  .form{
    padding: 90px 0 90px;

    > .input{
      &.one-col{
        &.half{
          input{
            width: 100%
          }
        }
      }
      &.two-col{
        width: 100%;
      }
      &.radio{

        .wpcf7-radio{
          flex-wrap: wrap;

          .wpcf7-list-item{
            width: 100%;
            margin-bottom: 15px;
          }
        }
      }
    }
    .submit-container {
      flex-wrap: wrap;

      .wpcf7-list-item{
        margin-bottom: 15px;
      }

      .submit{
        width: 100%;
      }
    }
  }

  //************Block*iso-9001*************//
  .iso-9001{
    .advgb-columns-container{
      .advgb-column-inner{
        img{
          margin: 0 auto;
        }
      }

      .wp-block-advgb-column{
        padding: 0 45px;
        p{
          padding: 0;
        }
      }
    }
    .advgb-columns{ 
      .wp-block-advgb-column{ 
        .advgb-column-inner{ 
          p{
            padding: 0 0 50px 0;
          }
        }
      }
    }
  }

  //**********Video*Carousel***************//
  .home-block-video-carousel{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            margin-bottom: 0;
            .home-block-video-carousel-description{
              max-width: 100%;
            }
            .home-block-video-carousel{
              height: 300px;
              padding-left: 0;
              .swiper-container{
                max-width: 310px;
                .home-block-video-carousel-slide{
                  .slide-row{
                    max-height: 192px;
                    .home-block-video-carousel-slide-title{
                      max-width: 380px;
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  top: 80%;
                }
                .swiper-button-next{
                  left: 300px;
                }
                .swiper-button-prev{
                  left: 260px;
                }
                .swiper-button-next:after,
                .swiper-button-prev:after{
                  font-size: 16px;
                }
                .white-square{
                  width: 122%;
                  height: 97%;
                  top: -90px;
                  left: -29px;
                }
              }
            }
          }
        }
      }
    }
  }

  //************Video*Carousel*Type*Corpo***//
  .home-block-video-carousel.type-corpo{
    &::before{
      background-image: none;
    }
  }

  //*********Block*Type*Pathology*********//
  .block-type-pathology{
    padding: 50px 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          height: 20%;
          max-width: 230px;
          top: 0;
          transform: translateY(0);
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 100% !important;
              background-color: transparent !important;
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          padding: 65px 0 30px;
          left: 0;
          .advgb-column-inner{
            padding: 85px 15px 25px 15px;
          }
        }
      }
    }
  }

  //*********Block*Type*Tabs**************//
  .block-type-tabs{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          margin-bottom: 100px !important;
          .advgb-column-inner{
            overflow-x: auto;
          }
        }
      }
    }
  }

  //*********Block*Type*Quote************//
  .block-type-quote{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          // padding-top: 119.33%;
          .advgb-column-inner{
            padding: 85px 30px 40px;
            .block-type-quote-content{
              &::before{
                top: 10%;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Intro*Corpo**********//
  .intro-corpo-block{
    .advgb-columns-container{
      .advgb-columns-row{
        flex-direction: column-reverse;
        .advgb-column.advgb-is-one-third-tablet{
          width: 100% !important;
          padding: 30px 0;
          .advgb-column-inner {
            .intro-corpo-block-title {
              font-size: 40px;
            }
          }
        }
        .advgb-column.advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            .intro-corpo-block-image{
              height: 255px !important;
              width: 100vw !important;
              position: relative;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          flex-direction: column-reverse;
          .advgb-column.advgb-is-one-third-tablet{
            padding: 30px 0;
            .advgb-column-inner {
              .intro-corpo-block-title {
                font-size: 40px;
              }
            }
          }
          .advgb-column.advgb-is-two-thirds-tablet{
            .advgb-column-inner{
              .intro-corpo-block-image{
                height: 255px !important;
                width: 100vw !important;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Three*Columns*******//
  .block-three-columns{
    padding: 50px 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          margin-right: 0;
          margin-bottom: 60px !important;
          max-height: 540px;
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-bottom: 0;
                  height: 80%;
                  .block-three-columns-img-column{
                    left: 15px;
                    width: 90% !important;
                  }
                  .block-three-columns-content-column{
                    top: -140px;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    &::before{
      background-image: none;
      background-position-x: unset;
    }
  }

  //********Block*Numbers************//
  .block-numbers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          padding-top: 100%;
          margin-bottom: 30px !important;
          .advgb-column-inner{
            padding: 0 25px;
          }
        }
      }
    }
  }

  //********Block*Map*Of*Centers*****//
  .block-map-of-centers{
    padding: 150px 0 0 0;
    margin-top: -65%;
    .advgb-columns-container{
      .advgb-columns-row{
        .block-map-of-centers-column-map{
          margin-right: 0;
          .advgb-column-inner{
            .block-map-of-centers-title-mobile{
              display: block;
              font-size: 35px;
              line-height: 1.14;
              margin: 50px 0;
            }
            .block-map-of-centers-map{

            }
          }
        }
        .block-map-of-centers-column-content{
          .advgb-column-inner{
            .block-map-of-centers-title-desktop{
              display: none;
            }
            .block-map-of-centers-button-center{
              .wp-block-advgb-button_link{
                padding: 25px 30px;
              }
            }
            .block-map-of-centers-button-job-offer{
              .wp-block-advgb-button_link{
                padding: 25px 30px;
              }
            }
          }
        }
      }
    }
  }

  //*******Block*Metiers*************//
  .block-metiers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-half-tablet{
          padding-top: 120.466%;
          margin-right: 0;
          .advgb-column-inner{
            .block-metiers-img{
              transform: scale(0.7777);
            }
            .block-metiers-title{
              font-size: 20px;
              line-height: 1.3;
              margin-top: 0;
            }
            .block-metiers-content{
              margin: 10px 0 20px 0;
              padding: 0 20px;
            }
            .block-metiers-tags{
              margin-right: 10px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    &:before{
      display: block;
    }
  }

  //******Block*Video*With*Title*Content**//
  .block-video-with-title-content{
    padding: 75px 0 120px;
    .advgb-columns-container {
      .advgb-columns-row {
        flex-direction: column-reverse;
        .block-video-with-title-content-column-video{
          margin-right: 0;
          height: 176px;
          .advgb-column-inner{
            .home-block-video-content {
              .advgb-video-wrapper{
                .advgb-video-poster{
                  img{
                    margin-top: -30px;
                  }
                }
              }
            }
          }
        }
        .block-video-with-title-content-column-content{
          .advgb-column-inner{
            margin-bottom: 30px;
            .block-video-with-title-content-title{
              font-size: 35px;
              line-height: 1.14;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  //*****Block*Actus*FB******************//
  .block-actus-FB{
    padding: 50px 0 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            margin-bottom: 525px;
            .block-actus-FB-title{
              top: -50px;
              left: -5px;
              max-width: 230px;
            }
            .block-actus-FB-description{
              display: none;
            }
            .block-actu-fb-carousel{
              padding-left: 0;
              .swiper-container{
                max-width: 315px;
                .swiper-wrapper{
                  min-height: 920px;
                  .block-actu-fb-carousel-slide{
                    .slide-row{
                      .actu-embed{
                        .fb_iframe_widget{
                          span{
                            width: 315px !important;
                            iframe{
                              width: 315px !important;
                            }
                          }
                        }
                        iframe{
                          width: 315px !important;
                        }
                      }
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  top: -22%;
                }
                .swiper-button-next{
                  left: 290px;
                }
                .swiper-button-prev{
                  left: 235px;
                }
                .blue-square{
                  width: 105vw;
                  height: 85%;
                  top: -1095px;
                  left: -40px;
                }
              }
            }
          }
        }
      }
    }
    &::before{
      background-image: none;
    }
  }

  //*****Block*Type*Article*To*Other*Page**//
  .block-type-article-to-other-page{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          max-width: 230px;
          height: 28%;
          top: 0;
          transform: translateY(0);
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 100% !important;
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          padding: 100px 30px;
          left: 0;
          .advgb-column-inner{
            padding: 85px 15px 25px 15px;
          }
        }
      }
    }
  }

  //*****Block*Type*Offers*************//
  .block-type-offers{
    padding: 50px 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .block-type-offers-column-button{
          .advgb-column-inner{
            .block-type-offers-button{
              .wp-block-advgb-button_link{
                padding: 15px 50px;
              }
            }
          }
        }
      }
    }
  }

  //***Center*template*//
  .container{
    .map-container{
      flex-wrap: wrap;
      margin-bottom: 0;
      padding-top: 0;
      &::before{
        content: none;
      }
      .filter{
        display: none;
        &.mobile{
          display: block;
        }
      }
      .map-display{
        display: block;
      }

      .right-column{
        width: 100%;
        margin-left: 0;
        height: auto;

        .items{
          height: 650px;
          .item{
            .mail{
              margin-bottom: 12px;
            }
            .typology{
              a{
                  margin-bottom: 15px;
              }
            }
          }
        }
      }
      .mapboxgl-map{
        width: 100%;
        display: none;
        margin-right: 0;
        height: 300px;
      }
    }
  }

  //***Offer*Template****************//
  .container{
    .offer {
      &.single{
        .title{
          h1{
            line-height: 1.1;
          }
        }
        .description{
          p{
            line-height: 1.88;
          }
        }
        .button{
          width: 100%;
        }
      }
    }
  }

  //*******Blog*page*****************//
  .sticky-post{
    .cart{
      display: block;
      .one-half-part{
        flex: 1 0 100%;
        margin-right: 0;
        figure{
          padding: 20px 0;
        }
      }
      .two-half-part{
        flex: 1 0 48%;
        .text-container{
          padding: 10px 0;
          .download{
            margin-left:30px;
            margin-top: 45px;
            display: block;
          }
        }
      }
    }
    &:before{
      height: 135%;
    }
  }
  .listing-blog{
    .filter-posts{
      margin-top: 40px;
      width: 100%;
      .searchandfilter{
        div{
          ul{
            display: block;
            li{
              display: block;
              padding-right: 0;
              h4{
                padding-left: 0;
              }
              #ofcategory{
                width: 100%;
                height: 50px;
                margin: 0;
                -webkit-appearance: listbox;
                -moz-appearance: listbox;
                appearance: listbox;
              }
              input[name="ofsearch"]{
                width: 100%;
                height: 50px;
                margin: 15px 0;
                padding: 15px 20px;
                -webkit-appearance: searchfield;
                -moz-appearance: searchfield;
                appearance: searchfield;
              }
              input[type="submit"]{
                width: 100%;
                height: 50px;
                margin: 0;
                padding: 15px 20px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
              }
            }
          }
        }
      }
    }
    .cart-wrapper{
      margin: 50px 0;
      justify-content: center;
      .cart-container{
        width: 100%;
        margin: 0;
        .cart{
          .one-half-part{
            figure{
              height: 180px;
              width: 100%;
              img{
                padding: 0 20px;
              }
            }
          }
          .two-half-part{
            width: 100%;
            margin-top: 100px;
            padding: 110px 20px 40px;
            .text-container{
              .download{
                margin-left: 50px;
                display: block;
                margin-top: 50px;
              }
            }
          }


        }
      }
      .pagination{
        .nav-links{
          text-align: center;
          .page-numbers{
            margin: 0 5px;
          }
        }
      }
    }
  }

  //**********Single*Post*Page**************//
  .single-post{
    .thumbnail{
      top: 0;
      left: 50%;
      width: 100vw;
      transform: translate(-50%, 0) scale(1);
      max-height: 450px;
      img{
        width: 100vw;
        max-height: 450px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
    .post-container{
      padding: 30px 20px 35px;
      margin-top: -80px;
      .title{
        font-size: 25px;
        line-height: 30px;
      }
    }
    .site__navigation{
      margin: 150px 0;
      .site__navigation__prev,
      .site__navigation__next{
        display: block;
        flex: none;
        max-width: 100%;
        margin-right: 0;
        margin-top: 15px;
        .thumbnail{
          display: none;
        }
        .text-container{
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }

  //**********Block*JobOffer*Carrousel******//
  .block-job-offers{
    padding: 30px 0 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            margin-bottom: 0;
            .block-job-offers-title{
              color: #fcfcfc;
            }
            .block-job-offers-carousel{
              .swiper-container{
                max-width: 315px;
                .swiper-wrapper{
                  min-height: 402px;
                  .block-job-offers-carousel-slide{
                    .slide-row{
                      .job-offer-embed{
                        .picto-job-offer{
                          position: relative;
                        }
                        .title{
                          min-height: 56px;
                          h1{
                            padding-left: 0;
                            padding-right: 0;
                            margin: 10px 0;
                          }
                        }
                        .center{
                          margin: 0 5px;
                        }
                      }
                      .formation-embed{
                        .title{
                          min-height: 56px;
                          h3{
                            padding-left: 0;
                            padding-right: 0;
                            margin: 10px 0;
                          }
                        }
                      }
                    }
                  }
                }
                .swiper-button-prev,
                .swiper-button-next{
                  color: #17385e;
                  top: 120%;
                }
                .swiper-button-next{
                  right: auto;
                  left: 90%;
                }
                .swiper-button-prev{
                  left: 0;
                }
                .blue-square{
                  height: 80%;
                  top: -600px;
                  left: -60px;

                }
              }
            }
            .block-job-offers-button{
              margin-top: 120px;
              .wp-block-advgb-button_link{
                min-width: 315px;
              }
            }
          }
        }
      }
    }
    &::before{
      display: none;
    }
  }

  //**********Block*Three*Columns*Actu*******//
  .block-three-columns-actu{
    .block-three-columns-actu-title{
      margin-bottom: 50px;
    }
    .actu-three-columns-block{
      .actu-three-columns-block-container{
        .actu-three-columns-block-row{
          .actu-three-columns-block-is-one-third{
            width: 100%;
            margin-right: 0;
            margin-bottom: 45px;
            max-height: 610px;
            .actu-three-columns-block-inner{
              .actu-three-columns-block-inner-row{
                .actu-three-columns-block-img-column{
                  width: 85.777%;
                  .actu-three-columns-block-image-block{
                    img{
                      min-height: 182px;
                    }
                  }
                }
                .actu-three-columns-block-content-column{
                  top: -100px;
                  .actu-three-columns-block-content-column-inner{
                    padding: 130px 25px 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .block-three-columns-button{
      a{
        width: 100%;
      }
    }
  }

  //*********Block*Slider*Home*Intro********//
  .slider-intro-block{
    .swiper-container-intro-block{
      .swiper-wrapper{
        min-height: 700px;
        .slider-intro-block-slide{
          .slide-row{
            width: 100%;
            .slider-intro-block-inner{
              width: 100%;
              .slider-intro-block-container{
                .slider-intro-block-row{
                  flex-direction: column-reverse;
                  flex-wrap: wrap;
                  .slider-intro-block-column-one{
                    width: 100%;
                    padding: 30px 0;
                    .slider-intro-block-column-one-inner{
                      .slider-intro-block-title{
                        font-size: 40px;
                        line-height: 45px;
                        margin-bottom: 20px;
                      }
                      .slider-intro-block-desc{
                        font-size: 16px;
                      }
                    }
                  }
                  .slider-intro-block-column-two{
                    width: 100vw;
                    transform: translate(-30px, 0);
                  }
                }
              }
            }
          }
        }
      }
      .swiper-pagination{
        .swiper-pagination-bullet{
          margin: 0 10px;
        }
      }
      .swiper-button-next{
        left: 280px;
      }
      .swiper-button-prev{
        left: 225px;
      }
      &::before{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100vw;
        z-index: -1;
        transform: translateX(-50%);
        background-color: #eef3f6;
      }
    }
  }

  /*************Block Koban Form ***********************/
  .kbnzone{
    .row{
      #ncform{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-content: center;
        .ncFieldct{
          flex-direction: column;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          .ncField{
            input[type="text"],
            input[type="email"]{
              width: 100%;
              max-width: unset;
            }
          }
          &.koba-form-33{
            width: 100%;
          }
          &.koba-form-100{
            textarea{
              width: 100%;
            }
          }
          &.tpboolean{
            flex-wrap: nowrap;
            .ncField{
              width: fit-content;
            }
            label{
              margin: 0 0 0 15px;
            }
          }
        }
        .ncSendZone{
          margin: 25px auto 0;
          width: 100%;
        }
      }
    }
  }

  //************Block*tab*semantic*link******************//
  .tab_semantic_link_block{
    padding-top: 50px;
    .tab_semantic_link_block-container{
      .tab_semantic_link_block-columns-row{
        .tab_semantic_link_block-column{
          &::before{
            top: 0;
            height: 100%;
          }
          .tab_semantic_link_block-column-inner{
            flex-direction: column;
            .tab_semantic_link_block-column-content{
              margin-bottom: 50px;
              margin-right: 0;
              flex: 0 1 100%;
              width: 100%;
              padding: 40px 0;
            }
          }
        }
      }
    }
  }

  //************Block*summary*semantic*link******************//
  .summary_semantic_link_block{
    .summary_semantic_link_block-container{
      .summary_semantic_link_block-columns-row{
        padding: 20px;
      }
    }
  }

  //***********Template*trombonoscope*surgeon*************//
  .trombinoscope-surgeon{
    .listing-surgeon{
      .filter-posts{
        justify-content: center;
        padding: 30px 20px;
        margin-top: 35px;
        .searchandfilter{
          div{
            ul{
              display: block;
              li{
                display: block;
                padding-right: 0;
                .ofcategory{
                  width: 100%;
                  height: 50px;
                  margin: 0 0 30px 0;
                  -webkit-appearance: listbox;
                  -moz-appearance: listbox;
                  appearance: listbox;
                  &:not(:last-child){
                    margin: 0 0 30px 0;
                  }
                }
                input[type="submit"]{
                  width: 100%;
                  height: 50px;
                  margin: 0;
                  padding: 15px 20px;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                }
              }
            }
          }
        }

      }
      .cart-wrapper{
        .cart-container{
          .cart-surgeon{
            flex-direction: column;
            padding: 15px;
            text-align: center;
            .column-one {
              width: 100%;
              .thumbnail-surgeon{
                margin: 0 auto;
              }
            }
            .column-two {
              width: 100%;
              border-right: 0 solid #6ab2e3;
              border-bottom: 1px solid #6ab2e3;
              padding-right: 0;
              margin-bottom: 30px;
              .text-container{
                .centers {
                  .center-surgeon {
                    margin-bottom: 10px;
                  }
                }
              }
            }
            .column-three {
              text-align: left;
              width: 100%;
              padding-left: 30px;
            }
          }
        }
        .pagination{
          .page-numbers {
            margin: 0 12px;
          }
        }
      }
    }
  }

  //***********Single*Training******************//
  .single-training {
    .row-header{
      padding-bottom: 170px;
      .row-flex {
        flex-direction: column;
        .row-content {
          flex: 0 1 100%;
          max-width: 100%;
        }
        .row-cta {
          flex: 0 1 100%;
          max-width: 100%;
          .cta-blue,
          .cta-border-blue {
            font-size: 12px;

          }
        }
      }
    }
    .row-info {
      margin-top: -30px;
      .column-img {
        width: calc( 100% - 30px);
        top: -100px;
        left: 15px;
        transform: none;
        .img-info {
          margin: 0 auto;
        }
      }
      .column-content {
        left: 0;
        .inner-column-content {
          padding: 165px 30px 30px;
        }
      }
    }
    .row-former {
      padding: 50px 0;
      .card-container{
        .card-former {
          flex-direction: column;
          padding: 15px;
          text-align: center;
          .column-one {
            width: 100%;
            .thumbnail-former {
              margin: 0 auto;
            }
          }
          .column-two {
            width: 100%;
            border-right: 0 solid #6ab2e3;
            border-bottom: 1px solid #6ab2e3;
            padding-right: 0;
            margin-bottom: 30px;
            .text-container{
              .centers {
                .center-surgeon {
                  margin-bottom: 10px;
                }
              }
            }
          }
          .column-three {
            text-align: left;
            width: 100%;
            padding-left: 30px;
          }
        }
      }
      &::before{
        display: none;
      }
    }
    .row-content {
      .inner-content {
        p {
          flex-direction: column;
        }
      }
    }
  }

  //***********Listing*Former*************//
  .listing-former{
    .inner-listing-former{
      .filter-posts{
        justify-content: center;
        padding: 30px 20px;
        margin-top: 35px;
        .searchandfilter{
          div{
            ul{
              display: block;
              li{
                display: block;
                padding-right: 0;
                .ofcategory{
                  width: 100%;
                  height: 50px;
                  margin: 0 0 30px 0;
                  -webkit-appearance: listbox;
                  -moz-appearance: listbox;
                  appearance: listbox;
                  &:not(:last-child){
                    margin: 0 0 30px 0;
                  }
                }
                input[type="submit"]{
                  width: 100%;
                  height: 50px;
                  margin: 0;
                  padding: 15px 20px;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                }
              }
            }
          }
        }

      }
      .cart-wrapper{
        .cart-container{
          .cart-former{
            flex-direction: column;
            padding: 15px;
            text-align: center;
            .column-one {
              width: 100%;
              .thumbnail-surgeon{
                margin: 0 auto;
              }
            }
            .column-two {
              width: 100%;
              border-right: 0 solid #6ab2e3;
              border-bottom: 1px solid #6ab2e3;
              padding-right: 0;
              margin-bottom: 30px;
              .text-container{
                .centers {
                  .center-surgeon {
                    margin-bottom: 10px;
                  }
                }
              }
            }
            .column-three {
              text-align: left;
              width: 100%;
              padding-left: 30px;
            }
          }
        }
        .pagination{
          .page-numbers {
            margin: 0 12px;
          }
        }
      }
    }
  }

  //***********Single*Fomer************//
  .inner-single-former {
    padding: 30px;
    .row-header{
      padding: 25px 0;
      flex-direction: column;
      align-items: center;
      .column-content {
        margin-left: 0;
        .link-all-training {
          padding: 20px;
          font-size: 11px;
        }
      }
    }
    .row-info {
      padding: 25px 0;
      .former-diplome {
        ul{
          li{
            flex: 0 1 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  //********* Catalog*Training********//
  .catalog-training{
    .catalog-container{
      padding: 10px;
      margin-top: -50px;
      .listing-training{
        .filter-posts{
          .searchandfilter{
            div{
              ul{
                display: block;
                li{
                  display: block;
                  padding-right: 0;
                  .ofcategory{
                    width: 100%;
                    height: 50px;
                    margin: 0 0 30px 0;
                    -webkit-appearance: listbox;
                    -moz-appearance: listbox;
                    appearance: listbox;
                    &:not(:last-child){
                      margin: 0 0 30px 0;
                    }
                  }
                  input[type="submit"]{
                    width: 100%;
                    height: 50px;
                    margin: 0;
                    padding: 15px 20px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                  }
                }
              }
            }
          }
        }
        .cart-wrapper{
          .cart-container{
            flex-direction: column;
            justify-content: center;
            .card-training {
              flex: 0 1 100%;
              max-width: 100%;
              .formation-embed {
                padding: 20px 15px;
              }
            }
          }
          .pagination{
            .page-numbers {
              margin: 0 12px;
            }
          }
        }
      }
    }
  }

  //**********Block*Three*Columns*Former*******//
  .block-three-columns-former{
    .block-three-columns-former-title{
      margin-bottom: 50px;
    }
    .former-three-columns-block{
      .former-three-columns-block-container{
        .former-three-columns-block-row{
          .former-three-columns-block-is-one-third{
            width: 100%;
            margin-right: 0;
            margin-bottom: 45px;
            max-height: 610px;
            .former-three-columns-block-inner{
              .former-three-columns-block-inner-row{
                .former-three-columns-block-img-column{
                  width: 85.777%;
                  .former-three-columns-block-image-block{
                    img{
                      min-height: 182px;
                    }
                  }
                }
                .former-three-columns-block-content-column{
                  top: -100px;
                  .former-three-columns-block-content-column-inner{
                    padding: 130px 25px 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .block-three-columns-button{
      a{
        width: 100%;
      }
    }
  }

  //***********Block*Certifications*Logo*block**********//
  .certifications-logo-block{
    .certifications-logo-row{
      flex-direction: column;
      align-items: center;
      .logo-item {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 30px;
        }
      }
    }
  }

}

@media screen and (max-width: 362px) {

  h1{
    font-size: 30px;
    letter-spacing: -1px;
  }

  h2{
    font-size: 25px;
    letter-spacing: -1px;
  }

  //************Header**************************//
  header{
    .head{
      .row-contact-header{
        padding: 20px 8px;
        .center-button-header{
          margin-right: 25px;
        }
      }
    }
  }

  //***********Block**Testimony****************//
  .home-block-testimony{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          .advgb-column-inner{
            .testimony-block-title{
              font-size: 30px;
              letter-spacing: -1px;
            }
          }
        }
      }
    }
  }

  //***********Block*Intro*Surgery************//
  .home-block-intro-surgery{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-three-quarters-tablet{
          .advgb-column-inner{
            .home-block-intro-surgery-title{
              font-size: 30px;
              letter-spacing: -1px;
            }
          }
        }
      }
    }
  }

  //*********Block*Intro*Corpo**********//
  .intro-corpo-block{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          .advgb-column-inner {
            .intro-corpo-block-title {
              font-size: 30px;
              letter-spacing: -1px;
            }
          }
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-one-third-tablet{
            .advgb-column-inner {
              .intro-corpo-block-title {
                font-size: 30px;
                letter-spacing: -1px;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Slider*Home*Intro********//
  .slider-intro-block{
    .swiper-container-intro-block{
      .swiper-wrapper{
        .slider-intro-block-slide{
          .slide-row{
            .slider-intro-block-inner{
              .slider-intro-block-container{
                .slider-intro-block-row{
                  .slider-intro-block-column-one{
                    .slider-intro-block-column-one-inner{
                      .slider-intro-block-title{
                        font-size: 30px;
                        letter-spacing: -1px;
                        line-height: 40px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .swiper-button-next{
        left: 250px;
      }
      .swiper-button-prev{
        left: 195px;
      }
    }
  }
}

@media screen and (max-width: 369px) {
  //*********Block*Type*Quote************//
  .block-type-quote{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          padding-top: 177.33%;
        }
      }
    }
  }
}
