.block-metiers{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      justify-content: center;
      .advgb-is-half-tablet{
        position: relative;
        width: 35% !important;
        padding-top: 35%;
        margin-right: 30px;
        background-color: #ffffff;
        box-shadow: 0 2px 40px 0 rgba(7, 26, 30, 0.12);
        .advgb-column-inner{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: center;
          align-content: center;
          align-items: center;
          .block-metiers-img{
            background-size: contain;
            background-color: transparent;
            .advgb-image-overlay{
              display: none;
            }
          }
          .block-metiers-title{
            font-family: $openSans-bold;
            font-size: 16px;
            line-height: 1.25;
            color: #333333;
            margin-top: 30px;
            text-transform: uppercase;
          }
          .block-metiers-content{
            font-family: $openSans-regular;
            font-size: 15px;
            line-height: 1.47;
            text-align: center;
            color: #333333;
            margin: 15px 0 55px 0;
            padding: 0 90px;
          }
          .block-metiers-tags{
            font-family: $openSans-bold;
            letter-spacing: 2px;
            text-align: center;
            width: fit-content;
            margin-right: 20px;
            display: inline-flex;
            a{
              line-height: normal;
              font-weight: normal;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
        &:last-child{
          margin-right: 0 !important;
        }
      }
    }
  }
  &:before{
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: #17385e;
    transform: translate(-50%, 0);
  }

  &.style-classic{
    .advgb-columns-container{
      margin-top: 0;
    }
    &:before{
      display: none;
    }
  }
}