.home-block-testimony{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column.advgb-is-one-third-tablet{
        width: 50% !important;
        padding: 100px 0;
        .advgb-column-inner{
          .testimony-block-title{
            font-family: $openSans-bold;
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.1;
            letter-spacing: normal;
            color: #ffffff;
          }
          .testimony-block-content{
            font-family: $openSans-regular;
            font-size: 16px;
            text-align: justify;
            line-height: 1.88;
            color: #ffffff;
            margin-top: 30px;
            ul, ol {
              font-family: $openSans-regular;
              li {
                font-size: 16px;
                line-height: 24px;
                position: relative;
                padding: 5px 5px 5px 30px;

                &::before {
                  content: '';
                  height: 6px;
                  width: 6px;
                  background: #000000;
                  border-radius: 50%;
                  position: absolute;
                  left: 0;
                  top: 15px;
                }
              }
            }

            ol {

              li {
                padding: 5px 5px 5px 0;
                list-style-type: decimal;
                list-style-position: inside;

                &::before {
                  content: none;
                }

                ol {

                  li {
                    margin: 5px 0 0 40px;
                  }
                }
              }
            }
          }
          .testimony-block-author{
            font-family: $openSans-bold;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #ffffff;
            margin-top: 20px;
          }
          .testimony-block-link{
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              border-color: unset;
              border-style: unset;
              border-width: unset !important;
              border-bottom: 3px #ffffff solid;
              padding: 10px 0;
              margin-top: 10px !important;
              &:hover{
                box-shadow: none;
                opacity: 1;
                transition: none;
                border-bottom: 3px solid #a8acb2;
              }
            }
          }
        }
      }
      .advgb-column.advgb-is-two-thirds-tablet{
        width: 100% !important;
        .advgb-column-inner{
          .testimony-block-image{
            margin: 0;
            background-size: cover;
            background-color: #c9c9c9;
            height: 100% !important;
            width: 66% !important;
            .advgb-image-overlay{
              display: none;
            }
          }
        }
      }
    }
  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: #a8acb2;
    transform: translate(-50%, 0);
  }
  &.img-left{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-two-thirds-tablet{
          width: 66% !important;
          .advgb-column-inner{
            .testimony-block-image{
              margin: 0;
              background-size: cover;
              background-color: #c9c9c9;
              height: 100% !important;
              width: 100% !important;
              .advgb-image-overlay{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}