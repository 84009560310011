.home-block-intro-surgery{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-is-three-quarters-tablet{
        width: 70% !important;
        padding: 100px 0;
        .advgb-column-inner{
          .home-block-intro-surgery-title{
            font-family: $openSans-bold;
            font-size: 40px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            color: #17385e;
          }
          .home-block-intro-surgery-content{
            font-family: $openSans-regular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #17385e;
            margin-top: 30px;
          }
        }
      }
      .advgb-is-one-quarter-tablet{
        width: 30% !important;
        padding: 150px 0;
        .advgb-column-inner{
          .home-block-intro-surgery-questions-link{
            margin-bottom: 20px;
            width: fit-content;
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09) !important;
              background-color: #5CA2D5  !important;
              border: none;
              border-width: unset !important;
              padding: 23px 43px;
              &:hover{
                background-color: #ffffff !important;
                color: #5CA2D5  !important;
                opacity: 1;
              }
            }
          }
          .home-block-intro-surgery-center-link{
            width: fit-content;
            .wp-block-advgb-button_link{
              font-family: $openSans-bold;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 2px;
              text-align: center;
              color: #ffffff;
              box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09) !important;
              background-color: #a8acb2 !important;
              border: solid 2px #a8acb2 !important;
              padding: 23px 51px;
              &:hover{
                background-color: #ffffff !important;
                color: #a8acb2 !important;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}