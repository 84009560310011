.catalog-training{
  .thumbnail{
    position: relative;
    top: 0;
    left: 50%;
    z-index: -1 !important;
    width: 100vw;
    transform: translate(-50%, 0) scale(1);
    max-height: 450px;
    img{
      width: 100vw;
      max-height: 450px;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }
  .catalog-container{
    padding: 80px 95px 60px 95px;
    box-shadow: 0 20px 40px 0 rgb(7 26 30 / 12%);
    background-color: #ffffff;
    margin-top: -195px;
    h1 {
      color: #17385e;
      margin: 0 0 40px 0;
    }
    .listing-training{
      .filter-posts{
        width: fit-content;
        justify-content: center;
        background-color: #183455;
        padding: 30px 20px;
        margin: 50px auto 0;

        .searchandfilter{
          width: 100%;
          div{
            ul{
              li{
                h4{
                  display: inline-block;
                  padding-left: 0;
                  font-family: $openSans-regular;
                  font-size: 16px;
                  line-height: 30px;
                  color: #ffffff;
                }
                .ofcategory{
                  width: 200px;
                  height: 49px;
                  margin: 0;
                  padding: 15px 20px;
                  background-color: #17385e;
                  color: #fff;
                  border: solid 1px #ffffff;
                  &:not(:last-child){
                    margin: 0 30px;
                  }
                }
                input[type="submit"]{
                  width: 211px;
                  height: 49px;
                  margin: 0 0 0 24px;
                  padding: 15px 50px;
                  background-color: #6ab2e3;
                  font-family: $openSans-regular;
                  font-size: 14px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 2px;
                  text-align: center;
                  color: #ffffff;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
      .cart-wrapper{
        margin: 50px 0;
        justify-content: center;
        .cart-container{
          position: relative;
          display: flex;
          width: 100%;
          margin: 0;
          text-align: center;
          font-size: 0;
          flex-wrap: wrap;
          flex-direction: row;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          .card-training {
            flex: 0 1 48%;
            max-width: 48%;
            margin-bottom: 30px;
            .formation-embed {
              padding: 35px 25px;
              position: relative;
              width: 100%;
              box-shadow: 0 20px 40px 0 rgba(45, 53, 62, 0.22);
              min-height: 310px;
              .title{
                min-height: 56px;
                h3{
                  padding-right: 15px;
                  margin: 0 0 17px 0;
                  font-family: $openSans-bold;
                  font-size: 20px;
                  line-height: 1.4;
                  color: #17385e;
                  text-align: left;
                }
              }
              .categories-row {
                text-align: left;
                margin-bottom: 15px;
                .category {
                  width: fit-content;
                  display: inline-block;
                  font-family: $openSans-semi-bold;
                  font-size: 13px;
                  color: #ffffff;
                  background-color: #0a4b78;
                  padding: 6px 12px;
                  border-radius: 17.5px;
                  line-height: normal;
                  &:not(:last-child) {
                    margin-right: 5px;
                  }
                }
              }
              .duration-price-row {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-top: 15px;
                margin-bottom: 8px;
                .duration {
                  position: relative;
                  font-family: $openSans-bold;
                  font-size: 16px;
                  line-height: 1.4;
                  color: #333333;
                  padding-left: 35px;
                  &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    width: 22px;
                    height: 22px;
                    background-image: url("../../../images/clock.png");
                    background-size: contain;
                    background-repeat: no-repeat;

                  }
                }
                .price {
                  font-family: $openSans-bold;
                  font-size: 16px;
                  line-height: 1.4;
                  color: #333333;
                }
              }
              .exercpt{
                margin: 5px 0 20px;
                font-family: $openSans-regular;
                font-size: 16px;
                line-height: 1.88;
                color: #333333;
                text-align: left;
              }
              .link{
                text-align: left;
                a{
                  font-family: $openSans-bold;
                  font-size: 18px;
                  line-height: 1.33;
                  color: #17385e;
                }
              }
            }
          }
        }
        .pagination{
          text-align: center;
          .page-numbers {
            font-size: 18px;
            line-height: 35px;
            color: #A8ACB2;
            margin: 0 40px;
            &.current,
            &.prev,
            &.next {
              color: #17385E;
              font-weight: bold;
            }
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}