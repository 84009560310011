.home-block-video-carousel{
  margin-bottom: 30px;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          height: 100%;
          margin-bottom: 200px;
          .home-block-video-carousel-title{
            font-family: $openSans-bold;
            font-size: 40px;
            font-weight: bold;
            line-height: 1.38;
            color: #17385e;
          }
          .home-block-video-carousel-description{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 1.88;
            color: #17385e;
            max-width: 45%;
            margin-top: 30px;
            margin-bottom: 60px;

          }
          .home-block-video-carousel{
            height: 600px;
            width: 100vw;
            padding-left: 95px;
            .swiper-container{
              max-width: 510px;
              margin: 0;
              overflow: visible;
              .swiper-wrapper{
                .home-block-video-carousel-slide{
                  .slide-row{
                    position: relative;
                    width: 100%;
                    box-shadow: 0 20px 40px 0 rgba(45, 53, 62, 0.22);
                    &::before{
                      content: " ";
                      display: block;
                      padding-top: 45.24%;
                    }
                    .player-carousel{
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                    }
                    .advgb-video-poster{
                      opacity: 1;
                      overflow: hidden;
                      img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }
                    .advgb-button-wrapper{
                      height: 100%;
                      width: 100%;
                      z-index: 3;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      cursor: pointer;
                      .advgb-play-button{
                        color: #c8c8c8 !important;
                      }
                    }
                    .home-block-video-carousel-slide-title{
                      font-family: $openSans-extra-bold;
                      font-size: 21px;
                      font-weight: 800;
                      color: #17385e;
                      text-align: start;
                      position: relative;
                      top: 70px;
                    }
                    .home-block-video-carousel-slide-duration{
                      font-family: $openSans-regular;
                      font-size: 18px;
                      color: #17385e;
                      text-align: start;
                      position: relative;
                      top: 75px;
                    }
                  }
                  &.swiper-slide{
                    opacity: 0;
                  }
                  &.swiper-slide-active{
                    opacity: 1;
                  }
                  &.swiper-slide-active ~ .swiper-slide{
                    opacity: 1;
                    .slide-row{
                      .home-block-video-carousel-slide-title,
                      .home-block-video-carousel-slide-duration{
                        top: -145px;
                      }
                    }
                  }
                }
              }
              .swiper-button-prev,
              .swiper-button-next{
                color: #17385e;
                top: 57%;
              }
              .swiper-button-next{
                right: auto;
                left: 450px;
              }
              .swiper-button-prev{
                left: 380px;
              }
              .white-square{
                position: relative;
                width: 120%;
                height: 40%;
                top: -135px;
                left: -40px;
                overflow: visible;
                box-shadow: 10px 10px 80px 0 rgba(139, 139, 139, 0.17);
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.home-block-video-carousel.type-corpo{
  position: relative;
  padding: 0;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          .home-block-video-carousel{
            .swiper-container{
              .white-square{
                background-color: #eef3f6;
              }
            }
          }
        }
      }
    }
  }
  &::before{
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    z-index: -1 !important;
    background-color: white;
    background-image: url("../../../images/motif-2.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 100%;
  }
}