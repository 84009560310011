.home-block-video-slider{
  position: relative;
  z-index: 60 !important;
  .advgb-columns-container{
    .advgb-columns-row{
      max-width: 100%;
      margin: 0 auto;
      .advgb-is-one-third-tablet{
        width: 632px !important;
        top: auto;
        left: 23%;
        margin-top: 72px;
        position: absolute;
        z-index: 777 !important;
        box-shadow: -10px 10px 80px 0 rgba(11, 30, 53, 0.15);
        .advgb-column-inner{
          .home-block-video-content{
            .advgb-video-wrapper{
              background-color: transparent;
              .advgb-video-poster{
                opacity: 1.5;
                overflow: hidden;
                img{
                  width: 100%;
                  margin-top: -57px;
                }
              }
              .advgb-button-wrapper{
                .advgb-play-button{
                  color: #c8c8c8 !important;
                }
              }
            }
          }
        }
      }
      .advgb-is-two-thirds-tablet{
        width: 100% !important;
        position: relative;
        z-index: 666 !important;
        padding: 100px 55px 100px 500px;
        min-height: 500px;
        background-color: #ffffff;
        box-shadow: -10px 10px 80px 0 rgba(11, 30, 53, 0.15);
        left: 40%;
        .advgb-column-inner{
          .home-block-video-content-list{
            ul{
              li{
                margin-bottom: 28px;
                cursor: pointer;
                position: relative;
                .home-block-video-content-list-title{
                    font-family: $openSans-extra-bold;
                    font-size: 18px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #a8acb2;
                    &.clicked{
                      font-size: 21px;
                      color: #17385e;
                    }
                  }
                .home-block-video-content-list-description{
                  font-family: $openSans-regular;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #a8acb2;
                  &.clicked{
                    font-size: 21px;
                    color: #17385e;
                  }
                }
                .home-block-video-content-list-duration{
                  font-family: $openSans-bold;
                  font-size: 15px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #a8acb2;
                  &.clicked{
                    font-size: 21px;
                    color: #17385e;
                  }
                }
                &.clicked{
                  .home-block-video-content-list-title{
                    font-size: 21px;
                    color: #17385e;
                  }
                  .home-block-video-content-list-description{
                    font-size: 21px;
                    color: #17385e;
                  }
                  .home-block-video-content-list-duration{
                    font-size: 21px;
                    color: #17385e;
                  }
                  &:nth-child(n)::before{
                    content: " ";
                    display: block;
                    width: 4px;
                    height: 75px;
                    background-color: #5CA2D5;
                    position: absolute;
                    top: 0;
                    left: -22%;
                    right: 0;
                    bottom: 0;
                  }
                }
              }
              &::before{
                content: " ";
                display: block;
                width: 4px;
                height: 72%;
                background-color: #dde1e6;
                position: absolute;
                top: 75px;
                left: 50%;
                right: 0;
                bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}