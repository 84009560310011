.block-type-quote{
  .advgb-columns-container{
    .advgb-columns-row{
      margin-bottom: 100px;
      .advgb-column{
        box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
        background-color: #ffffff;
        position: relative;
        // width: 38.12%;
        // padding-top: 28.58%;
        .advgb-column-inner{
          // position: absolute;
          // top: 0;
          // left: 0;
          // bottom: 0;
          // right: 0;
          padding: 100px 55px 55px;
          .block-type-quote-content{
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 2;
            text-align: center;
            color: #000000;
            &::before{
              content: " ";
              display: block;
              position: absolute;
              top: 15%;
              left: 48%;
              bottom: 0;
              right: 0;
              width: 22px;
              height: 16px;
              background-image: url("../../../images/quote.png");
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
}

.block-type-quote.type-corpo{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          .block-type-quote-content{
            &::before{
              background-image: url("../../../images/quote-type-corpo.png");
            }
          }
        }
      }
    }
  }
}