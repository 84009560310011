.kbnzone{
  .row{
    #ncform{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .ncFieldct{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
        width: 50%;
        margin-top: 25px;
        label{
          color: #17385e;
          font-family: $openSans-bold;
          font-size: 15px;
          display: block;
          width: auto;
          text-align: left;
          padding: 0;
          margin: 0 0 15px;
        }
        .ncField{
          float: initial;
          width: 100%;
          input[type="text"],
          input[type="email"]{
            width: 65%;
            max-width: 391px;
          }
          input[type="radio"],
          input[type="checkbox"],
          select{
            width: fit-content;
          }
          .multirequired{
            .kbn-svf{
              font-size: 16px;
              font-family: $openSans-regular;
            }
          }
          input[type="text"],
          input[type="email"],
          textarea{
            border: 1px solid #a8acb2;
            border-radius: 0;
            padding: 8px 20px;
            -webkit-appearance: none;
          }
          select{
            border: 1px solid #a8acb2;
            border-radius: 0;
            padding: 8px 20px;
          }
        }
        &.koba-form-33{
          width: 33.33%;
        }
        &.koba-form-100{
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: center;
          justify-content: flex-start;
          align-items: center;
          textarea{
            width: 50%;
          }
        }
        &.tpboolean{
          .ncField{
            width: fit-content;
          }
          label{
            margin: 0 0 0 15px;
          }
        }
      }
      .ncSendZone{
        margin: 25px auto 0;
        width: 50%;
        a{
          color: #FFFFFF;
          background-color: #6ab2e3;
          padding: 15px;
          margin: 0 auto;
          max-width: 322px;
          display: block;
          text-transform: uppercase;
          &:hover{
            color: #6ab2e3;
            background-color: #ffffff;
            box-shadow: 1px 1px 12px 0 #ccc;
          }
        }
      }
    }
  }
}