#widget_trusteville_point_vision_corpo{
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 300px;
  height: auto;
  box-sizing: border-box;
  z-index: 99;
  background: white;
  .title-advisor {
    background: #55B683;
    height: 37px;
    line-height: 37px;
    color: #FFFFFF;
    font-size: 11px;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 0 10px;
    margin: 0 0 8px;
    font-weight: bold;
    .close {
      float: right;
      &::before{
        display: block;
        color: #fff;
        content: '+';
        transform: rotate(45deg) translate3d(0, 0, 0);
        font-size: 29px;
      }
    }
  }
}
.container_trusteville {
  display: block;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid #a6cca6;
  margin-top: 1.5rem;
  .trustville-badge-stars {
    border: none;
    &.trustville-compact{
      min-height: 41px;
      margin-left: 23px;
      width: 280px;
      -webkit-border-radius: 0;
      border-radius: 0;
      .trustville-certificate {
        width: 62px !important;
        height: 62px !important;
        border-right: 0 none;
        margin: 0 5px 4px -20px;
        padding: 0 3px 0 0;
        box-sizing: initial;
        float: left;
      }
      #trustville_global_average {
        display: inline-block;
        width: 200px;
        .trustville-stars-small {
          height: 25px;
          width: 25px;
          margin-top: 7px;
          margin-right: 2px;
        }
        strong {
          line-height: 23px;
          font-size: 17px;
          margin-left: 10px;
          margin-top: 10px;
          float: left;
        }
        .trustville-on-5 {
          font-size: 15px;
          float: left;
          line-height: 23px;
          margin-top: 10px;
        }
        .trustville-legend {
          margin: 40px 0 0 4px;
          a{
            font-size: 15px !important;
          }
        }
        &::after{
          border-bottom: 0 solid #fff;
        }
      }
    }
  }
}