@media screen and (min-width: 1200px) {
  $container-width: 1200px;

  .container{
    max-width: $container-width;
    padding: 0 90px;
  }

  //************Header**************************//
  header{
    .header-title{
      font-size: 18px;
    }
    .head{
      .container{
        padding: 0 40px;
      }
      .title-logo-header{
        margin-left: 25px;
      }
      .row-contact-header{
        .contact-header{
          #label-contact-header{
            font-size: 13px;
            letter-spacing: -0.2px;
          }
          #num-contact-header{
            font-size: 16px;
          }
        }
        .center-button-header{
          min-height: 49px;
          padding: 0 25px;
          #inner-center-button-header{
            font-size: 14px;
          }
        }
        .question-button-header{
          min-height: 49px;
          padding: 0 25px;
          #inner-question-button-header{
            font-size: 14px;
          }
        }
      }

      /**Header*Corpo**/
      .container-header-corpo{
        max-width: $container-width;
        padding: 0 40px;
      }

    }
    .nav{
      .menu{
        max-width: $container-width;
      }
      ul {
        li {
          margin: 0;
          a{
            font-size: 15px;
            padding: 4px 0;
          }
        }
      }
    }
    li.has-mega-menu{
      &:hover,
      &.clicked {
        .sub-menu{
          max-width: 1110px;
          .mega-sub-column{
            width: 28.333333%;
            &.menu-item-has-children:hover{
              .sub-menu{
                left: 64%;
                padding-left: 110px;
                .mega-sub-sub-column{
                  &:hover{
                    .sub-menu{
                      left: 88%;
                      padding-left: 120px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //*************Banner*************************//
  .banner{
    .banner-container-outer{
      .banner-container{
        width: 910px;
        padding: 10px 60px;
      }
      .banner-close{
        top: 0;
        right: -60px;
      }
    }
  }

  //************Footer**************************//
  footer {
    .menu_footer {
      .footer-flex {
        flex-wrap: wrap;
        padding: 0 90px;
        margin: 0 auto 40px;
      }

      .iso {
        figure {
          img {
            max-width: 90px;
            max-height: 90px;
          }
        }
      }
    }
  }

  //***********Block**Testimony****************//
  .home-block-testimony{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column.advgb-is-one-third-tablet{
          padding: 45px 0;
          .advgb-column-inner{
            .testimony-block-title{
              font-size: 50px;
            }
          }
        }
      }
    }
  }

  //***********Block*Intro*Surgery************//
  .home-block-intro-surgery{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          padding: 150px 0;
        }
      }
    }
  }

  //**********Block*FAQ*********************//
  .home-block-faq-block{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .advgb-columns-wrapper{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: -10%;
                  .advgb-is-one-fifth-tablet{
                    max-width: 30%;
                    padding-top: 30%;
                    margin: 15px 15px !important;
                    .advgb-column-inner{
                      padding: 40px 40px;
                    }
                  }
                }
              }
            }
            .home-block-faq-block-row-button{
              .advgb-columns-container{
                .advgb-columns-row{
                  margin-top: 0;
                  .advgb-is-half-tablet{
                    max-width: 30%;
                    margin: 20px 15px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //************Form**************************//
  .form{
    .submit-container {
      .submit{
        width: 30%;
      }
    }
  }

  //**********Block*Video*Slider*************//
  .home-block-video-slider{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          left: 5%;
        }
      }
    }
  }

  //**********Block*Blue*Question*Frequency*//
  .home-block-blue-block-questions-frequency{
    margin-top: -15%;
    min-height: auto;
  }

  //**********Block*Frieze*****************//
  .home-block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        max-width: $container-width;
        .home-block-frieze-columns{
          width: 23.7777% !important;
          .advgb-column-inner{
            padding: 40px 50px 40px 0px;
          }
          &::after{
            width: 40%;
            height: 100%;
            left: 75%;
            top: -100%;
            background-size: unset;
          }
          &:last-child::after{
            width: 100%;
            height: 100%;
            left: 92%;
            top: -105%;
            background-size: unset;
          }
        }
      }
    }
  }

  //************Video*Carousel*************//
  .home-block-video-carousel{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  //**********Block*Type*Pathology*****//
  .block-type-pathology{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          max-width: 565px;
          .advgb-column-inner{
            .wp-block-advgb-image{
              height: 400px !important;
            }
          }
        }
        .advgb-is-two-thirds-tablet{
          .advgb-column-inner{
            padding: 100px 90px 100px 300px;
          }
        }
      }
    }
  }

  //*********Block*Type*Tabs**************//
  .block-type-tabs{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          margin-bottom: 100px !important;
        }
      }
    }
  }

  //*********Block*Type*Quote************//
  .block-type-quote{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          // padding-top: 32.58%;
          .advgb-column-inner{
            padding: 100px 55px 55px;
            .block-type-quote-content{
              &::before{
                top: 15%;
              }
            }
          }
        }
      }
    }
  }

  //*********Block*Intro*Corpo**********//
  .intro-corpo-block{
    .advgb-columns-container{
      .advgb-columns-row{
        flex-direction: row;
        .advgb-column.advgb-is-one-third-tablet{
          padding: 55px 145px 80px 0;
        }
      }
    }
    &.img-left{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column.advgb-is-one-third-tablet{
            padding: 55px 145px 80px 0;
          }
        }
      }
    }
  }

  //*********Block*Three*Columns********//
  .block-three-columns{
    //padding: 100px 0 230px 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          margin-right: 5% !important;
        }
      }
    }
    &::before{
      left: 40%;
    }
  }

  //********Block*Numbers************//
  .block-numbers{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-quarter-tablet{
          width: 23% !important;
          padding-top: 23%;
          margin-bottom: 0 !important;
          .advgb-column-inner{
            padding: 0;
            .block-numbers-title {
              font-size: 40px;
            }
          }
        }
      }
    }
  }

  //********Block*Map*Of*Centers*****//
  .block-map-of-centers{
    padding: 45px 0 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .block-map-of-centers-column-map{
          margin-right: 125px !important;
        }
      }
    }
  }

  //*******Block*Video*With*Title*Content**//
  .block-video-with-title-content{
    padding: 0 0 100px 0;
    .advgb-columns-container {
      .advgb-columns-row {
        .block-video-with-title-content-column-video{
          width: 45% !important;
          margin-right: 126px !important;
          height: 303px;
        }
      }
    }
  }

  //*****Block*Actus*FB******************//
  .block-actus-FB{
    padding: 200px 0 0 0;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            .block-actus-FB-title{
              left: 540px;
            }
            .block-actus-FB-description{
              left: 540px;
            }
            .block-actu-fb-carousel{
              .swiper-container{
                .swiper-button-prev,
                .swiper-button-next{
                  top: -14%;
                }
                .swiper-button-next{
                  left: 395px;
                }
                .swiper-button-prev{
                  left: 345px;
                }
                .blue-square{
                  left: 320px;
                }
              }
            }
          }
        }
      }
    }
  }

  //*****Block*Type*Article*To*Other*Page**//
  .block-type-article-to-other-page{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-two-thirds-tablet{
          left: 8.3333%;
          .advgb-column-inner{
            padding: 55px 95px 55px 190px;
          }
        }
      }
    }
  }

  //*****Block*Type*Offers*************//
  .block-type-offers{
    padding: 100px 0;
  }

  //***********Tarteaucitron****************//
  #tarteaucitronRoot{
    #tarteaucitronAlertBig{
      #tarteaucitronDisclaimerAlert{
        max-width: $container-width;
      }
    }
  }

  //***********Blog*Page********************//
  .listing-blog{
    .filter-posts{
      .searchandfilter{
        div{
          ul{
            li{
              input[name="ofsearch"]{
                width: 430px;
              }
            }
          }
        }
      }
    }
    .cart-wrapper{
      .cart-container{
        .cart{
          .two-half-part{
            padding: 30px 50px 35px 250px;
          }


        }
      }
      .pagination{
        .nav-links{
          font-size: 16px;
          .page-numbers{
            margin: 0 5px;
          }
        }
      }
    }
  }

  //*********Block*Slider*Home*Intro********//
  .slider-intro-block{
    .swiper-container-intro-block{
      .swiper-wrapper{
        .slider-intro-block-slide{
          .slide-row{
            .slider-intro-block-inner{
              .slider-intro-block-container{
                .slider-intro-block-row{
                  .slider-intro-block-column-one{
                    padding: 55px 100px 55px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  //************Block*summary*semantic*link******************//
  .summary_semantic_link_block{
    .summary_semantic_link_block-container{
      .summary_semantic_link_block-columns-row{
        padding: 50px;
      }
    }
  }

  //***********Single*Training******************//
  .single-training {
    .row-info {
      .column-content {
        .inner-column-content {
          padding: 150px 300px 150px 350px;
        }
      }
    }
  }
}
