.error404,.page-template-404{
	.advgb-columns{
		display: flex;
    	margin: 0 auto;

    	.wp-block-advgb-column{
    		flex:none;
    	}
	}
	.one-cta {
		.advgb-columns {
			.advgb-column {
				.one-cta-button {
					a {
						border-radius: 0;
						display: inline-block;
						text-decoration: none;
						border-color: #fff;
						margin: 0;
						padding: 23px 65px 23px 65px;
						border-width: 1px;
						font-size: 14px;
						border-style: none;
						color: #ffffff;
						background-color: #17385E !important;

						&:hover {
							box-shadow: 1px 1px 12px 0 #ccc;
							opacity: 1;
							transition: all 0.2s ease;
							background-color: #FFFFFF !important;
							color: #17385e !important;
						}
					}
				}
			}
		}
	}

	.title-text{
		.advgb-columns-container{
			.advgb-columns{
				.advgb-column{
					flex: 1;
				}
			}
		}
	}
}