// Colors
$color-variations: (
  noir: #000000,
  blanc: #ffffff,
  content: (
    texte: #333333
  ),
  social: (
    /**
     * Brand colors
     *
     * @see https://brandcolors.net/
     */
    defaut: #9f9f9f,
    facebook: #3b5998,
    twitter: #1da1f2,
    google-plus: #dd4b39,
    linkedin: #0077b5,
    vimeo: #1ab7ea,
    youtube: #cd201f,
    instagram: unquote('radial-gradient(circle at 30% 100%, #fed276, rgba(253, 189, 97, 0.5) 9%, #f6804d 20%, rgba(232, 61, 92, 0)), linear-gradient(166deg, #4e60d3, #913baf 14%, #d52d88 76%, #f26d4f)')
  )
);

// Grid
$container-width: 1440px;
$column-gap: 12px;
$column-count: 12;
$breakpoints: (
  m: 1024px,
  s: 1023px,
  xs: 767px,
);

// Font OpenSans

/* open-sans-300 - latin */
@font-face {
  font-family: OpenSans-light;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  font-weight: 300;
  src: local(''),
  url('fonts/OpenSans/open-sans-v34-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/OpenSans/open-sans-v34-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$openSans-light: 'OpenSans-light';

/* open-sans-regular - latin */
@font-face {
  font-family: OpenSans;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
  url('fonts/OpenSans/open-sans-v34-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/OpenSans/open-sans-v34-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$openSans-regular: 'OpenSans';

/* open-sans-600 - latin */
@font-face {
  font-family: OpenSans-semi-bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  font-weight: 600;
  src: local(''),
  url('fonts/OpenSans/open-sans-v34-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/OpenSans/open-sans-v34-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$openSans-semi-bold: 'OpenSans-semi-bold';

/* open-sans-700 - latin */
@font-face {
  font-family: OpenSans-bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  font-weight: 700;
  src: local(''),
  url('fonts/OpenSans/open-sans-v34-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/OpenSans/open-sans-v34-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$openSans-bold: 'OpenSans-bold';


/* open-sans-800 - latin */
@font-face {
  font-family: OpenSans-extra-bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  font-weight: 800;
  src: local(''),
  url('fonts/OpenSans/open-sans-v34-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/OpenSans/open-sans-v34-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$openSans-extra-bold: 'OpenSans-extra-bold';

$font-bold: 700;