.home-block-frieze{
  margin-top: -130px;
  margin-bottom: 100px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw !important;
  z-index: 70 !important;
  .advgb-columns-container{
    .advgb-columns-row{
      position: relative;
      z-index: 1 !important;
      -webkit-box-shadow: 16px 17px 37px 0 rgba(223, 223, 223, 0.51);
      -moz-box-shadow: 16px 17px 37px 0 rgba(223, 223, 223, 0.51);
      box-shadow: 16px 17px 37px 0 rgba(223, 223, 223, 0.51);
      .home-block-frieze-columns{
        background-color: white;
        width: 25% !important;
        .advgb-column-inner{
          padding: 40px 130px 40px 0;
          .home-block-frieze-columns-number{
            font-family: $openSans-bold;
            font-size: 39px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.51;
            letter-spacing: normal;
            text-align: center;
            color: #17385e ;
          }
          .home-block-frieze-columns-title{
            display: flex;
            font-family: $openSans-bold;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: #17385e ;
            margin: 15px 0;
            min-height: 40px;
            align-items: center;
            justify-content: center;
          }
          .home-block-frieze-columns-content{
            font-family: $openSans-regular;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.47;
            letter-spacing: normal;
            text-align: center;
            padding: 0 35px;
            color: #17385e;
          }
        }
        &::after{
          content: " ";
          display: block;
          width: 28%;
          height: 100%;
          position: relative;
          left: 75%;
          top: -100%;
          background-image: url("../../../images/fleche-frieze-desktop.png");
          background-repeat: no-repeat;
        }
        &:last-child::after{
          content: " ";
          display: block;
          width: 28%;
          height: 100%;
          position: relative;
          left: 94%;
          top: -104%;
          background-image: url("../../../images/fleche-frieze-desktop.png");
          background-repeat: no-repeat;
        }
      }
      &::before{
        content: '';
        display: block;
        position: absolute;
        z-index: -1 !important;
        right: 50%;
        bottom: 0;
        width: 50vw;
        height: 100%;
        background: white;
        -webkit-box-shadow: 16px 17px 37px 0 rgba(223, 223, 223, 0.51);
        -moz-box-shadow: 16px 17px 37px 0 rgba(223, 223, 223, 0.51);
        box-shadow: 16px 17px 37px 0 rgba(223, 223, 223, 0.51);
      }
    }
  }
}