header{
  .flex-container.row {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .head{
    margin: 0 auto;
    width: 100%;
    .container{
      display: flex;
      margin: 0 auto;
      width: 100%;
      justify-content: space-between;
      >div{
        margin-right: 0;
      }
      .row-logo-header{
        display: inherit;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        min-width: unset;
        margin-right: 0;
      }
    }
    .items-flex{
      display: inherit;
      flex-wrap: wrap;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    #site-header{
      overflow: hidden;
      background-color: white;
      .header-custom-logo {
        margin: 0;
      }
      #logo-header{
      }
    }
    .title-logo-header{
      .header-title{
        font-family: $openSans-extra-bold;
        font-size: 18px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #17385e;
      }
    }
    .row-contact-header{
      .contact-header{
        flex-direction: column;
        justify-content: center;
        margin-right: 30px;
        #label-contact-header{
          font-family: $openSans-regular;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.2px;
          color: #17385e;
        }
        #num-contact-header{
          font-family: $openSans-bold;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: normal;
          color: #17385e;
          text-decoration: none;
        }
        .type-call-contact-header{
          font-family: $openSans-regular;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.2px;
          color: #17385e;
        }
      }
      .rdv-button-header{
        box-shadow: 3px 7px 13px 0 rgba(11, 30, 53, 0.09);
        background-color: transparent;
        border: solid 2px #6ab2e3;
        min-height: 49px;
        padding: 0 25px;
        align-self: center;
        #inner-rdv-button-header{
          font-family: $openSans-bold;
          font-size: 14px;
          letter-spacing: 2px;
          text-align: center;
          text-transform: uppercase;
          color: #363636;
        }
        &:hover{
          background-color: rgba(106, 178, 227, 0.2);
          border: none;
          #inner-rdv-button-header{
            color: #17385e;
          }
        }
      }
      .meet-button-header{
        box-shadow: 3px 7px 13px 0 rgba(11, 30, 53, 0.09);
        background-color: #6ab2e3;
        border: 2px solid #6ab2e3;
        min-height: 49px;
        padding: 0 25px;
        align-self: center;
        margin-right: 0 !important;
        #inner-meet-button-header{
          font-family: $openSans-bold;
          font-size: 14px;
          letter-spacing: 2px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
        }
        &:hover{
          background-color: #ffffff;
          border: solid 2px #6ab2e3;
          #inner-meet-button-header{
            color: #6ab2e3;
          }
        }
      }
    }

    /**Header*Corpo**/
    .container-header-corpo{
      display: flex;
      margin: 0 auto;
      width: 100%;
      justify-content: space-around;
      >div{
        margin-right: 0;
      }
      .row-contact-header{
        >div{
          margin-right: 30px;
        }
        .rdv-button-header{
          box-shadow: 3px 7px 13px 0 rgba(11, 30, 53, 0.09);
          background-color: transparent;
          border: solid 2px #6ab2e3;
          min-height: 49px;
          padding: 0 25px;
          align-self: center;
          #inner-rdv-button-header{
            font-family: $openSans-bold;
            font-size: 14px;
            letter-spacing: 2px;
            text-align: center;
            color: #363636;
          }
          &:hover{
            background-color: rgba(106, 178, 227, 0.2);
            border: none;
            #inner-rdv-button-header{
              color: #17385e;
            }
          }
        }
        .meet-button-header{
          box-shadow: 3px 7px 13px 0 rgba(11, 30, 53, 0.09);
          background-color: #6ab2e3;
          border: 2px solid #6ab2e3;
          min-height: 49px;
          padding: 0 25px;
          align-self: center;
          margin-right: 0 !important;
          #inner-meet-button-header{
            font-family: $openSans-bold;
            font-size: 14px;
            letter-spacing: 2px;
            text-align: center;
            color: #ffffff;
          }
          &:hover{
            background-color: #ffffff;
            border: solid 2px #6ab2e3;
            #inner-meet-button-header{
              color: #6ab2e3;
            }
          }
        }
      }
    }
  }
  .nav{
    box-shadow: 0 32px 64px 0 rgba(192, 192, 192, 0.25);
    background-image: linear-gradient(to bottom, #173150, #17385e);
    padding: 40px 0;
    .menu{
      max-width: $container-width;
      margin: 0 auto;

    }
    ul {
      list-style-type: none;
      overflow: hidden;
      li {
        display: inline-block;
        margin: 0 45px;
        a{
          display: flex;
          font-family: $openSans-regular;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          position: relative;
          z-index: 992;
          padding: 4px 0;
          text-decoration: none;
          &:hover{
            color: #D5AE60 ;
          }
          &::before{
            content: "";
            width: 100%;
            height: 3px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: #fff;
            transition: 0.5s transform ease;
            transform: scale3d(0,1,1);
            transform-origin: 0 50%;
          }
          &::before{
            background: #D5AE60;
            transform-origin: 50% 50%;
          }
        }
        &.current-menu-ancestor{
          > a{
            font-family: $openSans-extra-bold;
            &::before{
              transform: scale3d(1,1,1);
            }
          }
          .current-menu-item{
            > a{
              font-family: $openSans-extra-bold;
              &::before{
                transform: scale3d(1,1,1);
              }
            }
          }
        }
        &.has-mega-menu{
          .sub-menu{
            .mega-sub-column.current-menu-ancestor{
              .sub-menu{
                .current-menu-item{
                  a{
                    max-width: fit-content !important;
                  }
                }
                .mega-sub-sub-column.current-menu-ancestor{
                  .sub-menu{
                    .current-menu-item{
                      a{
                        max-width: fit-content !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.style-corpo{
      ul {
        li {
          a{
            &:hover{
              color: #6ab2e3;
            }
            &::before{
              background: #6ab2e3;
            }
          }
        }
      }
    }
  }
}