.single-training {
  .row-header{
    position: relative;
    padding-top: 50px;
    padding-bottom: 360px;
    .title {
      h1 {
        color: #17385E;
        font-family: $openSans-bold;
        font-size: 40px;
        line-height: 55px;
      }
      margin-bottom: 10px;
    }
    .category{
      width: fit-content;
      display: inline-block;
      font-family: $openSans-semi-bold;
      font-size: 13px;
      color: #ffffff;
      background-color: #0a4b78;
      padding: 6px 12px;
      border-radius: 17.5px;
      line-height: normal;
      margin-right: 5px;
    }
    .row-flex {
      display: flex;
      justify-content: space-between;
      .row-content {
        flex: 0 1 60%;
        max-width: 60%;
        .date-revision {
          p {
            color: #17385E;
            font-family: $openSans-regular;
            font-size: 18px;
            line-height: 28px;
          }
          margin-bottom: 25px;
        }
        .short-desc {
          p {
            color: #17385E;
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 25px;
          }
          a{
            color: #17385E;
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 22px;
            text-decoration: underline;
          }
        }
      }
      .row-cta {
        flex: 0 1 30%;
        max-width: 30%;
        display: flex;
        flex-direction: column;
        .cta-blue {
          background-color: #6AB2E3;
          border: solid 2px #6ab2e3;
          box-shadow: 40px 10px 80px 0 rgba(11,30,53,0.09);
          padding: 25px;
          text-align: center;
          color: #FFFFFF;
          text-transform: uppercase;
          font-family: $openSans-bold;
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 19px;
          margin-bottom: 20px;
          &:hover{
            background-color: #ffffff;
            border: solid 2px #6ab2e3;
            color: #6ab2e3;
          }
        }
        .cta-border-blue {
          border:  2px solid #6AB2E3;
          box-shadow: 40px 10px 80px 0 rgba(11,30,53,0.09);
          padding: 25px;
          text-align: center;
          color: #17385E;
          text-transform: uppercase;
          font-family: $openSans-bold;
          font-size: 14px;
          letter-spacing: 2px;
          line-height: 19px;
          &:hover{
            background-color: #6AB2E333;
            border: 2px solid transparent;
          }
        }
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      transform: translate(-50%, 0);
      background-color: #EEF3F6;
    }
  }
  .row-info {
    position: relative;
    margin-top: -200px;
    margin-bottom: 100px;
    .column-img {
      width: 100%;
      position: absolute;
      z-index: 2;
      top: calc(50% - 35px);
      bottom: 0;
      transform: translateY(-50%);
      .img-info {
        background-size: contain;
        max-height: 450px;
      }
    }
    .column-content {
      position: relative;
      left: 33.3333%;
      z-index: 1;
      .inner-column-content {
        background-color: #FFFFFF;
        box-shadow: -10px 10px 80px 0 rgba(11,30,53,0.15);
        padding: 150px 85px 150px 350px;
        .duration-content,
        .price-content,
        .sanction-content,
        .language-content,
        .certification-content,
        .accessibility-content {
          position: relative;
          padding-left: 100px;
          .content-label {
            color: #17385E;
            font-family: $openSans-bold;
            font-size: 20px;
            line-height: 28px;
          }
          p{
            color: #17385E;
            font-family: $openSans-regular;
            font-size: 18px;
            line-height: 28px;
          }
        }
        .duration-content::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 50px;
          width: 65px;
          background-image: url("../../../images/ICON_Duree.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .price-content::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 50px;
          width: 65px;
          background-image: url("../../../images/ICON_Tarif.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .sanction-content::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 50px;
          width: 65px;
          background-image: url("../../../images/ICON_Diplome.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .language-content::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 50px;
          width: 65px;
          background-image: url("../../../images/ICON_Langue.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .certification-content::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 50px;
          width: 65px;
          background-image: url("../../../images/ICON_Certification.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .accessibility-content::before {
          content: "";
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 50px;
          width: 65px;
          background-image: url("../../../images/ICON_Accessibilite.png");
          background-size: contain;
          background-repeat: no-repeat;
        }

        div:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
  .row-former {
    position: relative;
    padding: 150px 0;
    h2 {
      color: #17385E;
      margin-bottom: 50px;
    }
    .card-container{
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
      text-align: center;
      font-size: 0;
      flex-wrap: wrap;
      .card-former {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 30px;
        margin: 0 0 30px 0;
        text-align: left;
        background-color: #fff;
        box-shadow: 0 32px 54px 0 rgba(0,0,0,.11);
        .column-one {
          width: 15%;
          .thumbnail-former{
            border-radius: 100%;
            overflow: hidden;
            width: fit-content;
            img{
              width: 100%;
              max-width: 140px;
              min-height: 140px;
              object-fit: cover;
            }
          }
        }
        .column-two {
          width: 50%;
          border-right: 1px solid #6ab2e3;
          padding-right: 50px;
          .text-container{
            padding: 0;
            font-size: 15px;
            .surgeon-name{
              color: #17385E;
              font-size: 30px;
              line-height: 42px;
            }
            .centers {
              margin-bottom: 35px;
              .center-surgeon {
                display: inline-block;
                position: relative;
                background-color: #6AB2E3;
                width: fit-content;
                border-radius: 50px;
                padding: 6px 0 6px 12px;
                overflow-wrap: normal;
                word-break: keep-all;
                word-wrap: normal;
                a{
                  font-size: 13px;
                  color: white;
                  line-height: 18px;
                  padding-right: 35px;
                }
                &:not(:last-child) {
                  margin-right: 10px;
                }
                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 11px;
                  right: 11px;
                  background-image: url("../../../images/external-link.png");
                  background-size: cover;
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
        }
        .column-three {
          width: 35%;
          padding-left: 60px;
          .text-container {
            padding: 0;
            font-size: 16px;
            .diplomes-surgeon {
              ul,li{
                list-style-type: disc;
              }
              ul{
                li{
                  font-family: $openSans-regular;
                  font-size: 16px;
                  line-height: 26px;
                  color: #17385E;
                  &:not(:last-child) {
                    margin-bottom: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      background-image: url("../../../images/motif.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: 14.5%;
      background-color: #ffffff;
      transform: translate(-50%, 0);
    }
  }
  .row-content {
    position: relative;
    padding-bottom: 60px;
    h2 {
      color: #17385E;
      padding: 60px 0;
    }
    .inner-content {
      p {
        color: #333333;
        font-family: $openSans-regular;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        margin-left: 10px;
        img {
          margin-right: 10px;
          margin-bottom: 10px;
          object-fit: contain;
        }
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      transform: translate(-50%, 0);
      background-color: #EEF3F6;
    }
  }
  .row-chapter {
    position: relative;
    h3 {
      color: #17385E;
      margin-bottom: 40px;
    }
    .inner-row-chapter {
      .accordion-item {
        background-color: #FFFFFF;
        .chapter-title {
          position: relative;
          color: #17385E;
          font-family: $openSans-bold;
          font-size: 18px;
          line-height: 35px;
          padding: 30px 40px;
          cursor: pointer;
          &::after {
            content: "";
            position: absolute;
            display: block;
            top: 40px;
            right: 15px;
            width: 15px;
            height: 8px;
            background-image: url("../../../images/chevron-down.png");
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.4s ease;

          }
        }
        .chapter-content {
          display: none;
          color: #17385E;
          font-family: $openSans-regular;
          font-size: 16px;
          line-height: 28px;
          padding: 30px 40px;
          ul,li{
            list-style-type: inherit;
            list-style-position: inside;
          }
        }
        &:not(:last-child) {
          .chapter-title {
            border-bottom: 1px solid #cecece;
          }
        }
        &.on {
          &:not(:last-child) {
            .chapter-title {
              border-bottom: 0 solid #cecece;
              &::after {
                transform: rotate(-90deg);
              }
            }
            .chapter-content {
              border-bottom: 1px solid #cecece;
            }
          }
          .chapter-title {
            &::after {
              transform: rotate(-90deg);
            }
          }
         .chapter-content {
           display: block;
         }
        }
      }
      padding-bottom: 60px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      transform: translate(-50%, 0);
      background-color: #EEF3F6;
    }
  }
  .row-complement {
    margin-bottom: 100px;
    h2 {
      color: #17385E;
      padding: 85px 0 60px;
    }
    .content-complement {
      h2 {
        color: #17385E;
        padding: 85px 0 60px;
      }
      p{
        color: #333333;
        font-family: $openSans-regular;
        font-size: 16px;
        line-height: 30px;
        strong {
          color: #17385E;
        }
      }
    }
  }
}