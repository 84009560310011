.block-numbers{
  position: relative;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-is-one-quarter-tablet{
        position: relative;
        width: 20% !important;
        padding-top: 20%;
        margin-right: 67px;
        background-color: #ffffff;
        box-shadow: 0 20px 40px 0 rgb(7 26 30 / 33%);
        .advgb-column-inner{
          position:  absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          .block-numbers-img{
            background-size: auto;
            background-color: transparent;
            height: 65px !important;
            margin: 0 0 30px;
            padding: 0;
            .advgb-image-overlay{
              display: none;
            }
          }
          .block-numbers-title{
            font-family: $openSans-bold;
            font-size: 46px;
            line-height: 0.48;
            text-align: center;
            color: #6ab2e3;
          }
          .block-numbers-subtitle{
            font-family: $openSans-bold;
            font-size: 20px;;
            line-height: 1.3;
            text-align: center;
            color: #333333;
            margin-top: 15px;
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1 !important;
    background-color: #17385e;
    transform: translate(-50%, 0);
  }
}