.container{
  .map-container{
    display: flex;
    margin-bottom: 90px;
    padding-top: 100px;
    position: relative;
    &::before{
      content: "";
      background-image: url("../../../images/motif.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      -height: calc(100% - 127px);
      width: 100%;
      transform: translateX(-33%);
    }

    ::-webkit-scrollbar {
      width: 10px;
      background-color: #ebeded;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #eef3f6;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #17385e;
    }

    .title{
      font-size: 20px;
      font-family: $openSans-bold;
      color: #17385e;
    }

    .filter{
      background-image: linear-gradient(to bottom, #173150, #17385e);
      display: flex;
      flex-wrap: wrap;
      padding: 30px 25px;
      width: 100%;

      &.mobile{
        display: none;
      }

      select{
        border: 1px solid #a8acb2;
        padding: 8px 20px;
        font-size: 15px;
        width: 100%;
        cursor: pointer;
      }
      a{
        background-color: #6ab2e3;
        color: #ffffff;
        font-size: 14px;
        letter-spacing: 2px;
        padding: 23px 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $openSans-regular;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .multiple select {
      margin-bottom: 12px;
    }

    .map-display{
      width: 100%;
      font-family: $openSans-bold;
      font-size: 14px;
      background-color: #ffffff;
      border: solid 2px #6ab2e3;
      padding: 23px;
      text-align: center;
      color: #17385e;
      cursor: pointer;
      margin: 30px 0 5px 0;
      letter-spacing: 2px
    }

    .right-column{
      width: 40%;
      overflow: hidden;

      p{
        margin-bottom: 7px;
        line-height: 1.68;
      }

      .items{
        overflow-y: scroll;
        box-shadow: 0 2px 40px 0 rgba(7, 26, 30, 0.07);
        margin-top: 25px;
        display: flex;
        flex-direction: column;

        .item{
          cursor: pointer;
          padding: 25px;
          position: relative;
          box-shadow: 0 2px 40px 0 rgba(7, 26, 30, 0.12);
          background-color: #ffffff;


          &:not(:last-child){
            margin-bottom: 25px;
          }

          .tel{
            font-family: $openSans-bold;
            color: #333333;
          }
          .mail{
            margin-bottom: 12px;
          }
          .mail,.calendar, .link{
            display: flex;
            img{
              min-width: 32px;
            }
            a{
              font-family: $openSans-bold;
              align-self: center;
              margin-left: 13px;
              color: #17385e;
              font-size: 18px;
            }
          }
          .offer {
            margin: 12px 0;
            width: 100%;
            flex-wrap: wrap;
            div {
              font-size: 16px;
            }
          }

          .typology{
            margin-top: 12px;
            display: flex;
            width: 100%;
            flex-wrap: wrap;

            a{
              background-color: #6ab2e3;
              padding: 0px 10px;
              color: #ffffff;
              border-radius: 17.5px;
              text-align: center;
              font-size: 12px;

              &:not(:last-child){
                margin-right: 15px;
              }
            }
          }
          .surgeon{
            margin: 25px 0;
            display: flex;
            .picture{

              img{
                max-width: 130px;
                max-height: 130px;
              }
            }
            .info{
              width: 70%;
              margin-left: 20px;

              .name{
                font-size: 20px;
                font-family: $openSans-bold;
              }
            }
          }
        }
      }
      .empty p {
        font-family: $openSans-bold;
        align-self: center;
        color: #17385e;
        font-size: 18px;
      }
    }
    .mapboxgl-map{
      margin-right: 25px;
      box-shadow: 0 10px 50px 0 rgba(7, 26, 30, 0.12);
      width: 60%;
      height: 850px;

      &.active{
        display: block;
        margin-top: 25px;
      }
      .popup-text{
        padding: 15px;
        p{
          &:not(:last-child){
            margin-bottom: 10px;
          }
        }

        .link,
        .calendar,
        .mail {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          a {
            font-size: 14px;
            margin-left: 4px;
            line-height: 1.2;

            img {
              max-width: 26px;
            }
          }
        }
      }
      .mapboxgl-marker{
        svg{
          g{
            fill: #17385e;
          }
        }
      }
      .mapboxgl-marker-selected {
        svg{
          g{
            fill: #47A7ED;
          }
        }
      }
    }
  }
  .offer {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 20px;
    .title {
      font-size: 22px;
      font-family: $openSans-bold;
    }
    .date{
      p {
        color: #CBCED1;
      }
    }
    .center {
      margin: 10px 0;
      .address {
        font-family: $openSans-bold;
      }
    }
    .contract-type{
      p{
        color: #83BCE6;
      }
    }
    .description,
    .reference{
      p {
        color: #767676;
        font-family: $openSans-regular;
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    .button {
      background-color: #41ccd1;
      border: 2px solid #41ccd1;
      padding: 20px 65px 20px 65px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 30%;
      margin: 0 auto;
      button {
        background: none;
      }
      a {
        font-family: $openSans-bold;
        color: #ffffff;
        letter-spacing: 2px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 14px;
      }
      &:hover{
        background-color: #ffffff;
        box-shadow: 1px 1px 12px 0 #ccc;
        opacity: 1;
        transition: all 0.2s ease;
        border: 2px solid #ffffff;
        a{
          color: #41ccd1;
        }
      }
    }
    &.single{
      .title{
        h1{
          color: #17385e;
          line-height: 1.1;
        }
      }
      .description{
        p{
          color: #000000;
          font-size: 16px;
          line-height: 1.88;
        }
        ul, ol {
          font-family: $openSans-regular;
          li {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            padding: 5px 5px 5px 30px;

            &::before {
              content: '';
              height: 6px;
              width: 6px;
              background: #000000;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 15px;
            }
          }
        }

        ol {

          li {
            padding: 5px 5px 5px 0;
            list-style-type: decimal;
            list-style-position: inside;

            &::before {
              content: none;
            }

            ol {

              li {
                margin: 5px 0 0 40px;
              }
            }
          }
        }
      }
      .button{
        background-color: #6ab2e3;
        border: 2px solid #6ab2e3;
        padding: 23px 65px;
        width: 482px;
        a{
          text-transform: uppercase;
          width: 100%;
        }
        &:hover{
          background-color: #ffffff;
          border: 2px solid #ffffff;
          box-shadow: 40px 10px 80px 0 rgba(11, 30, 53, 0.09);
          opacity: 1;
          transition: all 0.2s ease;
          a{
            color: #6ab2e3;
          }
        }
      }
    }
  }
  .single div {
    margin-bottom: 15px;
  }
  .item-selected {
    border: 1px solid #6ab2e3;
    box-shadow: 3px 3px 3px #6ab2e3;
    border-radius: 5px;
  }
  .mapboxgl-popup-close-button {
    font-size: 24px;
  }
}

.underlined-link {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
